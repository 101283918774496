import { Subscription } from "../models/subscription";
import { Surf } from "../models/surf";

export const pairSurfDataWithSubscription = (
	subscription: Subscription,
	surfData: Surf[]
): Surf | null => {
	const phoneNumberFromSubscription =
		subscription.phone_numbers[0].phone_number.slice(1);

	const surfDataThatMatchesSubscription = surfData.find(
		s => s.phone_number === phoneNumberFromSubscription
	);
	if (!surfDataThatMatchesSubscription) {
		return null;
	}

	return surfDataThatMatchesSubscription;
};
