import React from "react";
import { X } from "react-feather";
import styled from "styled-components";
import { ScreenReaderText } from "../ScreenReaderText";
import { useAppDispatch } from "../../hooks/hooks";
import { toggleModal } from "../../store/slices/modalSlice";

export const CloseButton: React.FC = () => {
	const dispatch = useAppDispatch();

	const clickHandler = () => {
		dispatch(toggleModal());
	};

	return (
		<Button onClick={clickHandler}>
			<span>
				<X size={28} />
				<ScreenReaderText text="Stäng dialogruta för kundservice" />
			</span>
		</Button>
	);
};

const Button = styled.button`
	all: unset;
	cursor: pointer;
	border-radius: 50%;
	padding: 0.4rem;
	height: auto;
	width: max-content;
	border: 2px solid transparent;
	position: relative;
	color: var(--color-whiteish);

	:hover,
	:focus {
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 2px solid rgba(255, 255, 255, 0.4);
			border-radius: 50%;
			animation: pulse 1.2s infinite;
			transition: all 0.2s ease-in-out;
		}
	}

	&:hover {
		&::after {
			border: 2px solid rgba(255, 255, 255, 0.3);
		}
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.1);
		}
		100% {
			transform: scale(1);
		}
	}
`;
