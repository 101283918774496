import { Phone } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Product } from "../../models/product";
import { Button } from "../Button";
import { CircledIcon } from "../CircledIcon";
import { GradientCard } from "../GradientCard";

interface Props {
	product: Product;
}

export const getProductCategoryFromSurfAmount = (surfAmount: number) => {
	if (surfAmount < 9) return "Småsurfaren";
	if (surfAmount < 22) return "Scrollaren";
	if (surfAmount < 35) return "Pendlaren";
	return "Storsurfaren";
};

const getSurfAmount = (subscription: string): number => {
	const surfAmount = subscription.split(" ")[2].slice(0, -2);
	return +surfAmount;
};

/**
 * @interface Props
 * @description A card that displays information about a product.
 * @param product - The product to display information about, must be of type Product.
 */

export const ProductCard = ({ product }: Props) => {
	const surfAmount = getSurfAmount(product.name);
	const productCategory = getProductCategoryFromSurfAmount(surfAmount);
	const navigate = useNavigate();

	const redirectToSalePage = () => {
		navigate(`/bli-kund?product=${product.id}`);
	};

	return (
		<GradientCard mixed title={product.name}>
			<Container>
				<CircledIcon iconName="phone">
					<Phone />
				</CircledIcon>
				<h3>{surfAmount}GB surf</h3>
				<h3>{product.unit_price * 1.25}kr/mån</h3>
				<p className="obundet">Obundet</p>
				<p className="category">{productCategory}</p>
				<p>{product.info}</p>
				<Button onClick={redirectToSalePage}>Köp nu</Button>
			</Container>
		</GradientCard>
	);
};

const Container = styled.div`
	padding: 32px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	& .gradient-circle {
		margin-inline: auto;
	}

	& .category {
		position: absolute;
		top: -12px;
		right: -16px;
		z-index: 3;
		background-color: var(--color-purple);
		text-align: center;
		text-transform: uppercase;
		color: var(--color-whiteish);
		font-weight: 700;
		text-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
		margin-bottom: 32px;
		line-height: 1;
		padding: 12px 8px 8px 8px;
		border-radius: 8px;
		font-size: var(--font-size-s);
		opacity: 0.95;
		transform: rotate(2deg);
	}

	& h3 {
		font-size: var(--font-size-m);
		text-align: center;
		color: var(--color-gray);
		margin-bottom: 16px;
		transform: rotate(-1deg);
	}

	& p {
		margin-bottom: 32px;
		line-height: 1.5;
	}

	& .obundet {
		text-align: center;
		text-transform: uppercase;
		color: var(--color-gray);
		opacity: 0.8;
		font-weight: 700;
		text-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
	}

	& button {
		margin-top: auto;
	}
`;
