import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { Modal } from "../../models/modal";
import {
	selectModalState,
	setActiveModal,
	toggleModal,
} from "../../store/slices/modalSlice";
import { Breakpoints } from "../../styles/Breakpoints";
import { ScreenReaderText } from "../ScreenReaderText";

const ToggleCustomerSupportBtn: React.FC = () => {
	const dispatch = useAppDispatch();
	const modalIsActive = useAppSelector(selectModalState);

	const clickHandler = () => {
		dispatch(setActiveModal(Modal.CustomerSupport));
		dispatch(toggleModal());
	};

	return (
		<Button
			aria-pressed={modalIsActive}
			onClick={clickHandler}
			title="Behöver du hjälp? Klicka här!"
		>
			<HelpIcon>?</HelpIcon>
			<ScreenReaderText text="Klicka för att öppna supportfönstret" />
		</Button>
	);
};

const Button = styled.button`
	z-index: 10;
	width: 3rem;
	height: 3rem;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: none;
	background-color: hsla(190, 40%, 46%, 0.75);
	cursor: pointer;
	outline: none;
	transition: all 0.4s;
	box-shadow: 0 8px 16px hsla(188, 22%, 25%, 0.1);

	&:hover {
		background-color: hsla(190, 35%, 46%, 1);
	}

	&:focus {
		outline: 4px solid var(--color-purple);
		outline-offset: 6px;
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		border-radius: 50%;
		animation: pulsate 3s infinite;
		background-color: hsla(190, 35%, 46%, 0.1);
	}

	&::before {
		width: 100%;
		height: 100%;
	}

	&::after {
		width: calc(100% + 1rem);
		height: calc(100% + 1rem);
	}

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		height: 4rem;
		width: 4rem;
		right: 3rem;
		bottom: 3rem;

		&::after {
			width: calc(100% + 1.25rem);
			height: calc(100% + 1.25rem);
		}
	}

	@media (min-width: ${Breakpoints.Laptop}) {
		width: 5rem;
		height: 5rem;

		&::after {
			width: calc(100% + 1.5rem);
			height: calc(100% + 1.5rem);
		}
	}
`;

const HelpIcon = styled.span`
	font-weight: 600;
	font-size: 2.25rem;
	line-height: 1;
	transform: translateY(5px) translateX(2px);
	color: var(--color-whiteish);

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		font-size: 3rem;
		transform: translateY(8px) translateX(2px);
	}
`;

export default ToggleCustomerSupportBtn;
