import styled from "styled-components";
import { Subscription } from "../../../models/subscription";
import { Product } from "../../../models/product";
import { DottedDivider } from "../../../components/Dividers/DottedDivider";
import { AnimatedSecondaryHeading } from "../../../components/AnimatedSecondaryHeading/AnimatedSecondaryHeading";

interface Props {
	wantsNewSubscription: boolean;
	subscriptionToChange: Subscription | undefined;
	selectedProduct: Product | undefined;
}

export const Step3: React.FC<Props> = (props: Props) => {
	const { wantsNewSubscription, subscriptionToChange, selectedProduct } =
		props;

	return (
		<ArticleWrapper>
			<AnimatedSecondaryHeading>3. Bekräfta val</AnimatedSecondaryHeading>
			<PurpleCard>
				<h4>Nu är vi nästan klara! 🎉 </h4>
				<p>
					Titta igenom dina val en sista gång och om allt ser bra ut
					så kör vi!
				</p>
			</PurpleCard>
			{wantsNewSubscription && selectedProduct && (
				<div className="wrapper">
					<h3>Du har valt att skaffa ett nytt abonnemang.</h3>
					<p>
						Ditt nya abonnemang heter {selectedProduct.name}. Du
						kommer inom kort bli kontaktad av Vobbiz för att få veta
						ditt nya telefonnummer.
					</p>
				</div>
			)}
			{!wantsNewSubscription && subscriptionToChange && selectedProduct && (
				<div className="wrapper">
					<h3>Du har valt att byta abonnemang.</h3>

					<h4>Du vill byta från abonnemanget...</h4>
					<p>
						...{subscriptionToChange.contracts[0].product_name} med
						telefonnummer{" "}
						{subscriptionToChange?.phone_numbers[0]?.phone_number?.replace(
							"+46",
							"0"
						)}
						.
					</p>
					<DottedDivider />
					<h4>Till abonnemanget...</h4>
					<p>
						...{selectedProduct.name}. Du behåller ditt nuvarande
						telefonnummer.
					</p>
				</div>
			)}
		</ArticleWrapper>
	);
};

const ArticleWrapper = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	max-width: 600px;

	h3,
	h4,
	p {
		margin: 16px;
		align-self: flex-start;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const PurpleCard = styled.section`
	max-width: 90%;
	width: 300px;
	border: 4px dotted var(--color-purple);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
	border-radius: 0.5rem;
	padding: 16px;
	margin-bottom: 32px;
	background: rgba(197, 173, 237, 0.1);
`;
