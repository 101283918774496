import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FETCHURL } from "../../constants/constants";
import { Customer } from "../../models/customer";
import { getTokenFromSessionStorage } from "../../services/sessionStorageServices";

export const customerSlice = createApi({
	reducerPath: "customer",
	tagTypes: ["Customer"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${FETCHURL}/api/customer`,
		prepareHeaders: headers => {
			const token = getTokenFromSessionStorage();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: builder => ({
		getCustomer: builder.query<Customer, void>({
			query: () => ({
				url: "/",
			}),
			providesTags: ["Customer"],
		}),
		updateCustomer: builder.mutation<Customer, Customer>({
			query: customer => ({
				url: "/",
				method: "PUT",
				body: { customer },
			}),
			invalidatesTags: ["Customer"],
		}),
	}),
});

export const { useGetCustomerQuery, useUpdateCustomerMutation } = customerSlice;
