import { useEffect, useState } from "react";
import styled from "styled-components";
import { Phone } from "react-feather";
import { PageWrapper } from "../../components/PageWrapper";
import { CircledIcon } from "../../components/CircledIcon";
import { Button } from "../../components/Button";
import { useGetSubscriptionsQuery } from "../../store/slices/subscriptionSlice";
import { useGetCustomerQuery } from "../../store/slices/customerSlice";
import { Subscription } from "../../models/subscription";
import { sendEmail } from "../../services/mailServices";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { SelectInput } from "../../components/FormComponents/SelectInput";
import { AlertMessage } from "../../components/AlertMessage";
import { AnchorText } from "../../components/AnchorText";
import { ClientErrorResponse } from "../../models/clientErrorResponse";
import { getSubscriptionSelectedByUser } from "../../utils/determineUserSelection";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

export const NewSimCardPage: React.FC = () => {
	const {
		data: activeSubscriptions,
		isLoading,
		isError,
	} = useGetSubscriptionsQuery();
	const { data: customer } = useGetCustomerQuery();

	const [selectedSubscription, setSelectedSubscription] = useState<
		Subscription | undefined
	>(undefined);
	const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertMessageIsVisible, setAlertMessageIsVisible] =
		useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState("");
	const [requestWasSuccessful, setRequestWasSuccessful] = useState(false);

	useEffect(() => {
		document.title = "Mitt Vobbiz - Beställa nytt SIM-kort";
	}, []);

	useEffect(() => {
		if (activeSubscriptions && activeSubscriptions.length === 1) {
			setButtonIsDisabled(false);
			setSelectedSubscription(activeSubscriptions[0]);
		}
	}, [activeSubscriptions]);

	const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedValue(event.target.value);

		if (activeSubscriptions && activeSubscriptions.length > 0) {
			const newSubscriptionSelectedByUser = getSubscriptionSelectedByUser(
				event,
				activeSubscriptions
			);

			if (newSubscriptionSelectedByUser) {
				setSelectedSubscription(newSubscriptionSelectedByUser);
				setButtonIsDisabled(false);
			}
		}
	};

	const onClickHandler = async () => {
		setRequestWasSuccessful(false);
		if (!customer || !selectedSubscription) {
			setAlertMessage(
				"Vi saknar information från dig, se till att du har valt ett abonnemang."
			);
			setAlertMessageIsVisible(true);
			return;
		}

		try {
			await sendEmail(
				customer.email,
				`Kund önskar beställa nytt SIM-kort till abonnemangsnummer ${selectedSubscription.contracts[0].contract_number}`
			);

			setRequestWasSuccessful(true);
			setAlertMessage(
				"Vi har tagit emot din förfrågan om ett nytt SIM-kort!"
			);
			setAlertMessageIsVisible(true);
		} catch (err) {
			setAlertMessage(
				err instanceof ClientErrorResponse
					? err.message
					: "Något gick fel, försök igen."
			);
			setAlertMessageIsVisible(true);
		}
	};

	return (
		<PageWrapper secondary>
			<Container>
				<CircledIcon iconName="phone" secondary>
					<Phone />
				</CircledIcon>
				<h1>Nytt SIM-kort?</h1>

				<p className="info">
					Har ditt SIM-kort kommit bort eller gått sönder? Här kan du
					enkelt beställa ett nytt kort som levereras hem till din
					brevlåda.
				</p>

				<p className="info">
					När du börjat använda ditt nya SIM-kort blir det förra
					automatiskt avaktiverat.
				</p>

				{isLoading && <LoadingSpinner />}
				{isError && (
					<h4 className="error">
						Något gick fel när vi försökte hämta abonnemang. Det
						verkar som om vi har tekniska problem. Du kan försöka
						igen eller kontakta vår support för hjälp med att ett
						nytt SIM-kort.
					</h4>
				)}

				{customer && !customer.email && (
					<h4>
						För att beställa ett nytt SIM-kort behöver du registrera
						en mejladress. Det kan du göra{" "}
						<AnchorText
							text="här."
							desiredRedirectPath="/mina-uppgifter"
						/>
						Vill du inte registrera en mejladress,{" "}
						<AnchorText
							text="kontakta Vobbiz support."
							desiredRedirectPath="/kontakta-oss"
						/>
					</h4>
				)}

				{activeSubscriptions?.length === 1 && (
					<Button secondary onClick={onClickHandler}>
						Jag vill ha ett nytt SIM-kort
					</Button>
				)}
				{activeSubscriptions?.length === 0 && (
					<h4 className="error">
						Vi hittar inget aktivt abonnemang.
					</h4>
				)}

				{activeSubscriptions &&
					activeSubscriptions.length > 1 &&
					customer &&
					customer.email && (
						<form>
							<SelectInput
								value={selectedValue}
								onChangeHandler={onChangeHandler}
								inputName="Välj abonnemang"
							>
								<option value="">Välj abonnemang</option>
								<optgroup label="Abonnemang">
									{activeSubscriptions.map(subscription => {
										const valueToDisplay = `${
											subscription.contracts[0]
												.product_name
										} ${formatPhoneNumber(
											subscription.phone_numbers[0]
												.phone_number
										)}`;

										return (
											<option
												key={subscription.id}
												value={valueToDisplay}
											>
												{
													subscription.contracts[0]
														.product_name
												}{" "}
												{formatPhoneNumber(
													subscription
														.phone_numbers[0]
														.phone_number
												)}
											</option>
										);
									})}
								</optgroup>
							</SelectInput>
							<Button
								secondary
								onClick={onClickHandler}
								disabled={buttonIsDisabled}
							>
								Jag vill ha ett nytt SIM-kort
							</Button>
						</form>
					)}
				<AlertMessage
					isSuccess={requestWasSuccessful}
					isVisible={alertMessageIsVisible}
				>
					{alertMessage}
				</AlertMessage>
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	margin: 0 auto;

	p.info {
		align-self: flex-start;
	}

	.error {
		margin-top: 16px;
		color: var(--color-error);
	}
`;
