import { Phone } from "react-feather";
import styled from "styled-components";
import { Subscription } from "../../models/subscription";
import { Surf } from "../../models/surf";
import { extractDates } from "../../utils/extractDates";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

interface Props {
	surfData: Surf | null;
	subscription: Subscription;
}

/**
 * @description A component that sums up important information about the subscription/s of the user.
 * @param surfData - an object that contains information about the users surf data. Must be of type Surf.
 * @param subscription - an object that contains information about the user's subscription. Must be of type Subscription.
 */

export const OverviewCard = (props: Props) => {
	const { surfData, subscription } = props;
	const phoneNumber = formatPhoneNumber(
		subscription.phone_numbers[0].phone_number
	);

	const { daysUntilFirstDayOfNextMonth } = extractDates();

	return (
		<Container>
			<PhoneNumber>
				<Phone size={24} />
				<span>{phoneNumber}</span>
			</PhoneNumber>
			<dl>
				<InformationWrapper>
					<dt>Abonnemang</dt>
					<dd>{subscription.contracts[0].product_name}</dd>
				</InformationWrapper>
				{surfData &&
				"currentVolume" in surfData &&
				"maxCapacity" in surfData ? (
					<InformationWrapper>
						<dt>Surf</dt>
						<dd>
							{surfData.currentVolume} av {surfData.maxCapacity}{" "}
							GB kvar
						</dd>
					</InformationWrapper>
				) : (
					<InformationWrapper>
						<dt>Surf</dt>
						<dd>Okänt</dd>
					</InformationWrapper>
				)}
				<InformationWrapper>
					<dt>Surfen fylls på om</dt>
					<dd>{daysUntilFirstDayOfNextMonth} dagar</dd>
				</InformationWrapper>
				<InformationWrapper>
					<dt>Bindningstid</dt>
					<dd>Obundet</dd>
				</InformationWrapper>
			</dl>
		</Container>
	);
};

const Container = styled.article`
	border: 1px dashed hsla(228, 70%, 79%, 0.3);
	padding: 32px;
	border-radius: 16px;
	width: 100%;
	color: var(--color-gray);
`;

const PhoneNumber = styled.h3`
	background-color: hsla(263, 35%, 65%, 1);
	color: var(--color-whiteish);
	display: flex;
	padding: 6px 8px 2px 8px;
	border-radius: 8px;
	margin-bottom: 16px;
	letter-spacing: 1px;

	& span {
		margin-left: 8px;
	}
`;

const InformationWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 0;

	&:not(:last-of-type) {
		border-bottom: 1px solid hsla(228, 70%, 79%, 0.2);
		margin-bottom: 8px;
	}

	&:last-of-type {
		padding-bottom: 0;
	}

	& dt {
		font-weight: bold;
	}
`;
