import React from "react";
import styled from "styled-components";
import gradientLogo from "../../assets/images/VobbizGradient.svg";

export const MinaSidorLogo: React.FC = () => (
	<Container>
		<img src={gradientLogo} alt="Vobbiz logga" />
		<span>Mina sidor</span>
	</Container>
);

const Container = styled.figure`
	width: 200px;
	height: 60px;
	position: relative;
	margin-bottom: 2rem;

	& span {
		position: absolute;
		top: 42px;
		right: 14px;
		text-transform: uppercase;
		font-size: 14px;
		color: var(--color-gray);
	}
`;
