import { useEffect, useState } from "react";
import styled from "styled-components";
import { PageWrapper } from "../components/PageWrapper";
import surprisedWoman from "../assets/images/surprised.jpeg";
import headerImage from "../assets/images/privat.jpeg";
import { Breakpoints } from "../styles/Breakpoints";
import { useFetchProductsQuery } from "../store/slices/productSlice";
import blobMask from "../assets/images/blob-mask.svg";
import { ProductCard } from "../components/ProductCard";
import { LoadingSpinner } from "../components/LoadingSpinner";
import ToggleModalLink from "../components/Modal/ToggleModalLink";
import { Modal } from "../models/modal";
import ContactForm from "../components/ContactForm/ContactForm";
import { EmailObj } from "../models/emailObj";
import { sendEmailAsGuest } from "../services/mailServices";
import { ClientErrorResponse } from "../models/clientErrorResponse";
import { AlertMessage } from "../components/AlertMessage";

export const HomePage: React.FC = () => {
	const { data: products, isLoading, isError } = useFetchProductsQuery();
	const [isSending, setIsSending] = useState(false);
	const [actionWasSuccessful, setActionWasSuccessful] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertMessageIsVisible, setAlertMessageIsVisible] =
		useState<boolean>(false);

	useEffect(() => {
		document.title = "Vobbiz Privat - Hem";
	}, []);

	const sendMessageHandler = async (emailObj: EmailObj) => {
		try {
			setAlertMessageIsVisible(false);
			setIsSending(true);

			await sendEmailAsGuest(emailObj);

			setIsSending(false);
			setActionWasSuccessful(true);
			setAlertMessageIsVisible(true);
			setAlertMessage(
				"Ditt mail är nu skickat till oss! 🥳 Vi svarar normalt inom 8 timmar."
			);
		} catch (err) {
			setIsSending(false);
			setActionWasSuccessful(false);
			setAlertMessageIsVisible(true);
			setAlertMessage(
				err instanceof ClientErrorResponse
					? err.message
					: "Något gick fel när vi försökte skicka ditt mail, vänligen försök igen eller kontakta Vobbiz support."
			);
		}
	};

	return (
		<PageWrapper hideMenu>
			<Container>
				<HeaderImage src={headerImage} alt="Alt" />
				<Heading>Enkelt att byta – vi ordnar allt!</Heading>
				<ProductsContainer>
					{isLoading ? (
						<LoadingSpinner />
					) : (
						products &&
						products.map(product => (
							<ProductCard key={product.id} product={product} />
						))
					)}
					{isError && (
						<ErrorText>
							Något gick visst fel... 😞 Testa att ladda om sidan!
						</ErrorText>
					)}
					<section className="surf">
						<p>Är du osäker på hur mycket surf du behöver?</p>
						<ToggleModalLink modal={Modal.SurfGuide}>
							Kolla in Surfguiden!
						</ToggleModalLink>
					</section>
				</ProductsContainer>
				<InfoContainer>
					<InfoContent>
						<p>
							Det självklara valet om du gillar schyssta villkor
						</p>
						<h2>
							Vobbiz är den
							<br /> schyssta operatören
							<br /> där du fortfarande kan få personlig service!
						</h2>
						<p>
							Inga avtalstider eller krångliga avtal för att
							beställa något så enkelt som ett abonnemang. På Mitt
							Vobbiz kan du enkelt ta hand om dina abonnemang.
							Lägg till, ta bort eller höja och sänk din surf.
						</p>
						<p>
							<strong>Flexibelt</strong>, <strong>Enkelt</strong>{" "}
							& <strong>Smart</strong> med Vobbiz!
						</p>
					</InfoContent>
					<InfoImage
						src={surprisedWoman}
						alt="Kvinna tittar uppspelt på sin mobiltelefon"
					/>
				</InfoContainer>
				<ContactForm
					sendMessage={sendMessageHandler}
					isSending={isSending}
					actionWasSuccessful={actionWasSuccessful}
				/>
				<AlertMessage
					isVisible={alertMessageIsVisible}
					isSuccess={actionWasSuccessful}
				>
					{alertMessage}
				</AlertMessage>
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	width: 100%;
	padding-inline: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Heading = styled.h1`
	color: hsla(263, 42%, 65%, 1);
	transform: rotate(-1deg);
	text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.08);
	margin-block: 48px 96px;
	text-align: center;

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		text-align: left;
	}
`;

const HeaderImage = styled.img`
	margin-inline: auto;
	width: 100%;
	height: auto;
	max-height: 200px;
	max-width: 350px;
	border-radius: 1.5rem;
	margin-bottom: 48px;
	mask: url(${blobMask}) no-repeat center;
	display: block;
	aspect-ratio: 1;
	object-fit: cover;

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		max-width: 550px;
		max-height: 300px;
	}
`;

const InfoContainer = styled.section`
	margin: 96px 0;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	@media (min-width: ${Breakpoints.TabletLandscape}) {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 48px;
	}
`;

const ProductsContainer = styled.section`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 32px;
	width: 100%;
	max-width: var(--max-width);
	margin-bottom: 96px;

	& .surf {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		text-align: center;
	}

	& .loading-spinner {
		margin-inline: auto;
	}

	> .gradient-card {
		width: 100%;
		max-width: 350px;
		position: relative;
		flex-basis: 100%;

		@media (min-width: ${Breakpoints.TabletPortrait}) {
			flex-basis: 50%;
		}

		@media (min-width: ${Breakpoints.Laptop}) {
			flex-basis: 33%;
		}
	}

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		gap: 48px;
	}
`;

const ErrorText = styled.p`
	text-align: center;
`;

const InfoImage = styled.img`
	width: 100%;
	max-width: 500px;
	border-radius: 1.5rem;
	height: auto;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
	margin-bottom: 48px;

	@media (min-width: ${Breakpoints.TabletLandscape}) {
		margin-bottom: 0;
	}
`;

const InfoContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	text-align: center;

	@media (min-width: ${Breakpoints.TabletLandscape}) {
		align-items: flex-start;
		text-align: left;
	}

	& h2 {
		max-width: 500px;
		line-height: 1.2;
		margin-bottom: 48px;
		transform: rotate(-2deg);
		color: hsla(263, 42%, 65%, 1);
		text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.08);
	}

	& p {
		margin-bottom: 32px;
		width: fit-content;
		max-width: 400px;
		text-align: left;

		&:first-of-type {
			text-transform: uppercase;
			font-size: var(--font-size-s);
			color: var(--color-gray);
		}

		@media (min-width: ${Breakpoints.TabletLandscape}) {
			max-width: 500px;
		}
	}
`;
