import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    body, html, #root {
        height: 100%;
    }

    body {
        font-family: 'Martel Sans', sans-serif;
        font-size: var(--font-size-base);
        font-weight: 300;
        color: var(--color-blackish);
        background-color: var(--color-whiteish);
    }

    h1 {
        font-size: var(--font-size-xxl);
    }

    h2 {
        font-size: var(--font-size-xl);
    }

    ::-webkit-scrollbar {
    width: 14px;
    }

    ::-webkit-scrollbar-track {
    background: var(--color-whiteish);
    }

    ::-webkit-scrollbar-thumb {
    background: hsla(188, 0%, 83%, 1.0);
    border: 3px solid var(--color-whiteish);
    border-radius: .5rem;
    }

    ::-webkit-scrollbar-thumb:hover {
    background: hsla(188, 0%, 73%, 1.0);
    }

    .hasDialog{
        position:fixed;
        overflow-y: scroll;
        width:100%;
    }

    @keyframes pulsate {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.3);
        }
        100% {
            transform: scale(1);
        }
    }
`;
