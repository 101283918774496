import { FETCHURL } from "../constants/constants";
import { ClientErrorResponse } from "../models/clientErrorResponse";
import { EmailObj } from "../models/emailObj";
import { getTokenFromSessionStorage } from "./sessionStorageServices";

export const sendEmail = async (email: string, message: string) => {
	const token = getTokenFromSessionStorage();

	const response = await fetch(`${FETCHURL}/api/mail`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({ email, message }),
	});

	const data = await response.json();

	if (!response.ok) {
		throw new ClientErrorResponse("Något gick fel, testa att försöka igen");
	}

	return data;
};

export const sendEmailAsGuest = async (emailObj: EmailObj) => {
	const response = await fetch(`${FETCHURL}/api/mail/guest`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(emailObj),
	});

	const data = await response.json();

	if (!response.ok) {
		throw new ClientErrorResponse(
			data.message || "Något gick fel, testa att försöka igen"
		);
	}

	return data;
};
