import { Menu, X } from "react-feather";
import styled from "styled-components";
import { ScreenReaderText } from "../ScreenReaderText";

interface Props {
	isOpen?: boolean;
	toggleMenu: () => void;
}

const MenuToggleBtn = (props: Props) => {
	const { isOpen, toggleMenu } = props;

	return (
		<Button
			data-testid="toggle-btn"
			className="toggle-btn"
			type="button"
			open={isOpen}
			onClick={toggleMenu}
		>
			{isOpen ? <X size={32} /> : <Menu size={32} />}
			<ScreenReaderText text="Öppna/stäng meny" />
		</Button>
	);
};

export default MenuToggleBtn;

interface ButtonProps {
	open?: boolean;
}

const Button = styled.button<ButtonProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	color: var(--color-gray);
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 4px;
	border-radius: 8px;
	transition: all 0.45s;
	outline: 2px solid transparent;
	outline-offset: 2px;

	&:focus,
	&:active,
	&:hover {
		background-color: var(--color-teal);
		color: var(--color-whiteish);
	}

	&:focus,
	&:active {
		outline: 2px solid var(--color-teal);
	}

	&:hover {
		transform: rotate(180deg);
	}
`;
