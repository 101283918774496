import { Check } from "react-feather";
import styled from "styled-components";
import { ScreenReaderText } from "../ScreenReaderText";

interface Props {
	currentStep: number;
	totalSteps: number;
}

/**
 * @description A wizard component that displays where the user is in the process of completing a series of steps
 * @param currentStep the current step the user is on
 * @param totalSteps The total number of steps in the wizard
 */

export function Wizard(props: Props) {
	const { totalSteps, currentStep } = props;

	const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);

	return (
		<WizardContainer aria-label="progress">
			<ProgressBar>
				<Progress currentStep={currentStep} totalSteps={totalSteps} />
			</ProgressBar>
			<StepsList>
				{steps.map(step => (
					<Step
						key={step}
						aria-current={currentStep === step}
						className={
							(step === currentStep ? "active" : "") +
							(currentStep > step ? "complete" : "")
						}
					>
						{currentStep > step ? (
							<>
								<Check size={18} strokeWidth={2} />
								<ScreenReaderText text="Klart" />
							</>
						) : (
							<>
								<p>{step}</p>
								{currentStep === step && (
									<ScreenReaderText text="Aktivt" />
								)}
								{currentStep < step && (
									<ScreenReaderText text="Inte klart" />
								)}
							</>
						)}
					</Step>
				))}
			</StepsList>
		</WizardContainer>
	);
}

const WizardContainer = styled.section`
	padding: 0;
	width: 100%;
	max-width: 200px;
	margin: 0 auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 500px) {
		max-width: 150px;
	}
`;

const StepsList = styled.ol`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	list-style-type: none;
	padding: 0;
`;

const ProgressBar = styled.div`
	position: absolute;
	width: calc(100% - 2px);
	height: 2px;
	background-color: var(--color-light-gray);
	z-index: -1;
`;

interface ProgressProps {
	currentStep: number;
	totalSteps: number;
	secondary?: boolean;
}

const Progress = styled.div<ProgressProps>`
	width: ${props =>
		`${(100 / (props.totalSteps - 1)) * (props.currentStep - 1)}%`};
	height: 2px;
	background-color: var(--color-gray);
`;

const Step = styled.li`
	margin: 4px 0;
	height: 22px;
	width: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 8px 8px;
	font-weight: 600;
	color: var(--color-whiteish);
	background-color: var(--color-light-gray);
	border-radius: 100%;
	font-size: 0.75rem;

	&.active {
		background-color: var(--color-gray);
		color: var(--color-whiteish);
	}

	&.complete {
		background-color: var(--color-gray);
		color: var(--color-light-gray);
	}

	& p {
		transform: translateY(2px);
	}

	@media (max-width: 500px) {
		width: 17px;
		height: 17px;
		font-size: 0.6rem;

		& p {
			transform: translateY(1px);
		}
	}
`;
