import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { User } from "react-feather";
import {
	validateIdentityNumber,
	validateEmail,
	validateFirstName,
	validateLastName,
	validateStreet,
	validateZipcode,
	validateCity,
} from "../../../utils/validators";
import { CircledIcon } from "../../../components/CircledIcon";
import { TextInput } from "../../../components/FormComponents/TextInput";
import { DottedDivider } from "../../../components/Dividers/DottedDivider";

interface Props {
	customer: {
		identity_number: string;
		email: string;
		first_name: string;
		name: string;
		address: string;
		postal_code: string;
		city: string;
		contact_phone: string;
	};
	setCustomer: React.Dispatch<
		React.SetStateAction<{
			identity_number: string;
			email: string;
			first_name: string;
			name: string;
			address: string;
			postal_code: string;
			city: string;
			contact_phone: string;
		}>
	>;
	setButtonIsDisabled: (buttonIsDisabled: boolean) => void;
}

export const Step3: React.FC<Props> = props => {
	const { customer, setCustomer, setButtonIsDisabled } = props;

	const [inputValueIsValid, setInputValueIsValid] = useState(true);

	useEffect(() => {
		setButtonIsDisabled(true);

		if (
			customer.identity_number &&
			customer.email &&
			customer.first_name &&
			customer.name &&
			customer.address &&
			customer.postal_code &&
			customer.city
		) {
			setButtonIsDisabled(false);
		}
	}, [
		customer.address,
		customer.city,
		customer.email,
		customer.first_name,
		customer.identity_number,
		customer.name,
		customer.postal_code,
		setButtonIsDisabled,
	]);

	const inputValueHandler = ({
		target: { name, value },
	}: React.ChangeEvent<HTMLInputElement>) => {
		setCustomer(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	return (
		<ArticleWrapper>
			<CircledIcon iconName="user">
				<User />
			</CircledIcon>
			<h2>3. Mina uppgifter</h2>

			<TextInput
				type="text"
				inputName="identity_number"
				label="Personnummer"
				placeholder="YYYYMMDD-XXXX"
				value={customer.identity_number}
				setMultipleValue={inputValueHandler}
				isValid={inputValueIsValid}
				setIsValid={setInputValueIsValid}
				validate={validateIdentityNumber}
			/>

			<TextInput
				type="email"
				inputName="email"
				label="Mejladdress"
				placeholder="namn@mail.com"
				value={customer.email}
				setMultipleValue={inputValueHandler}
				isValid={inputValueIsValid}
				setIsValid={setInputValueIsValid}
				validate={validateEmail}
			/>

			<DottedDivider />

			<TextInput
				type="text"
				inputName="first_name"
				label="Förnamn"
				placeholder="Anna"
				value={customer.first_name}
				setMultipleValue={inputValueHandler}
				isValid={inputValueIsValid}
				setIsValid={setInputValueIsValid}
				validate={validateFirstName}
			/>

			<TextInput
				type="text"
				inputName="name"
				label="Efternamn"
				placeholder="Andersson"
				value={customer.name}
				setMultipleValue={inputValueHandler}
				isValid={inputValueIsValid}
				setIsValid={setInputValueIsValid}
				validate={validateLastName}
			/>

			<DottedDivider />

			<TextInput
				type="text"
				inputName="address"
				label="Adress"
				placeholder="Exempelvägen 1"
				value={customer.address}
				setMultipleValue={inputValueHandler}
				isValid={inputValueIsValid}
				setIsValid={setInputValueIsValid}
				validate={validateStreet}
			/>

			<TextInput
				type="text"
				inputName="postal_code"
				label="Postnummer"
				placeholder="123 45"
				value={customer.postal_code}
				setMultipleValue={inputValueHandler}
				isValid={inputValueIsValid}
				setIsValid={setInputValueIsValid}
				validate={validateZipcode}
			/>

			<TextInput
				type="text"
				inputName="city"
				label="Ort"
				placeholder="Exempelstad"
				value={customer.city}
				setMultipleValue={inputValueHandler}
				isValid={inputValueIsValid}
				setIsValid={setInputValueIsValid}
				validate={validateCity}
			/>
		</ArticleWrapper>
	);
};

const ArticleWrapper = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: clamp(300px, 350px, 50vw);

	h2,
	h3 {
		margin: 1rem 0;
	}
`;
