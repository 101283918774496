import styled from "styled-components";
import bankidlogo from "../../assets/images/logo-bank-id.svg";
import { Button } from "../../components/Button";
import { detectMobileDevice } from "../../utils/detectMobileDevice";

const isMobileDevice = detectMobileDevice();

interface Props {
	handleLoginOnSameDevice: () => void;
	handleLoginWithQr: () => void;
	responseMessage: string;
}

const MakeChoiceView = (props: Props) => {
	const { handleLoginOnSameDevice, handleLoginWithQr, responseMessage } =
		props;

	return (
		<Container>
			<BankIdLogo src={bankidlogo} alt="BankID" />
			{isMobileDevice ? (
				<Text>
					Vill du identifiera dig med ett BankID på den här enheten
					eller med ett BankID på en annan enhet?
				</Text>
			) : (
				<Text>
					Vill du identifiera dig med BankID på den här datorn eller
					med ett Mobilt BankID?
				</Text>
			)}
			{responseMessage && <ErrorMessage>{responseMessage}</ErrorMessage>}
			<Button secondary onClick={handleLoginOnSameDevice}>
				BankID på {isMobileDevice ? "samma enhet" : "denna dator"}
			</Button>
			<Button secondary onClick={handleLoginWithQr}>
				{isMobileDevice ? "BankID på annan enhet" : "Mobilt BankID"}
			</Button>
			<Text>
				Om du inte har något BankID kan du hämta ett hos din
				internetbank.
			</Text>
		</Container>
	);
};

export default MakeChoiceView;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Text = styled.p`
	font-size: var(--font-size-s);
	text-align: center;
	margin-top: 1rem;

	&:first-of-type {
		font-size: var(--font-size-base);
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}
`;

const BankIdLogo = styled.img`
	width: 60px;
	height: auto;
	margin-bottom: 1rem;
	opacity: 0.7;
	align-self: center;
`;

const ErrorMessage = styled.p`
	text-align: center;
	font-size: var(--font-size-s);
	margin-top: 0;
	margin-bottom: 1rem;
	color: var(--color-error);
	font-weight: bold;
`;
