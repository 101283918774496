import React from "react";
import styled from "styled-components";
import { Phone, Mail, MapPin } from "react-feather";
import { CloseButton } from "../Modal/CloseButton";
import { ReactComponent as Blob } from "../../assets/images/blob1.svg";
import { ReactComponent as Waves } from "../../assets/images/waves-double.svg";
import { Breakpoints } from "../../styles/Breakpoints";
import { VOBBIZ_CALL, VOBBIZ_MAIL } from "../../constants/constants";

/**
 * @description A modal that displays the contact information to Vobbiz
 */

export const CustomerSupportModal: React.FC = () => (
	<Modal
		data-testid="customer-support-modal"
		aria-modal="true"
		aria-label="customer_support_dialog"
		role="dialog"
		onClick={e => e.stopPropagation()}
	>
		<Waves />
		<Content>
			<CloseButton />
			<Heading>Hej där! 👋</Heading>
			<Text>
				Har du stött på problem eller behöver du kanske hjälp med något?
				Kontakta vår support! Vi finns tillgängliga mellan 08-17 alla
				vardagar 😊
			</Text>
			<ContactInfo>
				<Link href={VOBBIZ_CALL}>
					<ContactWrapper>
						<Blob />
						<div className="contact">
							<div className="icon-wrapper">
								<Phone strokeWidth="2" size="3rem" />
							</div>
							<div>
								<ContactHeading>Ring</ContactHeading>
								<p>031-745&nbsp;67&nbsp;50</p>
							</div>
						</div>
					</ContactWrapper>
				</Link>
				<Link href={`mailto:${VOBBIZ_MAIL}`}>
					<ContactWrapper>
						<Blob />
						<div className="contact">
							<div className="icon-wrapper">
								<Mail strokeWidth="2" size="3rem" />
							</div>
							<div>
								<ContactHeading>Maila</ContactHeading>
								<p>{VOBBIZ_MAIL}</p>
							</div>
						</div>
					</ContactWrapper>
				</Link>
				<Link
					href="https://goo.gl/maps/NxxvaNm2BqoV2KUS6"
					target="_blank"
				>
					<ContactWrapper>
						<Blob />
						<div className="contact">
							<div className="icon-wrapper">
								<MapPin
									id="icon"
									strokeWidth="2"
									size="3.5rem"
								/>
							</div>
							<Address>
								<ContactHeading>Besök oss</ContactHeading>
								<p>
									<span>Gamla Almedalsvägen 4</span>
									<span>412 63 Göteborg</span>
								</p>
							</Address>
						</div>
					</ContactWrapper>
				</Link>
			</ContactInfo>
		</Content>
	</Modal>
);

const Modal = styled.div`
	outline: none;
	overflow: hidden;
	width: 600px;
	max-width: 90vw;
	margin-inline: auto;
	height: auto;
	max-height: 90vh;
	background-color: var(--color-whiteish);
	border-radius: 1.5rem;
	position: relative;
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);

	&.wave {
		width: 100%;
		height: auto;
		position: absolute;
		top: 0;
		left: 0;
	}
`;

const Content = styled.section`
	padding: 32px 16px 16px;
	overflow-y: scroll;
	margin-top: -64px;
	height: 100%;
	max-height: calc(90vh - 200px);

	& > button {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		padding: 32px;
		margin-top: -98px;
	}
`;

const Heading = styled.h2`
	position: absolute;
	top: 2rem;
	left: 2rem;
	font-size: var(--font-size-xl);
	color: var(--color-whiteish);
	transform: rotate(-3deg);

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		top: 3rem;
		left: 3rem;
	}
`;

const Text = styled.p`
	margin-bottom: 32px;
`;

const ContactWrapper = styled.article`
	display: flex;
	width: 100%;
	position: relative;
	transition: all 0.2s;

	.blob {
		width: 30%;
		min-width: 120px;
		transition: all 0.2s;
	}

	&:hover {
		& .blob {
			transform: scale(1.2);
			left: 0;
			top: -10px;
		}
	}

	& .icon-wrapper {
		color: var(--color-teal);
	}

	& .contact {
		display: flex;
		align-items: center;
		gap: 16px;
		z-index: 1;
		position: absolute;
		left: 32px;
		top: 50%;
		transform: translateY(-50%);
		min-width: 50%;
	}

	& p:first-of-type {
		width: 100%;
		min-width: 200px;
		padding: 6px 0;
		border-top: 1px solid #59939c53;
	}

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		& p:first-of-type {
			min-width: 300px;
		}
	}
`;

const ContactHeading = styled.h3`
	color: var(--color-gray);
	font-size: var(--font-size-m);
	transform: rotate(-2deg);
`;

const ContactInfo = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-inline: auto;
	gap: 16px;
`;

const Link = styled.a`
	width: 100%;
	text-decoration: none;
	color: var(--color-blackish);
	outline: none;
	border-radius: 0.5rem;
	transition: all 0.3s;

	&:focus {
		outline: 3px solid var(--color-teal);
		outline-offset: 16px;
	}

	&:hover {
		h3 {
			color: var(--color-teal);
		}
	}
`;

const Address = styled.address`
	width: 100%;
	& span {
		display: block;
	}
`;
