import { SimCard } from "../models/simCard";
import { SimWithPUK } from "../models/simWithPuk";
import { Subscription } from "../models/subscription";
import { formatPhoneNumber } from "./formatPhoneNumber";

export const pairSimCardDataWithPhoneNumbers = (
	simCardData: SimCard[],
	subscriptions: Subscription[]
) => {
	const arrayOfSimCardDataWithPhoneNumbers: SimWithPUK[] = [];

	subscriptions.forEach(subscription => {
		const filteredSimData = simCardData.find(
			data => data.imsi === subscription.phone_numbers[0].imsi
		);

		if (filteredSimData) {
			arrayOfSimCardDataWithPhoneNumbers.push({
				phoneNumber: formatPhoneNumber(
					subscription.phone_numbers[0].phone_number
				),
				imsi: subscription.phone_numbers[0].imsi,
				puk: filteredSimData.puk1,
				icc: filteredSimData?.icc,
			});
		}
	});

	return arrayOfSimCardDataWithPhoneNumbers;
};
