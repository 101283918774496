export enum BankIdStatus {
	pending = "pending",
	failed = "failed",
	complete = "complete",
}

// BankID response interfaces

export interface BankIdPendingCollectResponse {
	status: BankIdStatus;
	orderRef: string;
	userMessage?: string;
}
export interface BankIdSuccessResponse {
	token: string;
}

export class BankIdErrorResponse {
	constructor(message: string, abortFurtherRequests?: boolean) {
		this.message = message;
		this.abortFurtherRequests = abortFurtherRequests;
	}

	message: string;

	abortFurtherRequests?: boolean = false;
}

export interface BankIdCancelResponse {
	success: boolean;
}

export interface BankIdAuthResponse {
	orderRef: string;
	autoStartToken: string;
	qrStartToken: string;
}

export interface BankIdQrResponse {
	qrCode: string;
	token?: string;
}

// BankID options interfaces

export interface GenerateQrCodeOptions {
	secondsSinceAuth: number;
	qrStartToken: string;
	orderRef: string;
	signal: AbortSignal;
}

export interface HandlePendingLoginWithQrOptions {
	qrStartToken: string;
	orderRef: string;
	signal: AbortSignal;
	setResponseMessage: (message: string) => void;
	setQrImage: (image: string) => void;
}

export interface HandlePendingLoginOnSameDeviceOptions {
	orderRef: string;
	signal: AbortSignal;
	setResponseMessage: (message: string) => void;
	autostart: boolean;
}

export interface CollectOptions {
	orderRef: string;
	signal: AbortSignal;
	usingQr: boolean;
	autostart?: boolean;
}
