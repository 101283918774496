import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../Button";
import { Menu } from "../../Menu";
import MenuToggleBtn from "../../Menu/MenuToggleBtn";

interface Props {
	hideMenu?: boolean;
	showMittVobbizLink?: boolean;
}

export const MobileHeader = ({ hideMenu, showMittVobbizLink }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		document.body.style.overflow = "unset";
		if (isOpen) {
			window.addEventListener("keydown", handleKeyDown);
			document.body.style.overflow = "hidden";
		}
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
			document.body.style.overflow = "unset";
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleKeyDown = (e: KeyboardEvent): void => {
		if (e.key === "Escape") {
			toggleMenu();
		}
	};

	const redirectToMittVobbiz = () => {
		navigate("/logga-in");
	};

	return (
		<HeaderWrapper data-testid="mobile-header">
			<Link to="/">
				<LogoWrapper>
					<svg
						data-testid="vobbiz-logo"
						id="logo"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 232.74 232.74"
					>
						<linearGradient id="gradient">
							<stop className="main-stop" offset="0%" />
							<stop className="alt-stop" offset="100%" />
						</linearGradient>
						<path
							className="header-logo"
							d="M218.66,194A116.37,116.37,0,1,0,335,310.38,116.37,116.37,0,0,0,218.66,194Zm77.06,78.44L222.93,397.38a4.94,4.94,0,0,1-8.54,0L141.6,272.45a4.94,4.94,0,0,1,4.27-7.43h81.21a8.34,8.34,0,0,1,8.34,8.35l0,33.6a2.68,2.68,0,0,0,5,1.35L262,271.17A12.37,12.37,0,0,1,272.65,265h18.81A4.93,4.93,0,0,1,295.72,272.45Z"
							transform="translate(-102.29 -194.01)"
						/>
					</svg>
				</LogoWrapper>
			</Link>
			{!hideMenu && !isOpen && (
				<MenuToggleBtn isOpen={isOpen} toggleMenu={toggleMenu} />
			)}
			{!hideMenu && <Menu isOpen={isOpen} setIsOpen={setIsOpen} />}
			{showMittVobbizLink && hideMenu && (
				<Button onClick={redirectToMittVobbiz} secondary>
					Mitt Vobbiz
				</Button>
			)}
		</HeaderWrapper>
	);
};

const HeaderWrapper = styled.header`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 5rem;
	padding: 1rem;
	position: fixed;
	top: 0;
	z-index: 5;

	& ~ * {
		margin-top: 60px;
	}

	#logo {
		width: 3rem;
	}

	.header-logo {
		background-size: cover;
		fill: url(#gradient);
	}

	.main-stop {
		stop-color: var(--color-pink);
	}

	.alt-stop {
		stop-color: var(--color-purple);
	}

	& a {
		border-radius: 8px;

		&:active,
		&:focus {
			outline: 3px solid var(--color-teal);
			outline-offset: 2px;
		}
	}
`;

const LogoWrapper = styled.div`
	width: fit-content;
	background: var(--color-whiteish);
	border-radius: 50%;
`;
