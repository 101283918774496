import { useEffect, useState } from "react";
import styled from "styled-components";
import { Check } from "react-feather";
import { CircledIcon } from "../../../components/CircledIcon";
import { DottedDivider } from "../../../components/Dividers/DottedDivider";
import { GradientTextBox } from "../../../components/GradientTextBox";
import { CheckBox } from "../../../components/FormComponents/CheckBox";
import { Product } from "../../../models/product";
import { CustomerToBeCreated } from "../../../models/customer";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { Modal } from "../../../models/modal";
import {
	selectModalState,
	setActiveModal,
	toggleModal,
} from "../../../store/slices/modalSlice";
import { TextInput } from "../../../components/FormComponents/TextInput";

interface Props {
	keepPhoneNumber: string;
	customer: CustomerToBeCreated;
	selectedSubscription: Product | undefined;
	setButtonIsDisabled: (buttonIsDisabled: boolean) => void;
	discountCode: string;
	setDiscountCode: (discount: string) => void;
}

export const Step5 = (props: Props) => {
	const {
		keepPhoneNumber,
		customer,
		selectedSubscription,
		setButtonIsDisabled,
		discountCode,
		setDiscountCode,
	} = props;

	const [isChecked, setIsChecked] = useState(false);
	const [inputValueIsValid, setInputValueIsValid] = useState(true);
	const [wantsDiscount, setWantsDiscount] = useState(false);
	const dispatch = useAppDispatch();
	const modalIsActive = useAppSelector(selectModalState);

	const privacyPolicyHandler = () => {
		dispatch(setActiveModal(Modal.PrivacyPolicy));
		dispatch(toggleModal());
	};

	const termsOfServicesHandler = () => {
		dispatch(setActiveModal(Modal.TermsOfService));
		dispatch(toggleModal());
	};

	const inputValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDiscountCode(event.target.value.toUpperCase());
	};

	useEffect(() => {
		setButtonIsDisabled(true);

		if (isChecked) {
			setButtonIsDisabled(false);
		}
	}, [setButtonIsDisabled, isChecked]);

	return (
		<ArticleWrapper>
			<CircledIcon iconName="check">
				<Check />
			</CircledIcon>
			<h2>5. Sammanfattning</h2>

			<PurpleCard>
				<h4>Nu är vi nästan klara! 🎉 </h4>
				<p>
					Titta igenom dina val en sista gång och om allt ser bra ut
					så kör vi!
				</p>
			</PurpleCard>

			{keepPhoneNumber ? (
				<section className="phone-number">
					<h4>Jag vill behålla mitt nuvarande nummer</h4>
					<GradientTextBox text={keepPhoneNumber} />
				</section>
			) : (
				<section>
					<h4>Jag har valt ett nytt nummer</h4>
					<p className="new-number">
						Vobbiz skickar information till mig inom kort
					</p>
				</section>
			)}

			<DottedDivider />
			<section className="personal-info">
				<h4>Personuppgifter</h4>
				{customer && (
					<ul>
						<li>{customer.identity_number}</li>
						<li>{customer.email}</li>
						<li>
							{customer.first_name} {customer.name}
						</li>
						<li>{customer.address}</li>
						<li>
							{customer.postal_code} {customer.city}
						</li>
					</ul>
				)}
			</section>

			<DottedDivider />
			{selectedSubscription && (
				<section className="my-subscription">
					<h4>Mitt abonnemang</h4>
					<ul>
						<li>
							<strong>{selectedSubscription.name}</strong>
						</li>
						<li>
							Månadsbetalning,{" "}
							{selectedSubscription.unit_price * 1.25} kr/månad
						</li>
						<li>Fria samtal och SMS</li>
						<li>Ingen bindningstid</li>
						<li>{selectedSubscription.info}</li>
					</ul>
				</section>
			)}

			<DottedDivider />
			<section className="payment-method">
				<h4>Betalningsmetod</h4>
				<p className="payment">Månadsfaktura till din mejladdress</p>
			</section>

			<DottedDivider />

			<section className="discounts">
				{wantsDiscount || discountCode ? (
					<>
						<button
							type="button"
							onClick={() => setWantsDiscount(false)}
							className="styled-button padding-bottom"
						>
							Jag har ingen rabattkod
						</button>
						<TextInput
							type="text"
							inputName="discount"
							label="Skriv din rabattkod här"
							placeholder="Rabattkod123"
							value={discountCode}
							setMultipleValue={inputValueHandler}
							isValid={inputValueIsValid}
							setIsValid={setInputValueIsValid}
						/>
					</>
				) : (
					<button
						type="button"
						onClick={() => setWantsDiscount(true)}
						className="styled-button"
					>
						Klicka här om du har en rabattkod
					</button>
				)}
			</section>

			<DottedDivider />

			<TermsAndConditions>
				<CheckBox
					ariaLabel="Jag godkänner villkoren"
					isChecked={isChecked}
					setIsChecked={setIsChecked}
				/>

				<p className="terms-of-service">
					Jag godkänner Vobbiz{" "}
					<button
						aria-pressed={modalIsActive}
						onClick={termsOfServicesHandler}
						type="button"
						className="styled-button"
					>
						allmänna villkor
					</button>{" "}
					och att mina personuppgifter behandlas i enlighet med{" "}
					<button
						aria-pressed={modalIsActive}
						onClick={privacyPolicyHandler}
						type="button"
						className="styled-button"
					>
						rådande integritetspolicy
					</button>
					.
				</p>
			</TermsAndConditions>
		</ArticleWrapper>
	);
};

const ArticleWrapper = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: clamp(90%, 450px, 500px);
	max-width: 100%;
	padding: 0 16px;
	margin: 0 auto;

	@media (max-width: 768px) {
		width: clamp(300px, 375px, 500px);
	}

	h2,
	h3 {
		margin: 1rem 0;
	}

	section {
		margin: 1rem 0;
	}

	section.discounts {
		margin-bottom: 0;
	}

	section.personal-info,
	section.my-subscription,
	section.payment-method,
	section.discounts {
		align-self: flex-start;
	}

	input {
		margin-bottom: -16px;
	}

	ul,
	p.payment,
	p.terms-of-service,
	p.new-number {
		list-style-type: none;
		padding: 0;
		max-width: min(80vw, 450px);
	}

	a {
		text-decoration: none;
		font-weight: 700;
		color: inherit;

		&:hover {
			text-decoration: underline;
		}
	}

	.styled-button {
		display: inline;
		cursor: pointer;
		background: inherit;
		color: inherit;
		border: none;
		font-weight: 700;
		padding: 0;

		&:focus,
		&:active {
			outline: 2px solid var(--color-purple);
		}
	}

	.padding-bottom {
		margin-bottom: 16px;
	}
`;

const PurpleCard = styled.section`
	max-width: 90%;
	width: 300px;
	border: 4px dotted var(--color-purple);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
	border-radius: 0.5rem;
	padding: 1rem;
	background: rgba(197, 173, 237, 0.1);
`;

const TermsAndConditions = styled.section`
	display: flex;
	align-self: flex-start;

	.check-box {
		margin-right: 1rem;
	}
`;
