import { useEffect } from "react";
import styled from "styled-components";
import { CreditCard } from "react-feather";
import { CircledIcon } from "../../../components/CircledIcon";
import { RadioButton } from "../../../components/FormComponents/RadioButton";

interface Props {
	setButtonIsDisabled: (buttonIsDisabled: boolean) => void;
}

export const Step4 = (props: Props) => {
	const { setButtonIsDisabled } = props;

	useEffect(() => {
		setButtonIsDisabled(false);
	}, [setButtonIsDisabled]);

	return (
		<ArticleWrapper>
			<CircledIcon iconName="credit-card">
				<CreditCard />
			</CircledIcon>
			<h2>4. Välj betalmetod</h2>

			<ButtonContainer>
				<RadioButton
					name="pay-method"
					value="invoice"
					id="invoice"
					checked
				>
					{" "}
					Månadsfaktura till din mejladdress
				</RadioButton>
			</ButtonContainer>
		</ArticleWrapper>
	);
};

const ArticleWrapper = styled.article`
	display: flex;
	flex-direction: column;

	.gradient-circle,
	h2 {
		align-self: center;
	}

	h2 {
		margin: 1rem 0;
	}
`;

const ButtonContainer = styled.section`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	.radio-button {
		margin-right: 1rem;
	}
`;
