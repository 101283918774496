import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Phone } from "react-feather";
import { CircledIcon } from "../../../components/CircledIcon";
import { TextInput } from "../../../components/FormComponents/TextInput";
import { RadioButton } from "../../../components/FormComponents/RadioButton";
import { validatePhoneNumber } from "../../../utils/validators";

interface Props {
	setButtonIsDisabled: (buttonIsDisabled: boolean) => void;
	keepPhoneNumber: string;
	setKeepPhoneNumber: (value: string) => void;
	changePhoneNumber: boolean;
	setChangePhoneNumber: (value: boolean) => void;
}

export const Step2: React.FC<Props> = props => {
	const {
		setButtonIsDisabled,
		keepPhoneNumber,
		setKeepPhoneNumber,
		changePhoneNumber,
		setChangePhoneNumber,
	} = props;

	const [inputValueIsValid, setInputValueIsValid] = useState(true);
	const [keepNumberButtonIsChecked, setKeepNumberButtonIsChecked] =
		useState(true);
	const textInputRef = useRef<HTMLInputElement>(null);
	const radioButtonRef = useRef<HTMLInputElement>(null);

	const focusTextInput = () => {
		if (
			textInputRef.current &&
			radioButtonRef.current &&
			radioButtonRef.current.checked
		) {
			textInputRef.current.focus();
		}
	};

	useEffect(() => {
		focusTextInput();
	}, []);

	useEffect(() => {
		setButtonIsDisabled(true);
		if (inputValueIsValid && keepPhoneNumber !== "") {
			setButtonIsDisabled(false);
		} else if (changePhoneNumber) {
			setButtonIsDisabled(false);
		}
	}, [
		setButtonIsDisabled,
		inputValueIsValid,
		keepPhoneNumber,
		changePhoneNumber,
	]);

	const radioButtonHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.id === "keepCurrentPhoneNumber") {
			focusTextInput();
			setChangePhoneNumber(false);
			setKeepNumberButtonIsChecked(true);
		} else if (e.target.id === "createNewNumber") {
			setKeepPhoneNumber("");
			setChangePhoneNumber(true);
			setKeepNumberButtonIsChecked(false);
		}
	};

	const textInputHandler = () => {
		if (textInputRef.current && radioButtonRef.current) {
			setKeepNumberButtonIsChecked(true);
		}
	};

	return (
		<FormWrapper>
			<CircledIcon iconName="phone">
				<Phone />
			</CircledIcon>
			<h2>2. Välj nummer</h2>

			<h4 className="keep-number-header">
				Jag vill behålla mitt nuvarande nummer
			</h4>
			<ButtonContainer>
				<RadioButton
					ariaLabel="Jag vill behålla mitt nuvarande nummer"
					name="phone-number"
					value={keepPhoneNumber}
					id="keepCurrentPhoneNumber"
					onChange={radioButtonHandler}
					ref={radioButtonRef}
					checked={keepNumberButtonIsChecked}
				/>
				<TextInput
					type="text"
					inputName="phone-number"
					placeholder="Mitt nuvarande nummer"
					value={keepPhoneNumber}
					setValue={setKeepPhoneNumber}
					isValid={inputValueIsValid}
					setIsValid={setInputValueIsValid}
					style={{ width: "350px" }}
					validate={validatePhoneNumber}
					ref={textInputRef}
					onClick={textInputHandler}
				/>
			</ButtonContainer>

			<h4>Jag vill ha ett nytt nummer</h4>
			<ButtonContainer>
				<RadioButton
					ariaLabel="Jag vill ha ett nytt nummer"
					name="phone-number"
					value="changeNumber"
					id="createNewNumber"
					onChange={radioButtonHandler}
					checked={!keepNumberButtonIsChecked}
				>
					Vobbiz skickar ett nytt nummer till mig
				</RadioButton>
			</ButtonContainer>
		</FormWrapper>
	);
};

const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;

	.gradient-circle,
	h2 {
		align-self: center;
	}

	h2,
	h4 {
		margin: 16px 8px;
	}
`;

const ButtonContainer = styled.section`
	display: flex;
	align-items: center;
	margin: 16px 8px;

	.radio-button {
		margin-right: 1rem;
	}

	#keepCurrentPhoneNumber {
		position: relative;
		top: -16px;
	}

	.validIcon {
		top: 25%;
	}

	.input-container {
		max-width: 75vw;
	}
`;
