import { useEffect, useState } from "react";
import styled from "styled-components";
import { Mic } from "react-feather";
import { CircledIcon } from "../../components/CircledIcon";
import { PageWrapper } from "../../components/PageWrapper";
import { Subscription } from "../../models/subscription";
import {
	useGetSubscriptionsQuery,
	useCreateContractMutation,
	useDeleteContractMutation,
} from "../../store/slices/subscriptionSlice";
import { ToggleSwitch } from "../../components/FormComponents/ToggleSwitch";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ContractToBeUpdated } from "../../models/contract";
import { useGetCustomerQuery } from "../../store/slices/customerSlice";
import { SelectInput } from "../../components/FormComponents/SelectInput";
import { AlertMessage } from "../../components/AlertMessage";
import { ErrorWhenUserHasNoSubscription } from "../../components/ErrorWhenUserHasNoSubscription/ErrorWhenUserHasNoSubscription";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { getSubscriptionSelectedByUser } from "../../utils/determineUserSelection";

export const VoiceMailPage: React.FC = () => {
	const {
		data: activeSubscriptions,
		isLoading,
		isError,
	} = useGetSubscriptionsQuery();
	const { data: customer } = useGetCustomerQuery();
	const [createContract] = useCreateContractMutation();
	const [deleteContract] = useDeleteContractMutation();

	const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [selectedSubscription, setSelectedSubscription] =
		useState<Subscription>();
	const [contractIdValue, setContractIdValue] = useState("");
	const [alertMessage, setAlertMessage] = useState("");
	const [alertMessageIsVisible, setAlertMessageIsVisible] =
		useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState("");
	const [changeWasSuccessful, setChangeWasSuccessful] = useState(false);

	useEffect(() => {
		document.title = "Mitt Vobbiz - Röstbrevlåda";
	}, []);

	const onChangeToggleHandler = async (
		e: React.ChangeEvent<HTMLFormElement>
	) => {
		if (!isChecked && customer) {
			setAlertMessageIsVisible(false);
			setIsChecked(true);
			const contract: ContractToBeUpdated = {
				product_id: 1103,
				next_price: 0,
				product_name: "Röstbrevlåda",
				unit_price: 0,
				customer_id: customer.id,
				parent_contract_id: Number(e.target.value),
			};

			try {
				setIsWaitingForResponse(true);
				const createdContract = await createContract(contract);
				if ("data" in createdContract) {
					setChangeWasSuccessful(true);
					setAlertMessage("Röstbrevlåda är nu aktiverad.");
					setAlertMessageIsVisible(true);
					setIsWaitingForResponse(false);
				} else {
					throw new Error();
				}
			} catch (err) {
				setChangeWasSuccessful(false);
				setAlertMessage(
					"Något gick fel när vi försökte aktivera röstbrevlådan, försök igen!"
				);
				setAlertMessageIsVisible(true);
				setIsChecked(false);
				setIsWaitingForResponse(false);
			}
		} else if (isChecked && customer) {
			setIsChecked(false);
			const voiceMailId = findIdOfVoiceMailIdBasedOnParentContractId(
				e.target.value
			);

			try {
				setIsWaitingForResponse(true);
				const deletedContract = await deleteContract(voiceMailId[0]);
				if ("data" in deletedContract) {
					setChangeWasSuccessful(true);
					setAlertMessage("Röstbrevlåda är nu inaktiverad.");
					setAlertMessageIsVisible(true);
					setIsWaitingForResponse(false);
				} else {
					throw new Error();
				}
			} catch (err) {
				setChangeWasSuccessful(false);
				setAlertMessage(
					"Något gick fel när vi försökte inaktivera röstbrevlådan, försök igen!"
				);
				setAlertMessageIsVisible(true);
				setIsChecked(true);
				setIsWaitingForResponse(false);
			}
		}
	};

	function findIdOfVoiceMailIdBasedOnParentContractId(contractId: string) {
		const contractIdsToReturn: number[] = [];

		if (activeSubscriptions) {
			activeSubscriptions.forEach(subscription => {
				subscription.contracts.map(contract => {
					if (
						contract.product_name === "Röstbrevlåda" &&
						contract.parent_contract_id === +contractId
					) {
						contractIdsToReturn.push(contract.id);
					}
					return contract.id;
				});
			});
		}

		return contractIdsToReturn;
	}

	const onChangeSelectHandler = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		setSelectedValue(event.target.value);

		if (activeSubscriptions && activeSubscriptions.length > 0) {
			const subscriptionSelectedByUser = getSubscriptionSelectedByUser(
				event,
				activeSubscriptions
			);
			if (subscriptionSelectedByUser) {
				setSelectedSubscription(subscriptionSelectedByUser);
				setContractIdValue(
					subscriptionSelectedByUser.contracts[0].id.toString()
				);
			}

			subscriptionSelectedByUser?.contracts.find(contract => {
				if (contract.product_name === "Röstbrevlåda") {
					setIsChecked(true);
				} else {
					setIsChecked(false);
				}
				return null;
			});
		}
	};

	useEffect(() => {
		if (activeSubscriptions && activeSubscriptions.length === 1) {
			activeSubscriptions[0].contracts.find(subscription => {
				if (subscription.product_name === "Röstbrevlåda") {
					setIsChecked(true);
				} else {
					setIsChecked(false);
				}
				return null;
			});
		}
	}, [activeSubscriptions]);

	return (
		<PageWrapper secondary>
			<Container>
				<CircledIcon iconName="phone" secondary>
					<Mic />
				</CircledIcon>
				<h1>Röstbrevlåda</h1>
				{activeSubscriptions && activeSubscriptions.length > 1 ? (
					<p>
						Här kan du enkelt aktivera eller avaktivera
						röstmeddelanden. Börja med att välja vilket abonnemang
						du vill ändra och klicka sedan på knappen för att
						aktivera eller avaktivera Röstbrevlåda.
					</p>
				) : (
					<p>
						Här kan du enkelt aktivera eller avaktivera
						röstmeddelanden genom att klicka på knappen nedan. 😊
					</p>
				)}

				{isLoading && <LoadingSpinner />}
				{isError && (
					<h3 className="error">
						Något gick fel när vi försökte hämta abonnemangen, testa
						att ladda om sidan.
					</h3>
				)}
				{!activeSubscriptions ||
					(activeSubscriptions.length === 0 && (
						<ErrorWhenUserHasNoSubscription />
					))}

				{activeSubscriptions && activeSubscriptions.length > 1 && (
					<form>
						<SelectInput
							onChangeHandler={onChangeSelectHandler}
							value={selectedValue}
							inputName="Välj abonnemang"
						>
							<option value="">Välj abonnemang</option>
							<optgroup label="Aktiva abonnemang">
								{activeSubscriptions.map(subscription => {
									const valueToDisplay = `
									${subscription.contracts[0].product_name} ${formatPhoneNumber(
										subscription.phone_numbers[0]
											.phone_number
									)}`;

									return (
										<option
											key={subscription.id}
											value={valueToDisplay}
										>
											{
												subscription.contracts[0]
													.product_name
											}{" "}
											{formatPhoneNumber(
												subscription.phone_numbers[0]
													.phone_number
											)}
										</option>
									);
								})}
							</optgroup>
						</SelectInput>
					</form>
				)}

				{activeSubscriptions &&
					activeSubscriptions.length > 1 &&
					selectedSubscription && (
						<form onChange={onChangeToggleHandler}>
							<ToggleSwitch
								ariaLabel="Stäng av eller sätt på röstbrevlåda"
								value={contractIdValue}
								setIsChecked={setIsChecked}
								isChecked={isChecked}
								firstValue="AV"
								secondValue="PÅ"
							/>
						</form>
					)}

				{isWaitingForResponse && <LoadingSpinner />}
				{!isWaitingForResponse &&
					!isError &&
					activeSubscriptions &&
					activeSubscriptions.length === 1 && (
						<form onChange={onChangeToggleHandler}>
							<ToggleSwitch
								ariaLabel="Stäng av eller sätt på röstbrevlåda"
								value={String(
									activeSubscriptions[0].contracts[0]
										.contract_number
								)}
								setIsChecked={setIsChecked}
								isChecked={isChecked}
								firstValue="AV"
								secondValue="PÅ"
							/>
						</form>
					)}
				<AlertMessage
					isVisible={alertMessageIsVisible}
					isSuccess={changeWasSuccessful}
				>
					{alertMessage}
				</AlertMessage>
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	margin: 0 auto;

	p {
		margin: 32px;
	}

	.error {
		color: var(--color-error);
	}
`;
