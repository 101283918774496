/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Product } from "../../models/product";
import { useFetchProductsQuery } from "../../store/slices/productSlice";
import { getProductSelectedByUser } from "../../utils/determineUserSelection";
import { SelectInput } from "../FormComponents/SelectInput";
import { LoadingSpinner } from "../LoadingSpinner";

interface Props {
	setSelectedProduct: (product: Product) => void;
	selectedProduct: Product | undefined;
}

const resolveSurf = (name: string) => name.split(" ")[2];

export const DisplayProductsCard: React.FC<Props> = props => {
	const { setSelectedProduct, selectedProduct } = props;
	const [params] = useSearchParams();

	const { data: products = [], isLoading, isError } = useFetchProductsQuery();

	const [selectedValue, setSelectedValue] = useState("");

	useEffect(() => {
		const productParams = params.get("product");
		if (productParams !== null && products) {
			const chosenSubscription = products.find(
				sub => sub.id === Number(productParams)
			);

			if (chosenSubscription) {
				setSelectedProduct(chosenSubscription);
			}
		}
	}, [products, params, setSelectedProduct]);

	const onChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		setSelectedValue(event.target.value);
		const newSubscriptionSelectedByUser = getProductSelectedByUser(
			event,
			products
		);

		if (newSubscriptionSelectedByUser) {
			setSelectedProduct(newSubscriptionSelectedByUser);
		}
	};

	return (
		<FormWrapper>
			{isLoading && <LoadingSpinner />}
			{!isLoading && !isError && products && (
				<SelectInput
					onChangeHandler={onChangeHandler}
					value={selectedValue}
					inputName="Välj abonnemang"
				>
					<option value="">Välj abonnemang</option>
					<optgroup label="Produkter">
						{products.map(product => (
							<option key={product.id} value={product.name}>
								{product.name}
							</option>
						))}
					</optgroup>
				</SelectInput>
			)}
			{isError && (
				<h4 className="error">
					Något gick fel när vi försökte hämta produkterna. Det verkar
					som om vi har tekniska problem. Du kan försöka igen eller
					kontakta vår support för hjälp med att teckna ett
					abonnemang.
				</h4>
			)}
			<SubscriptionsWrapper>
				{selectedProduct && (
					<div>
						<h3>
							{selectedProduct.name},{" "}
							{selectedProduct.unit_price * 1.25} kr/mån
						</h3>
						<ul>
							<li>{resolveSurf(selectedProduct.name)} GB surf</li>
							<li>Fria SMS och samtal</li>
							<li>Ingen bindningstid</li>
							<li>Månadsbetalning</li>
						</ul>
						<p>{selectedProduct.info}</p>
					</div>
				)}
			</SubscriptionsWrapper>
		</FormWrapper>
	);
};

const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 90%;

	.error {
		margin-top: 16px;
		color: var(--color-error);
	}
`;

const SubscriptionsWrapper = styled.section`
	display: flex;
	justify-content: center;
	min-width: 350px;
	max-width: 350px;
	min-height: 225px;
	max-height: 225px;

	ul {
		list-style-type: none;
		margin-bottom: 1rem;
	}
`;
