import React from "react";
import styled from "styled-components";

type Props = {
	text: string;
	mixed?: boolean;
};

export const GradientTextBox: React.FC<Props> = props => {
	const { text, mixed } = props;

	return (
		<Card mixed={mixed}>
			<p>{text}</p>
		</Card>
	);
};

interface CardProps {
	mixed?: boolean;
}

const Card = styled.div<CardProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	background: ${props =>
		props.mixed ? "var(--gradient-mixed)" : "var(--gradient-primary)"};
	color: var(--color-whiteish);
	font-size: var(--font-size-l);
	font-weight: 700;
`;
