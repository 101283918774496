import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MobileHeader } from "../MobileHeader";
import logo from "../../../assets/images/VobbizVit.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import { Breakpoints } from "../../../styles/Breakpoints";
import headerPrimary from "../../../assets/images/header-primary.svg";
import headerSecondary from "../../../assets/images/header-secondary.svg";
import { Button } from "../../Button";
import { SwitchToVobbizCompanyLink } from "../../SwitchToVobbizCompanyLink";

type Props = {
	secondary?: boolean;
	hideMenu?: boolean;
};

/**
 * @interface Props
 * @description A header component with a wavy feeling.
 * @param secondary A boolean that is added if the header should be returned with seceondary colors instead of primary.
 */

export const Header: React.FC<Props> = ({ secondary, hideMenu }) => {
	const { width } = useWindowSize();
	const breakpoint = Number(Breakpoints.TabletLandscape.split("rem")[0]) * 16;
	const location = useLocation();
	const navigate = useNavigate();
	const showMittVobbizLink = location.pathname === "/";

	const redirectToMittVobbiz = () => {
		navigate("/logga-in");
	};

	return (
		<>
			{width > breakpoint ? (
				<HeaderElement
					secondary={secondary}
					data-testid="desktop-header"
				>
					<Link to="/">
						<Logo src={logo} alt="Vobbiz" />
					</Link>
					{showMittVobbizLink && <SwitchToVobbizCompanyLink />}
					{showMittVobbizLink && (
						<Button onClick={redirectToMittVobbiz} secondary>
							Mitt Vobbiz
						</Button>
					)}
				</HeaderElement>
			) : (
				<>
					{showMittVobbizLink && <SwitchToVobbizCompanyLink />}
					<MobileHeader
						showMittVobbizLink={showMittVobbizLink}
						hideMenu={hideMenu}
					/>
				</>
			)}
		</>
	);
};

interface HeaderProps {
	secondary?: boolean;
}

const HeaderElement = styled.header<HeaderProps>`
	overflow: hidden;
	background-image: url(${props =>
		props.secondary ? headerSecondary : headerPrimary});
	width: 100%;
	height: 150px;
	background-size: cover;
	background-position: bottom;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 32px;

	@media screen and (min-width: ${Breakpoints.Desktop}) {
		height: 175px;
	}

	& a {
		border-radius: 8px;

		&:active,
		&:focus {
			outline: 3px solid var(--color-teal);
			outline-offset: 4px;
		}
	}
`;

const Logo = styled.img`
	width: 160px;
	height: auto;
`;
