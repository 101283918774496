export const extractDates = () => {
	const dateToday = new Date();
	const lastDayOfMonth = new Date(
		dateToday.getFullYear(),
		dateToday.getMonth() + 1,
		0
	).getDate();

	const daysUntilEndOfMonth = lastDayOfMonth - dateToday.getDate();

	const daysUntilFirstDayOfNextMonth = daysUntilEndOfMonth + 1;

	return {
		dateToday,
		lastDayOfMonth,
		daysUntilEndOfMonth,
		daysUntilFirstDayOfNextMonth,
	};
};
