import { Phone } from "react-feather";
import styled from "styled-components";
import { Surf } from "../../models/surf";
import waves from "../../assets/images/waves-double-purple.svg";
import { RadialProgressBar } from "../RadialProgressBar";

interface Props {
	surf: Surf;
}

/**
 * @description A card that displays customer surf data
 * @param surf an object containing users surf data, must be of type Surf
 */

export const SurfCard = ({ surf }: Props) => {
	const { phone_number: phoneNumber, currentVolume, maxCapacity } = surf;
	return (
		<Container background={waves}>
			<PhoneNumber>
				<IconContainer>
					<Phone size={28} />
				</IconContainer>
				{phoneNumber.replace("46", "0")}
			</PhoneNumber>
			<ProgressBarContainer>
				<RadialProgressBar
					currentValue={currentVolume}
					totalValue={maxCapacity}
				/>
			</ProgressBarContainer>
		</Container>
	);
};

interface ContainerProps {
	background: string;
}

const Container = styled.article<ContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 350px;
	padding: 32px;
	background-image: url(${props => props.background});
	background-repeat: no-repeat;
	border-radius: 1.5rem;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
`;

const PhoneNumber = styled.h2`
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color-whiteish);
	font-size: var(--font-size-l);
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
	transform: rotate(-1deg);
`;

const IconContainer = styled.span`
	background-color: hsla(263, 62%, 30%, 0.2);
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
	border-radius: 50%;
`;

const ProgressBarContainer = styled.div`
	width: 100%;
	max-width: 250px;
	margin-top: 64px;
	margin-bottom: 32px;
`;
