import { createGlobalStyle } from "styled-components";

export const CssVariables = createGlobalStyle`
  :root {

    // Colors
    --color-purple: #C5ADED;
    --color-pink: #F8ABEB;
    --color-salmon: #FFC8DD;
    --color-teal: #65B3BF;
    --color-teal-dark: hsla(190, 35%, 46%, 1);
    --color-light-teal: #99C7D7;
    --color-blackish: #4B4B4B;
    --color-gray: hsla(0, 0%, 35%, 1.0);
    --color-medium-gray: hsla(0, 0%, 55%, 1.0);
    --color-light-gray: #D9D9D9;
    --color-whiteish: #FCFCFC;
    --color-error: #EF5D80;
    --color-warning: #efa35d;
    --color-success: hsla(190, 22%, 46%, 1.0);

    // Gradients
    // Purple - Pink

     --gradient-primary: linear-gradient(
    135deg,
    hsl(310deg 85% 82%) 0%,
    hsl(305deg 74% 81%) 11%,
    hsl(300deg 65% 80%) 23%,
    hsl(294deg 66% 80%) 34%,
    hsl(288deg 66% 80%) 45%,
    hsl(283deg 66% 81%) 56%,
    hsl(278deg 66% 81%) 67%,
    hsl(272deg 66% 81%) 78%,
    hsl(267deg 65% 81%) 89%,
    hsl(263deg 64% 80%) 100%
  );

  --gradient-primary-reversed: linear-gradient(
  135deg,
  hsl(263deg 64% 80%) 0%,
  hsl(267deg 65% 81%) 11%,
  hsl(272deg 66% 81%) 22%,
  hsl(278deg 66% 81%) 33%,
  hsl(283deg 66% 81%) 44%,
  hsl(288deg 66% 80%) 55%,
  hsl(294deg 66% 80%) 66%,
  hsl(300deg 65% 80%) 78%,
  hsl(305deg 74% 81%) 89%,
  hsl(310deg 85% 82%) 100%
);

  // Teal - Light Teal
  --gradient-secondary: linear-gradient(
    130deg,
    hsl(188deg 41% 57%) 0%,
    hsl(189deg 42% 59%) 11%,
    hsl(190deg 42% 61%) 22%,
    hsl(190deg 42% 62%) 33%,
    hsl(191deg 43% 64%) 44%,
    hsl(192deg 43% 66%) 56%,
    hsl(193deg 43% 67%) 67%,
    hsl(194deg 43% 69%) 78%,
    hsl(195deg 44% 71%) 89%,
    hsl(195deg 44% 72%) 100%
  );

  --gradient-secondary-reversed: linear-gradient(
  135deg,
  hsl(195deg 44% 72%) 0%,
  hsl(195deg 44% 71%) 11%,
  hsl(194deg 43% 69%) 22%,
  hsl(193deg 43% 67%) 33%,
  hsl(192deg 43% 66%) 44%,
  hsl(191deg 43% 64%) 56%,
  hsl(190deg 42% 62%) 67%,
  hsl(190deg 42% 61%) 78%,
  hsl(189deg 42% 59%) 89%,
  hsl(188deg 41% 57%) 100%
);

  // Purple - Teal
  --gradient-mixed: linear-gradient(
    135deg,
    hsl(263deg 64% 80%) 0%,
    hsl(244deg 66% 81%) 22%,
    hsl(229deg 70% 79%) 37%,
    hsl(218deg 71% 75%) 49%,
    hsl(211deg 69% 71%) 59%,
    hsl(205deg 65% 68%) 69%,
    hsl(200deg 60% 64%) 77%,
    hsl(196deg 54% 61%) 85%,
    hsl(192deg 48% 59%) 93%,
    hsl(188deg 41% 57%) 100%
  );

    // Light teal - Purple
  --gradient-mixed-reversed: linear-gradient(120deg, #99C7D7 0%, #FA79DB 100%);

    // Font families
    --font-primary: 'Martel Sans', sans-serif;

    // Font sizes
    --font-size-s: clamp(0.8rem, 0.18vw + 0.66rem, 0.9rem);
    --font-size-base: clamp(0.88rem, 0.34vw + 0.79rem, 1.06rem);
    --font-size-m: clamp(1.09rem, 0.59vw + 0.95rem, 1.42rem);
    --font-size-l: clamp(1.37rem, 0.95vw + 1.13rem, 1.7rem);
    --font-size-xl: clamp(1.71rem, 1.47vw + 1.34rem, 2.2rem);
    --font-size-xxl: clamp(2.14rem, 2.22vw + 1.58rem, 2.8rem);
    --font-size-xxxl: clamp(2.67rem, 3.28vw + 1.85rem, 4rem);

    // Max content size
    --max-width: 100rem;
    --global-padding: 1rem;
    --menu-width: 18.75rem;

  }
`;
