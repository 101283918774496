import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper } from "../components/PageWrapper";
import { ReactComponent as Lost } from "../assets/images/lost.svg";
import { AnchorText } from "../components/AnchorText";

export const FourOFourPage: React.FC = () => {
	useEffect(() => {
		document.title = "404 - Sidan hittades inte";
	}, []);

	return (
		<PageWrapper hideMenu hideFooter>
			<Container>
				<h1>404 Sidan kunde inte hittas</h1>
				<AnchorText
					desiredRedirectPath="/"
					text="Ta mig tillbaka till startsidan"
				/>
				<Link to="/">
					<Lost />
				</Link>
			</Container>
		</PageWrapper>
	);
};

const Container = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 16px 32px 16px;
	text-align: center;

	& p,
	a,
	h1 {
		margin-bottom: 32px;
	}

	.lost {
		width: 50%;
		min-width: 300px;
		margin: 0 auto;
	}
`;
