import React from "react";
import styled from "styled-components";
import { Facebook, Instagram, Linkedin } from "react-feather";
import { Link } from "react-router-dom";
import logo from "../../assets/images/VobbizVit.svg";
import { Breakpoints } from "../../styles/Breakpoints";
import footerPrimary from "../../assets/images/footer-primary.svg";
import footerSecondary from "../../assets/images/footer-secondary.svg";
import { ScreenReaderText } from "../ScreenReaderText";

type Props = {
	secondary?: boolean;
};

/**
 * @description A footer component that displays the Vobbiz logo and links
 * @param secondary A boolean that is added if the footer should be returned with secondary colors instead of primary.
 */

export const Footer: React.FC<Props> = ({ secondary }) => (
	<Wrapper secondary={secondary} data-testid="footer">
		<div className="footer-content">
			<section className="about-container">
				<Link to="/">
					<img className="logo" src={logo} alt="Vobbiz logo" />
				</Link>
				<p className="about-info">
					Vobbiz är den schyssta operatören där ni fortfarande kan få
					personlig service. Inga avtalstider eller krångliga avtal
					för att beställa något så enkelt som ett abonnemang.
				</p>
			</section>
			<section className="links-container">
				<h3>Snabblänkar</h3>
				<Link to="/" className="link">
					Hem
				</Link>
				<Link to="/bli-kund" className="link">
					Produkter
				</Link>
				<a
					href="https://vobbiz.se/prislista/"
					target="_blank"
					rel="noreferrer"
					className="link"
				>
					Prislista
				</a>
				<a
					href="https://vobbiz.se/privacy-policy/"
					target="_blank"
					rel="noreferrer"
					className="link"
				>
					Privacy Policy
				</a>
				<a
					href="https://vobbiz.se/data-request-policy/"
					target="_blank"
					rel="noreferrer"
					className="link"
				>
					Data Request Policy
				</a>
				<a
					href="https://vobbiz.se/vobbiz-terms-of-service/"
					target="_blank"
					rel="noreferrer"
					className="link"
				>
					Vobbiz Terms of Service
				</a>
				<a
					href="https://vobbiz.se/vobbiz-user-terms-of-service/"
					target="_blank"
					rel="noreferrer"
					className="link"
				>
					Vobbiz User Terms of Service
				</a>
				<a
					href="https://vobbiz.se/vobbiz-acceptable-use-policy/"
					target="_blank"
					rel="noreferrer"
					className="link"
				>
					Vobbiz Acceptable Use Policy
				</a>
			</section>
			<section className="social-media-container">
				<h3>Sociala medier</h3>
				<p>Besök och följ oss på våra olika sociala medier</p>
				<div className="icon-wrapper">
					<a
						href="https://www.facebook.com/Vobbiz"
						target="_blank"
						rel="noreferrer"
						className="social-media-link"
						data-testid="facebook"
					>
						<Facebook className="icon" size={36} />
						<ScreenReaderText text="Länk till Vobbiz Facebook" />
					</a>
					<a
						href="https://www.instagram.com/vobbiz_privat/"
						target="_blank"
						rel="noreferrer"
						className="social-media-link"
						data-testid="instagram"
					>
						<Instagram className="icon" size={36} />
						<ScreenReaderText text="Länk till Vobbiz Instagram" />
					</a>
					<a
						href="https://www.linkedin.com/company/vobbiz/"
						target="_blank"
						rel="noreferrer"
						className="social-media-link"
						data-testid="linkedin"
					>
						<Linkedin className="icon" size={36} />
						<ScreenReaderText text="Länk till Vobbiz LinkedIn" />
					</a>
				</div>
			</section>
		</div>
	</Wrapper>
);

interface FooterProps {
	secondary?: boolean;
}

const Wrapper = styled.footer<FooterProps>`
	width: 100%;
	overflow: hidden;
	background-image: url(${props =>
		props.secondary ? footerSecondary : footerPrimary});
	background-size: cover;
	background-position: top;
	color: var(--color-whiteish);
	display: flex;
	justify-content: center;
	flex-shrink: 0;
	padding: 48px 0 32px 0;

	.footer-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: var(--max-width);
		padding: 0 16px;

		@media screen and (min-width: ${Breakpoints.TabletLandscape}) {
			padding: 0 32px;
		}

		h3 {
			margin-bottom: 8px;
		}

		p.about-info {
			margin-top: 32px;
		}
	}

	@media screen and (min-width: ${Breakpoints.TabletLandscape}) {
		.footer-content {
			flex-direction: row;
			gap: 64px;
		}

		section.links-container,
		section.social-media-container {
			width: 30%;
		}

		section.about-container {
			max-width: 800px;
			width: 40%;
			margin-right: 64px;
		}
	}

	& a {
		border-radius: 8px;
		width: fit-content;

		&:focus {
			outline: ${props =>
				props.secondary
					? "3px solid var(--color-pink)"
					: "3px solid var(--color-teal)"};
			outline-offset: 4px;
		}
	}

	img.logo {
		width: 12rem;
	}

	.link {
		text-decoration: none;
		color: inherit;
		margin: 2px;
		width: fit-content;

		&:hover {
			text-decoration: underline;
		}
	}

	.social-media-link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		margin: 10px;
		background: var(--color-whiteish);
		border-radius: 30%;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
		position: relative;
		overflow: hidden;

		&:hover {
			transform: scale(1.3);

			& .icon {
				color: var(--color-whiteish);
				z-index: 1;
			}
		}

		:before {
			content: "";
			position: absolute;
			left: -60px;
			top: 0;
			width: 120%;
			height: 120%;
			border-radius: 30%;
			background: ${props =>
				props.secondary ? "var(--color-teal)" : "var(--color-purple)"};
			transform: rotate(85deg);
		}

		&:hover:before {
			animation: animation 1.5s 1 forwards;
		}
	}

	.icon {
		color: ${props =>
			props.secondary ? "var(--color-teal)" : "var(--color-purple)"};
		transition: all 0.2s;
	}

	@keyframes animation {
		0% {
			left: -110%;
			top: 90%;
		}

		50% {
			left: 15%;
			top: -30%;
		}

		100% {
			left: -10%;
			top: -10%;
		}
	}

	section {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 16px 0;
	}

	.icon-wrapper {
		display: flex;
	}
`;
