import { useNavigate } from "react-router-dom";
import FocusLock from "react-focus-lock";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { Breakpoints } from "../../styles/Breakpoints";
import { clearTokenFromSessionStorage } from "../../services/sessionStorageServices";
import useWindowSize from "../../hooks/useWindowSize";
import Links from "./Links";

type Props = {
	isOpen?: boolean;
	setIsOpen?: (isOpen: boolean) => void;
};

export const Menu: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();
	const { isOpen, setIsOpen } = props;
	const { width } = useWindowSize();
	const breakpoint = Number(Breakpoints.TabletLandscape.split("rem")[0]) * 16;

	const toggleMenu = () => {
		if (setIsOpen) {
			setIsOpen(!isOpen);
		}
	};

	const handleLogout = () => {
		clearTokenFromSessionStorage();
		navigate("/");
	};

	return (
		<>
			{width < breakpoint && isOpen && (
				<FocusLock>
					<AnimatePresence>
						<MenuWrapper
							initial={{ x: "100%" }}
							animate={{ x: 0 }}
							transition={{
								type: "spring",
								stiffness: 140,
							}}
							exit={{ x: "-100%" }}
							key="menu"
						>
							<Links
								isOpen={isOpen}
								toggleMenu={toggleMenu}
								handleLogout={handleLogout}
							/>
						</MenuWrapper>
						<Overlay
							key="overlay"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							onClick={toggleMenu}
						/>
					</AnimatePresence>
				</FocusLock>
			)}
			{width > breakpoint && (
				<Links
					isOpen={isOpen}
					toggleMenu={toggleMenu}
					handleLogout={handleLogout}
				/>
			)}
		</>
	);
};

const MenuWrapper = styled(motion.div)`
	background: var(--color-whiteish);
	padding: 4rem 2rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	text-align: left;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	max-width: 400px;
	height: 100vh;
	z-index: 10;
	transition: all 0.08s;
`;

const Overlay = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.6);
	z-index: 1;
	cursor: pointer;
	@media screen and (min-width: ${Breakpoints.TabletLandscape}) {
		display: none;
	}
`;
