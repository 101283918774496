import { useEffect, useState } from "react";
import styled from "styled-components";
import { MapPin, MessageCircle, Phone, Mail } from "react-feather";
import { CircledIcon } from "../../components/CircledIcon";
import { PageWrapper } from "../../components/PageWrapper";
import { validateMessage } from "../../utils/validators";
import { TextArea } from "../../components/FormComponents/TextArea";
import { Button } from "../../components/Button";
import { Breakpoints } from "../../styles/Breakpoints";
import { ReactComponent as Blob } from "../../assets/images/blob1.svg";
import { sendEmail } from "../../services/mailServices";
import { useGetCustomerQuery } from "../../store/slices/customerSlice";
import { Customer } from "../../models/customer";
import { AlertMessage } from "../../components/AlertMessage";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { VOBBIZ_MAIL, VOBBIZ_CALL } from "../../constants/constants";

export const ContactUsPage: React.FC = () => {
	const { data: customer } = useGetCustomerQuery();

	const [isLoading, setIsLoading] = useState(false);
	const [inputValueIsValid, setInputValueIsValid] = useState(true);
	const [userMessage, setUserMessage] = useState("");
	const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
	const [actionWasSuccessful, setActionWasSuccessful] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertMessageIsVisible, setAlertMessageIsVisible] =
		useState<boolean>(false);
	const [userData, setUserData] = useState<Customer | null | undefined>(null);

	useEffect(() => {
		document.title = "Mitt Vobbiz - Kontakta oss";
	}, []);

	useEffect(() => {
		if (customer) {
			setUserData(customer);
		}
	}, [customer]);

	useEffect(() => {
		setButtonIsDisabled(true);

		if (userData && userData.email && userMessage && inputValueIsValid) {
			setButtonIsDisabled(false);
		}
	}, [userData, userMessage, inputValueIsValid]);

	const onClickHandler = async () => {
		if (!userData || !userMessage) {
			setActionWasSuccessful(false);
			setAlertMessage(
				"Vi saknar information från dig, vänligen fyll i alla fält och se till att du är inloggad."
			);
			setAlertMessageIsVisible(true);
			return null;
		}

		try {
			setAlertMessageIsVisible(false);
			setIsLoading(true);
			const emailSent = await sendEmail(userData.email, userMessage);

			if (emailSent) {
				setIsLoading(false);
				setActionWasSuccessful(true);
				setAlertMessage(
					"Ditt mail är nu skickat till oss! 🥳 Vi svarar normalt inom 8 timmar."
				);
				setAlertMessageIsVisible(true);
				setUserMessage("");
			} else {
				throw new Error();
			}
		} catch (error) {
			setActionWasSuccessful(false);
			setIsLoading(false);
			setAlertMessage(
				"Något gick fel när vi försökte skicka mailet, vänligen försök igen eller kontakta Vobbiz support."
			);
			setAlertMessageIsVisible(true);
		}

		return "Success!";
	};

	return (
		<PageWrapper secondary>
			<HeaderWrapper>
				<CircledIcon iconName="Message circle" secondary>
					<MessageCircle />
				</CircledIcon>
				<h1>Kontakta oss</h1>
			</HeaderWrapper>
			<ContentWrapper>
				<Content>
					<Text>
						Har du stött på problem eller behöver du kanske hjälp
						med något? Kontakta vår support! Vi finns tillgängliga
						mellan 08-17 alla vardagar 😊
					</Text>
					<ContactInfo>
						<Link href={VOBBIZ_CALL}>
							<ContactWrapper>
								<Blob />
								<div className="contact">
									<div className="icon-wrapper">
										<Phone strokeWidth="2" size="3rem" />
									</div>
									<div>
										<ContactHeading>Ring</ContactHeading>
										<p>031-745&nbsp;67&nbsp;50</p>
									</div>
								</div>
							</ContactWrapper>
						</Link>
						<Link href={`mailto:${VOBBIZ_MAIL}`}>
							<ContactWrapper>
								<Blob />
								<div className="contact">
									<div className="icon-wrapper">
										<Mail strokeWidth="2" size="3rem" />
									</div>
									<div>
										<ContactHeading>Maila</ContactHeading>
										<p>{VOBBIZ_MAIL}</p>
									</div>
								</div>
							</ContactWrapper>
						</Link>
						<Link
							href="https://goo.gl/maps/NxxvaNm2BqoV2KUS6"
							target="_blank"
						>
							<ContactWrapper>
								<Blob />
								<div className="contact">
									<div className="icon-wrapper">
										<MapPin
											id="icon"
											strokeWidth="2"
											size="3.5rem"
										/>
									</div>
									<Address>
										<ContactHeading>
											Besök oss
										</ContactHeading>
										<p>
											<span>Gamla Almedalsvägen 4</span>
											<span>412 63 Göteborg</span>
										</p>
									</Address>
								</div>
							</ContactWrapper>
						</Link>
					</ContactInfo>
				</Content>
				{customer && customer.email && (
					<FormContainer>
						<p>
							Vill du skicka ett mail direkt till oss kan du fylla
							i ditt meddelande i nedanstående ruta, så svarar vi
							dig inom kort.
						</p>

						<TextArea
							inputName="message"
							label="Meddelande *"
							placeholder="Skriv ditt meddelande här"
							value={userMessage}
							setValue={setUserMessage}
							isValid={inputValueIsValid}
							setIsValid={setInputValueIsValid}
							validate={validateMessage}
						/>

						<Button
							secondary
							onClick={onClickHandler}
							disabled={buttonIsDisabled}
						>
							Skicka
						</Button>
						{isLoading && <LoadingSpinner />}
						<AlertMessage
							isVisible={alertMessageIsVisible}
							isSuccess={actionWasSuccessful}
						>
							{alertMessage}
						</AlertMessage>
					</FormContainer>
				)}
			</ContentWrapper>
		</PageWrapper>
	);
};

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-inline: auto;
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	max-width: min(600px 90%);
	min-width: max(300px 90vw);
	margin-block: 2rem;
	margin-inline: auto;

	@media (min-width: ${Breakpoints.Laptop}) {
		gap: 48px;
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
	}

	.loading-spinner {
		margin-top: 16px;
	}
`;

const Content = styled.section`
	max-width: 400px;
`;

const Text = styled.p`
	margin-bottom: 32px;
`;

const ContactWrapper = styled.article`
	display: flex;
	width: 100%;
	position: relative;
	transition: all 0.2s;

	.blob {
		width: 30%;
		min-width: 120px;
		transition: all 0.2s;
	}

	&:hover {
		& .blob {
			transform: scale(1.2);
			left: 0;
			top: -10px;
		}
	}

	& .icon-wrapper {
		color: var(--color-teal);
	}

	& .contact {
		display: flex;
		align-items: center;
		gap: 16px;
		z-index: 1;
		position: absolute;
		left: 32px;
		top: 50%;
		transform: translateY(-50%);
		min-width: 50%;
	}

	& p:first-of-type {
		width: 100%;
		min-width: 200px;
		padding: 6px 0;
		border-top: 1px solid #59939c53;
	}
`;

const ContactHeading = styled.h3`
	color: var(--color-gray);
	font-size: var(--font-size-m);
	transform: rotate(-2deg);
`;

const ContactInfo = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-inline: auto;
	gap: 16px;
`;

const Link = styled.a`
	width: 100%;
	text-decoration: none;
	color: var(--color-blackish);
	outline: none;
	border-radius: 0.5rem;
	transition: all 0.3s;

	&:focus {
		outline: 3px solid var(--color-teal);
		outline-offset: 16px;
	}

	&:hover {
		h3 {
			color: var(--color-teal);
		}
	}
`;

const Address = styled.address`
	width: 100%;
	& span {
		display: block;
	}
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 500px;
	margin-inline: auto;

	@media (max-width: ${Breakpoints.TabletPortrait}) {
		width: 400px;
	}

	@media (max-width: ${Breakpoints.Mobile}) {
		width: 300px;
	}

	p {
		margin-bottom: 32px;

		@media (max-width: ${Breakpoints.Laptop}) {
			margin-top: 32px;
		}
	}
`;
