import { useEffect } from "react";
import { Star } from "react-feather";
import styled from "styled-components";
import { CircledIcon } from "../../components/CircledIcon";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { PageWrapper } from "../../components/PageWrapper";
import { useGetCustomerQuery } from "../../store/slices/customerSlice";
import { useGetSubscriptionsQuery } from "../../store/slices/subscriptionSlice";
import phoneIllustration from "../../assets/images/phone_heart_illustration.svg";
import { Breakpoints } from "../../styles/Breakpoints";
import { News } from "../../components/News/News";
import { DottedDivider } from "../../components/Dividers/DottedDivider";
import { useGetSurfQuery } from "../../store/slices/surfSlice";
import { OverviewCard } from "../../components/OverviewCard/OverviewCard";
import { AnimatedSecondaryHeading } from "../../components/AnimatedSecondaryHeading/AnimatedSecondaryHeading";
import { pairSurfDataWithSubscription } from "../../utils/pairSurfDataWithSubscription";
import { AlertMessage } from "../../components/AlertMessage";

export const OverviewPage: React.FC = () => {
	const {
		data: customer,
		isLoading: customerIsLoading,
		isError: customerError,
	} = useGetCustomerQuery();
	const {
		data: subscriptions,
		isLoading: subscriptionsIsLoading,
		isError: subscriptionsError,
	} = useGetSubscriptionsQuery();
	const {
		data: surfData,
		isLoading: surfDataIsLoading,
		isError: surfDataError,
	} = useGetSurfQuery();
	const hasMoreThanOneSubscription =
		(subscriptions && subscriptions?.length > 1) || false;

	useEffect(() => {
		document.title = "Mitt Vobbiz - Översikt";
	}, []);

	return (
		<PageWrapper secondary>
			<Container>
				{customerIsLoading || subscriptionsIsLoading ? (
					<LoadingSpinner />
				) : (
					<Content>
						{customer && (
							<>
								<CircledIcon iconName="star" secondary>
									<Star />
								</CircledIcon>
								<Heading>
									Välkommen, {customer.first_name}! 👋
								</Heading>
								<NewsSection>
									<WelcomeImage
										src={phoneIllustration}
										alt="phone illustration"
									/>
									<News />
								</NewsSection>
								<DottedDivider />
								{(surfDataIsLoading ||
									subscriptionsIsLoading) && (
									<LoadingSpinner />
								)}
								{subscriptions && surfData && (
									<OverviewCardsContainer
										useGrid={hasMoreThanOneSubscription}
									>
										<AnimatedSecondaryHeading>
											{hasMoreThanOneSubscription
												? "Mina"
												: "Mitt"}{" "}
											abonnemang
										</AnimatedSecondaryHeading>
										{subscriptions.map(subscription => (
											<OverviewCard
												key={subscription.id}
												surfData={pairSurfDataWithSubscription(
													subscription,
													surfData
												)}
												subscription={subscription}
											/>
										))}
									</OverviewCardsContainer>
								)}
							</>
						)}
					</Content>
				)}
				{surfDataError && (
					<AlertMessage isVisible={surfDataError}>
						Det gick inte att hämta surfdata.
					</AlertMessage>
				)}
				{subscriptionsError && (
					<AlertMessage isVisible={surfDataError}>
						Det gick inte att hämta abonnemangsdata.
					</AlertMessage>
				)}
				{customerError && (
					<AlertMessage isVisible={surfDataError}>
						Det gick inte att hämta kunddata.
					</AlertMessage>
				)}
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	width: 100%;
	max-width: 1000px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-inline: auto;
`;

const Content = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
`;

const Heading = styled.h1`
	margin-bottom: 2rem;
	transform: rotate(-1deg);
	text-align: center;
`;

const WelcomeImage = styled.img`
	height: 100%;
	max-height: 250px;
	transform: rotate(6deg);
	width: auto;

	@media (min-width: ${Breakpoints.TabletLandscape}) {
		margin-left: 24px;
	}
`;

const NewsSection = styled.section`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	gap: 32px;

	@media (min-width: ${Breakpoints.Laptop}) {
		flex-direction: row;
	}
`;

const OverviewCardsContainer = styled.section<{ useGrid?: boolean }>`
	align-self: flex-start;
	display: grid;
	width: 100%;
	grid-template-columns: 1fr;
	gap: 32px;
	max-width: 600px;
	margin-inline: auto;

	@media (min-width: ${Breakpoints.Laptop}) {
		grid-template-columns: ${props => (props.useGrid ? "1fr 1fr" : "1fr")};
		max-width: ${props => (props.useGrid ? "100%" : "600px")};
	}

	& h2 {
		grid-column: 1 / -1;
		margin-bottom: -16px;
		text-align: center;
	}
`;
