import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FETCHURL } from "../../constants/constants";
import {
	Contract,
	ContractToBeCreated,
	ContractToBeUpdated,
} from "../../models/contract";
import { Subscription } from "../../models/subscription";
import { getTokenFromSessionStorage } from "../../services/sessionStorageServices";

interface SuccessResponse {
	success: true;
	message: "Contract updated" | "Contract created" | "Contract deleted";
	contract?: Contract;
}

interface UpdateContractData {
	contract: ContractToBeUpdated;
	contractId: number;
}

export const subscriptionSlice = createApi({
	reducerPath: "subscription",
	tagTypes: ["Contract"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${FETCHURL}/api/subscription`,
		prepareHeaders: headers => {
			const token = getTokenFromSessionStorage();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: builder => ({
		getSubscriptions: builder.query<Subscription[], void>({
			query: () => ({
				url: "/",
			}),
			providesTags: ["Contract"],
		}),
		createContract: builder.mutation<SuccessResponse, ContractToBeCreated>({
			query: (contract: Contract) => ({
				url: "/",
				method: "POST",
				body: { contract },
			}),
			invalidatesTags: ["Contract"],
		}),
		updateContract: builder.mutation<SuccessResponse, UpdateContractData>({
			query: ({ contract, contractId }) => ({
				url: `/${contractId}`,
				method: "PUT",
				body: { contract },
			}),
			invalidatesTags: ["Contract"],
		}),
		deleteContract: builder.mutation<SuccessResponse, number>({
			query: (contractId: number) => ({
				url: `/${contractId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Contract"],
		}),
	}),
});

export const {
	useCreateContractMutation,
	useGetSubscriptionsQuery,
	useUpdateContractMutation,
	useDeleteContractMutation,
} = subscriptionSlice;
