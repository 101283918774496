import { FETCHURL } from "../constants/constants";
import { ClientErrorResponse } from "../models/clientErrorResponse";
import { getTokenFromSessionStorage } from "./sessionStorageServices";

export const getSimCardDataFromImsiNumbers = async (imsiNumbers: string[]) => {
	const response = await fetch(`${FETCHURL}/api/sim-card`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getTokenFromSessionStorage()}`,
		},
		method: "POST",
		body: JSON.stringify({ imsiNumbers }),
	});

	const data = await response.json();

	if (!response.ok) {
		throw new ClientErrorResponse(
			data.message || "Något gick fel, försök igen."
		);
	}

	return data.codes;
};
