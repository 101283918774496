import { useEffect } from "react";
import styled from "styled-components";
import { Globe } from "react-feather";
import { PageWrapper } from "../../components/PageWrapper";
import { CircledIcon } from "../../components/CircledIcon";
import { useGetSurfQuery } from "../../store/slices/surfSlice";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { SurfCard } from "../../components/SurfCard/SurfCard";
import illustration from "../../assets/images/woman_in_wheelchair.svg";
import { ErrorWhenUserHasNoSubscription } from "../../components/ErrorWhenUserHasNoSubscription/ErrorWhenUserHasNoSubscription";

export const SurfPage: React.FC = () => {
	const { data: surfData, isLoading, isError } = useGetSurfQuery();

	useEffect(() => {
		document.title = "Mitt Vobbiz - Min surf";
	}, []);

	return (
		<PageWrapper secondary>
			<Container>
				<CircledIcon iconName="surfer" secondary>
					<Globe />
				</CircledIcon>
				<h1>Surf</h1>
				<p>
					Här hittar du information om hur mycket surf du har kvar på
					ditt eller dina abonnemang.
				</p>
				<p>
					P.S Du visste väl att du kan byta abonnemang när som helst
					om surfen sinar? 😎
				</p>
				{isLoading && <LoadingSpinner />}
				{isError && (
					<h3 className="error">
						Något gick fel när vi försökte hämta din surf, försök
						att ladda om sidan.
					</h3>
				)}
				<SurfCardsContainer>
					{surfData &&
						surfData.map(surf => (
							<SurfCard key={surf.phone_number} surf={surf} />
						))}

					{!surfData ||
						(surfData.length === 0 && (
							<ErrorWhenUserHasNoSubscription />
						))}
				</SurfCardsContainer>
				<Illustration
					src={illustration}
					alt="En illustration av kvinna i rullstol som håller sin mobiltelefon i ena handen och håller upp den andra bakom vad som ser ut som ett webbläsarfönster"
				/>
			</Container>
		</PageWrapper>
	);
};

const Container = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 0 16px 32px 16px;
	text-align: center;

	& p {
		margin-bottom: 16px;
	}

	& h1 {
		margin-bottom: 32px;
	}

	.error {
		color: var(--color-error);
	}
`;

const SurfCardsContainer = styled.section`
	margin-top: 32px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 64px;
	width: 100%;
	margin-inline: auto;
`;

const Illustration = styled.img`
	margin-top: 64px;
	width: 100%;
	max-width: 600px;
	height: auto;
`;
