import React from "react";
import styled from "styled-components";

type Props = {
	name: string;
	value: string;
	id: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checked?: boolean;
	children?: React.ReactNode;
	ariaLabel?: string;
};

/**
 * @interface Props
 * @description A custom radio button component
 * @param name - a name that groups the radio buttons together
 * @param value - the value of the button
 * @param id - the id of the button
 * @param onChange - optional value, the functionality of the radiobutton.
 * @param checked - optional value, if you want the button to be checked by default.
 * @param ariaLabel - an optional aria label
 * @param children - an optional children node
 */

export const RadioButton = React.forwardRef<HTMLInputElement, Props>(
	(props: Props, ref) => {
		const { name, value, id, onChange, checked, children, ariaLabel } =
			props;

		return (
			<LabelElement htmlFor={id}>
				<RadioButtonELement
					aria-label={ariaLabel}
					className="radio-button"
					type="radio"
					value={value}
					id={id}
					name={name}
					onChange={onChange}
					checked={checked}
					ref={ref}
					readOnly
				/>
				{children}
			</LabelElement>
		);
	}
);

const LabelElement = styled.label`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const RadioButtonELement = styled.input.attrs({
	type: "radio",
})`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: inline-block;
	width: 25px;
	height: 25px;
	padding: 3px;
	background-clip: content-box;
	border: 2px solid var(--color-gray);
	background-color: var(--color-whiteish);
	border-radius: 50%;

	:checked {
		background-color: var(--color-gray);
	}

	&:focus {
		outline: 3px solid var(--color-purple);
		outline-offset: 3px;
	}
`;
