import { Link } from "react-router-dom";
import styled from "styled-components";

/**
 * @description A component that displays an error message to the user when the user has no subscription.
 */

export const ErrorWhenUserHasNoSubscription = () => (
	<ErrorContainer>
		<h4>Du har inget aktivt abonnemang just nu! 😞</h4>
		<p>
			Men du har väl koll på att det är supersmidigt att teckna ett nytt
			abonnemang? 🥳{" "}
			<Link className="link" to="/andra-abonnemang">
				Det kan du göra här!
			</Link>
		</p>
		<p>
			Obs! Har du precis tecknat ett abonnemang kan det ta en stund innan
			det dyker upp här.
		</p>
	</ErrorContainer>
);

const ErrorContainer = styled.div`
	width: 100%;
	max-width: 450px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;

	& h4 {
		margin-bottom: 16px;
	}

	a.link {
		color: inherit;
		text-decoration: none;
		font-weight: 700;
		line-height: 1;
		padding: 0;
		transition: all 0.3s;
		border-radius: 8px;

		&:active,
		&:focus {
			outline: 3px solid var(--color-teal);
			outline-offset: 2px;
		}

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
`;
