import { useEffect } from "react";
import styled from "styled-components";
import { User, AtSign, Phone, MapPin } from "react-feather";
import { Button } from "../../components/Button";
import { DottedDivider } from "../../components/Dividers/DottedDivider";
import { Customer } from "../../models/customer";
import { Breakpoints } from "../../styles/Breakpoints";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

interface Props {
	customer: Customer;
	onEdit: () => void;
}

const DisplayPersonalDetails = ({ customer, onEdit }: Props) => {
	useEffect(() => {
		document.title = "Mitt Vobbiz - Mina uppgifter";
	}, []);

	return (
		<Container>
			<InfoText>
				Här är de kontaktuppgifter som finns sparade om dig! 😄 Är det
				något som inte stämmer kan du trycka på knappen för att
				uppdatera din information.
			</InfoText>
			<InformationContainer>
				<Wrapper>
					<User size={24} strokeWidth={2} />
					<Heading>Namn</Heading>
					<p>{`${customer.first_name} ${customer.name}`}</p>
				</Wrapper>
				<DottedDivider />
				<Wrapper>
					<AtSign size={24} strokeWidth={2} />
					<Heading>E-post</Heading>
					<p>{customer.email}</p>
				</Wrapper>
				<DottedDivider />
				<Wrapper>
					<Phone size={24} strokeWidth={2} />
					<Heading>Kontakttelefon</Heading>
					<p>{formatPhoneNumber(customer.contact_phone)}</p>
				</Wrapper>
				<DottedDivider />
				<Wrapper>
					<MapPin size={24} strokeWidth={2} />
					<Heading>Adress</Heading>
					<p>{customer.address}</p>
					<p>{`${customer.postal_code} ${customer.city}`}</p>
				</Wrapper>
			</InformationContainer>
			<Button onClick={onEdit}>Ändra mina uppgifter</Button>
		</Container>
	);
};

const Container = styled.section`
	min-width: 50%;
	max-width: 550px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const InfoText = styled.p`
	margin-inline: auto;
	max-width: 450px;
	text-align: center;
	margin-bottom: 48px;
`;

const Heading = styled.h2`
	font-size: var(--font-size-m);
`;

const InformationContainer = styled.div`
	width: max-content;
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;

	.dot-container {
		align-self: center;
	}

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 32px;

		.dot-container {
			display: none;
		}
	}
`;

const Wrapper = styled.article`
	min-width: 225px;
	border-radius: 16px;
	border: 1px dashed hsla(228, 70%, 79%, 0.3);
	padding: 16px 16px 16px 54px;
	position: relative;

	svg {
		position: absolute;
		left: 16px;
		top: 18px;
		color: var(--color-purple);
		opacity: 0.8;
	}

	@media (min-width: ${Breakpoints.TabletPortrait}) {
	}
`;

export default DisplayPersonalDetails;
