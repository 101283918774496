import styled from "styled-components";

type Props = {
	isChecked: boolean;
	setIsChecked: (isChecked: boolean) => void;
	ariaLabel?: string;
};

/**
 * @description A custom checkbox component
 * @param isChecked A boolean that indicates if the checkbox is checked or not
 * @param setIsChecked A function that sets the checkbox to checked or not
 * @param ariaLabel A string that is used to add an aria-label to the checkbox
 */

export const CheckBox = (props: Props) => {
	const { isChecked, setIsChecked, ariaLabel } = props;

	const onChangeHandler = () => {
		setIsChecked(!isChecked);
	};

	return (
		<Container className="checkbox-container">
			<input
				onChange={onChangeHandler}
				aria-label={ariaLabel}
				className="check-box"
				type="checkbox"
				checked={isChecked}
			/>
			<span className="check-mark" />
		</Container>
	);
};

/* Customize the label (the container) */
const Container = styled.label`
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		top: 6px;
		left: 6px;

		&:focus {
			outline: 3px solid var(--color-purple);
			outline-offset: 10px;
		}
	}

	/* Create a custom checkbox */

	.check-mark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: var(--color-whiteish);
		border: 1px solid var(--color-purple);
		z-index: 1;
	}

	/* On mouse-over, add a grey background color */

	&:hover input ~ .check-mark {
		background-color: var(--color-light-gray);
	}

	/* When the checkbox is checked, add a blue background */

	& input:checked ~ .check-mark {
		background-color: var(--color-purple);
	}

	/* Create the checkmark/indicator (hidden when not checked) */

	.check-mark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */

	& input:checked ~ .check-mark:after {
		display: block;
	}

	/* Style the checkmark/indicator */

	.check-mark:after {
		left: 7px;
		top: 1px;
		width: 10px;
		height: 15px;
		border: solid var(--color-whiteish);
		border-width: 0 3px 3px 0;
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;
