import styled from "styled-components";
import { Button } from "../../components/Button";
import { LoadingSpinner } from "../../components/LoadingSpinner";

interface Props {
	handleCancel: () => void;
	responseMessage: string;
	qrImage: string;
}

const QrCodeView = (props: Props) => {
	const { handleCancel, responseMessage, qrImage } = props;

	return (
		<QrContainer>
			<p>{responseMessage}</p>
			<QrImageWrapper>
				{qrImage ? (
					<img
						src={qrImage}
						alt="QR code for logging in with BankID"
					/>
				) : (
					<LoadingSpinner />
				)}
			</QrImageWrapper>

			<h2>Gör såhär</h2>
			<ol>
				<li>Starta BankID-appen.</li>
				<li>
					Tryck på QR-ikonen i appen och rikta sedan kameran mot
					QR-koden som visas på skärmen.
				</li>
				<li>
					Identifiera dig med din säkerhetskod, Touch ID eller Face ID
					i appen.
				</li>
			</ol>
			<Button onClick={handleCancel}>Avbryt</Button>
		</QrContainer>
	);
};

export default QrCodeView;

const QrContainer = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 8px;

	h2 {
		margin: 1rem 0 0.5rem 0;
		font-size: var(--font-size-base);
	}

	p {
		min-height: 1.6rem;
		width: fit-content;
		margin-inline: auto;
		margin-bottom: 1rem;
		font-weight: bold;
	}

	li {
		margin-bottom: 0.25rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ol {
		margin-bottom: 1rem;
		font-size: var(--font-size-s);
	}
`;

const QrImageWrapper = styled.figure`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 175px;
	height: 175px;
	border: 4px solid rgba(0, 0, 0, 0.05);
	border-radius: 1rem;
	padding: 1rem;
`;
