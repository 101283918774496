import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../hooks/hooks";
import { toggleModal } from "../../store/slices/modalSlice";

type Props = {
	children: React.ReactNode;
};

export const Modal: React.FC<Props> = ({ children }) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	});

	const handleToggle = () => {
		dispatch(toggleModal());
	};

	const handleKeyDown = (e: KeyboardEvent): void => {
		if (e.key === "Escape") {
			dispatch(toggleModal());
		}
	};

	return (
		<AnimatePresence>
			<ModalOverlay
				onClick={handleToggle}
				key="overlay"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<ModalContent
					initial={{ x: "100%" }}
					animate={{ x: 0 }}
					transition={{
						type: "spring",
						stiffness: 140,
					}}
					exit={{ x: "-100%" }}
					key="modal"
				>
					{children}
				</ModalContent>
			</ModalOverlay>
		</AnimatePresence>
	);
};

const ModalOverlay = styled(motion.div)`
	background: hsla(0, 0%, 0%, 0.5);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ModalContent = styled(motion.section)`
	margin: 16px;
`;
