import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
	title: string;
	children: React.ReactNode;
	mixed?: boolean;
	secondTitle?: string;
}

/**
 * @description A gradient card component that flips over when you tab to it
 * @param children - a node of react children to render inside the component - this will display on the back of the card
 * @param title - the title of the card which will display on the front of the card
 * @param secondTitle - a voluntary second title of the card which will display on the front of the card under the title
 * @param mixed A boolean that is added if the gradient card should return a card with seceondary colors instead of primary.
 */

export const FlipCard: React.FC<Props> = props => {
	const { title, children, mixed, secondTitle } = props;
	const [transform, setTransform] = useState(0);
	const cardRef = useRef<HTMLDivElement>(null);
	const [focused, setFocused] = React.useState(false);
	const onFocus = () => setFocused(true);
	const onBlur = () => setFocused(false);

	useEffect(() => {
		if (focused) {
			setTransform(180);
		} else {
			setTransform(0);
		}
	}, [transform, focused]);

	return (
		<CardWrapper
			onClick={() => setTransform(180)}
			ref={cardRef}
			onFocus={onFocus}
			onBlur={onBlur}
		>
			<CardElement transform={transform} tabIndex={0}>
				<CardContentFront mixed={mixed}>
					<h2>{title}</h2>
					<h3>{secondTitle}</h3>
					<DescriptiveText>Klicka för att läsa mer</DescriptiveText>
				</CardContentFront>
				<CardContentBack>{children}</CardContentBack>
			</CardElement>
		</CardWrapper>
	);
};

interface TransformProps {
	transform: number;
}

interface TitleProps {
	mixed?: boolean;
}

const CardWrapper = styled.div`
	width: 100%;
	max-width: 350px;
	height: 700px;
	perspective: 1000px;
	cursor: pointer;
	border-radius: 16px;
`;

const CardElement = styled.div<TransformProps>`
	position: relative;
	transition: transform 0.6s;
	transform-style: preserve-3d;
	width: 100%;
	height: 100%;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	transform: rotateY(${props => props.transform}deg);
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
	letter-spacing: 0.5px;
	line-height: 1;
	border-radius: 16px;

	&:focus,
	&:focus-within {
		outline: 3px solid var(--color-purple);
		outline-offset: 4px;
		transform: rotateY(${props => props.transform}deg);
	}

	& > * {
		border-radius: 16px;
	}

	@media (prefers-reduced-motion) {
		transition: none;
	}
`;

const CardContentFront = styled.div<TitleProps>`
	background: ${props =>
		props.mixed ? "var(--gradient-mixed)" : "var(--gradient-primary)"};
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--color-whiteish);

	& h2 {
		margin-bottom: 16px;
	}

	&:hover {
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	}
`;

const CardContentBack = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: rotateY(180deg);

	&:hover {
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	}
`;

const DescriptiveText = styled.span`
	margin-top: 32px;
	padding: 8px 8px 6px 8px;
	background-color: var(--color-whiteish);
	border-radius: 8px;
	font-size: var(--font-size-s);
	color: var(--color-gray);
	font-weight: 600;
	text-shadow: none;
	text-transform: uppercase;
`;
