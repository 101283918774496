import styled from "styled-components";

type Props = {
	children: React.ReactNode;
	isVisible: boolean;
	isSuccess?: boolean;
};

/**
 * @description An alert message component
 * @param children - a node of react children to render inside the component
 * @param isVisible - a boolean that indicates if the message should be visible in the DOM
 * @param isSuccess - a boolean that indicates if the message should be styled as a success message
 */

export const AlertMessage = (props: Props) => {
	const { children, isVisible, isSuccess } = props;
	const className = `${isVisible ? "" : "hidden"} ${
		isSuccess ? "success" : ""
	}`;

	return (
		<MessageContainer>
			<Message className={className}>{isVisible && children}</Message>
		</MessageContainer>
	);
};

const MessageContainer = styled.div`
	min-height: 5rem;
`;

const Message = styled.p`
	margin: 24px auto;
	text-align: center;
	height: fit-content;
	max-width: 350px;
	width: fit-content;
	padding: 16px;
	font-weight: 700;
	color: var(--color-error);
	border: 4px dotted var(--color-error);
	border-radius: 1.5rem;

	&.success {
		color: var(--color-success);
		border: 4px dotted var(--color-success);
	}

	&.hidden {
		visibility: hidden;
	}
`;
