import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CheckSquare, XCircle } from "react-feather";
import { ValidationResult } from "../../../models/validationResult";

type Props = {
	inputName: string;
	label?: string;
	placeholder?: string;
	value: string;
	setValue?: (value: string) => void;
	setMultipleValue?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	style?: React.CSSProperties;
	isValid: boolean;
	setIsValid: (isValid: boolean) => void;
	validate?: (value: string) => ValidationResult;
};

/**
 * @description A text area component with validation and error handling
 * @param inputName The name of the input, will also be used as id
 * @param label The label to display above the input
 * @param placeholder The placeholder text to display when the input is empty
 * @param value The value of the input (presumably from a state variable)
 * @param setValue A function to set the value of the input
 * @param style An optional style object to apply to the input
 * @param isValid A boolean indicating whether the input is valid, will be set from inside the component
 * @param setIsValid A function to set the validity of the input
 * @param validate An optional function to validate the input, returns a ValidationResult object
 */

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
	(props: Props, ref) => {
		const [isEmpty, setIsEmpty] = useState(true);
		const [isVisited, setIsVisited] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");

		const {
			inputName,
			label,
			placeholder,
			value,
			setValue,
			style,
			isValid,
			setIsValid,
			validate,
			setMultipleValue,
		} = props;

		const onChangeHandler = (
			event: React.ChangeEvent<HTMLTextAreaElement>
		) => {
			if (setValue) {
				setValue(event.target.value);
			} else if (setMultipleValue) {
				setMultipleValue(event);
			}
			if (validate) {
				const validationResult = validate(event.target.value);
				setIsValid(validationResult.isValid);
				setErrorMessage(validationResult.errorMessage);
			}
		};

		const onBlurHandler = () => {
			setIsVisited(true);
		};

		useEffect(() => {
			if (value.length > 0) {
				setIsEmpty(false);
			} else {
				setIsEmpty(true);
				setErrorMessage("");
			}
		}, [value]);

		const inputClassName = `
	${isEmpty ? "empty" : ""}
	${isVisited && errorMessage ? "error" : ""}`;

		return (
			<TextAreaContainer className="textarea-container" style={style}>
				<Label htmlFor={inputName}>{label}</Label>
				<TextAreaElement
					name={inputName}
					id={inputName}
					value={value}
					onChange={onChangeHandler}
					onBlur={onBlurHandler}
					className={inputClassName}
					placeholder={placeholder}
					ref={ref}
				/>
				{!isEmpty && isValid && isVisited && (
					<CheckSquare
						size={16}
						strokeWidth={1}
						className="validIcon"
					/>
				)}
				<AlertMessageContainer>
					{errorMessage && isVisited && (
						<AlertMessage className="error">
							<XCircle size={14} /> {errorMessage}
						</AlertMessage>
					)}
				</AlertMessageContainer>
			</TextAreaContainer>
		);
	}
);

const TextAreaContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;

	.validIcon {
		position: absolute;
		right: 1rem;
		top: 35px;
		color: var(--color-gray);
	}
`;

const TextAreaElement = styled.textarea`
	height: 200px;
	border-radius: 0.25rem;
	padding: 1rem 2.5rem 1rem 1rem;
	font: inherit;
	border: 1px solid var(--color-light-gray);
	color: var(--color-gray);
	background: transparent;
	transition: all 0.2s;
	outline: none;
	overflow: hidden;
	resize: none;

	&:hover,
	&:focus {
		border: 1px solid var(--color-light-gray);
		color: var(--color-blackish);
		background-color: #f9f9f9;
	}

	&:focus {
		outline: 3px solid var(--color-purple);
		outline-offset: 3px;
	}

	&.error {
		border-color: var(--color-error);
	}

	&.warning {
		border-color: var(--color-warning);
	}

	::placeholder {
		color: var(--color-medium-gray);
	}
`;

const Label = styled.label`
	font-size: 0.9rem;
	color: var(--color-gray);
`;

const AlertMessageContainer = styled.div`
	height: 1rem;
`;

const AlertMessage = styled.span`
	font-size: 0.8rem;
	display: flex;
	align-items: center;
	margin-top: 0.25rem;
	margin-left: 0.25rem;
	line-height: 1;

	&.error {
		color: var(--color-error);
	}

	& svg {
		margin-right: 0.25rem;
		transform: translateY(-1px);
	}
`;
