import QRCode from "qrcode";

export const generateQrImage = (qrCodeString: string): string => {
	let image = "";

	QRCode.toDataURL(
		qrCodeString,
		{
			width: 175,
			margin: 0,
		},
		(err, url) => {
			if (err) {
				throw new Error(err.message);
			}
			image = url;
		}
	);

	return image;
};
