import React from "react";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import { Header } from "../Headers/Header";
import { Menu } from "../Menu";
import useWindowSize from "../../hooks/useWindowSize";
import { Breakpoints } from "../../styles/Breakpoints";
import { Footer } from "../Footer";

type Props = {
	secondary?: boolean;
	children?: React.ReactNode;
	hideMenu?: boolean;
	hideFooter?: boolean;
};

/**
 * @description A RootPageWrapper is a wrapper for the entire page. It contains the Header and optionally the Menu.
 * @param children - a node of react children to render inside the component
 * @param secondary - a boolean that is added if the header is to be rendered  with secondary colors instead of primary
 * @param hideMenu - a choice for user to hide the side menu
 * @param hideFooter - a choice for user to hide the footer
 */

export const PageWrapper: React.FC<Props> = ({
	children,
	secondary,
	hideMenu = false,
	hideFooter = false,
}) => {
	const { width } = useWindowSize();
	const breakpoint = Number(Breakpoints.TabletLandscape.split("rem")[0]) * 16;

	return (
		<ContentWrapper>
			<Header hideMenu={hideMenu} secondary={secondary} />
			<MainWrapper>
				{width > breakpoint && !hideMenu && (
					<AnimatePresence>
						<Menu key="menu" />
					</AnimatePresence>
				)}
				<Main>{children}</Main>
			</MainWrapper>
			{!hideFooter && <Footer secondary={secondary} />}
		</ContentWrapper>
	);
};

const ContentWrapper = styled.div`
	min-height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const MainWrapper = styled.div`
	flex: 1;
	min-height: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-inline: auto;
	width: 100%;
	max-width: var(--max-width);
	padding: 16px;

	@media (min-width: ${Breakpoints.TabletLandscape}) {
		padding: 32px;
		flex-direction: row;
		gap: 32px;
	}
`;

const Main = styled.main`
	width: 100%;
	height: 100%;
	margin-inline: auto;
`;
