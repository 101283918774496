import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../styles/Breakpoints";
import { CloseButton } from "../Modal/CloseButton";

const PrivacyPolicyModal: React.FC = () => (
	<Container
		data-testid="privacy-policy-modal"
		aria-modal="true"
		aria-label="privacy_policy_dialog"
		role="dialog"
		onClick={e => e.stopPropagation()}
	>
		<CloseButton />
		<h1>Integritetspolicy</h1>
		<Content>
			<h2>Hantering av personuppgifter</h2>
			<p>
				Personuppgifter är information som kan knytas till en
				identifierad eller identifierbar person som exempelvis namn,
				adress, personnummer, e-postadress och registreringsnummer på
				fordon. Dessa uppgifter kan vara nödvändiga för oss att behandla
				för att vi ska kunna betjäna dig som kund.
			</p>
			<p>
				Vi behandlar personuppgifter som är nödvändiga för att leverera
				våra IT-tjänster. Vobbiz behandlar personuppgifter i enlighet
				med gällande personuppgiftslagstiftning. Din integritet är
				viktig för oss och vi har ett stort fokus på att dina
				personuppgifter hanteras i enlighet med principerna om
				sekretess, integritet, tillgänglighet och hållbarhet.
			</p>
			<p>
				Vi är medvetna om att det inte är lämpligt att skicka känsliga
				personuppgifter eller personnummer via e-post utan att
				innehållet krypteras.
			</p>
			<p>
				Om en säkerhetsöverträdelse som mycket sannolikt kan utgöra en
				allvarlig risk för våra kunders rättigheter, rapporterar vi
				detta till dig i enlighet med personuppgiftsregelverket.
			</p>

			<h2>Varför och hur behandlar vi personuppgifter?</h2>
			<p>
				Syftet med vår behandling av dina personuppgifter är att
				identifiera dig som kund och uppfylla det avtal ni har med oss.
				Vår behandling av personuppgifter hanteras av våra duktiga och
				kompetenta medarbetare. Vi använder professionella system med
				tillräcklig säkerhet för att lagra och behandla dina
				personuppgifter.
			</p>
			<p>
				Det är endast ett urval av personer som hanterar och har
				tillgång till eventuella känsliga uppgifter i våra system.
				Fysiska dokument med känsliga personuppgifter förvaras inlåsta
				på säker plats.
			</p>
			<p>
				Alla anställda på Vobbiz omfattas av tystnadsplikt.
				Arbetstagaren är pålagd en tystnadsplikt både mot externa
				personer och mot verksamheter, men även internt mellan kollegor.
				Tystnadsplikten upphör inte när anställningen avslutas.
			</p>

			<h2>Vilka personuppgifter behandlar Vobbiz?</h2>
			<p>
				Uppgifter som behandlas hos Vobbiz kan kategoriseras enligt
				följande:
			</p>
			<ul>
				<li>
					Administrativa uppgifter såsom namn, adress, telefonnummer,
					e-postadress och personnummer
				</li>
				<li>
					Uppgifter om supportärenden som är nödvändiga för att kunna
					utföra våra tjänster
				</li>
			</ul>

			<h2>Vilka behandlingsgrunder använder sig Vobbiz av?</h2>
			<p>
				Vissa personuppgifter är nödvändiga att behandla för att
				uppfylla ett avtal med dig. Detta gäller bland annat namn och
				kontaktuppgifter.
			</p>
			<p>
				Om ett avtal mellan dig och oss kräver att vi behandlar
				ytterligare information eller andra känsliga personuppgifter om
				dig, kommer vi före behandlingen att inhämta ditt samtycke. Du
				kan när som helst dra tillbaka ditt samtycke, och behandlingen
				av dina personuppgifter upphör. Behandlingen av dina
				personuppgifter som ägde rum när samtycket var aktivt kommer
				inte att påverkas av återkallandet. Vi kommer emellertid att
				radera de aktuella personuppgifterna om vi inte har några andra
				lagliga grunder för att fortsätta lagringen.
			</p>
			<p>
				Om vi inte får tillåtelse till att behandla relevanta och
				nödvändiga personuppgifter gör vi dig uppmärksam på att vi
				eventuellt inte kommer att ha möjlighet att utreda ditt
				supportärende.
			</p>

			<h2>Vem levererar vi personuppgifter till?</h2>
			<p>
				Vi kan lämna ut personuppgifter till tredje part om
				personuppgiftsregelverket tillåter detta. I vissa fall är det
				nödvändigt för oss att lämna ut personuppgifter om dig för att
				kunna uppfylla vårt avtal med dig som användare.
			</p>
			<p>
				Om det är nödvändigt för oss att använda ett
				personuppgiftsbiträde, behandlar personuppgiftsbiträdet endast
				personuppgifter efter detaljerade instruktioner från Vobbiz.
				Detta för att säkerställa dina rättigheter och skydda din
				integritet. Eventuella tredje parter som mottar personuppgifter
				från oss omfattas av tystnadsplikt enligt avtal.
			</p>

			<h2>Hur länge lagrar vi personuppgifter om dig?</h2>
			<p>
				Vi lagrar inte personuppgifter längre än vad som är nödvändigt
				för att uppfylla syftet med behandlingen. Om du har en
				kundrelation med oss lagras personuppgifter om dig så länge
				denna är aktiv. Den personliga informationen kommer sedan att
				raderas.
			</p>

			<h2>Din rätt till insyn, rättning, radering och dataöverföring</h2>
			<h3>Rätt att kräva insyn:</h3>
			<p>
				Du har rätt att få insyn över de uppgifter vi behandlar om dig.
				Det innebär att du har rätt att få veta vilket syfte och
				behandlingsgrund vi använder oss av, vilka uppgifter om dig som
				vi behandlar, vilka mottagare eller kategorier av mottagare till
				vilka personuppgifterna vidareförmedlats till, vilken tid
				personuppgifterna lagras samt var uppgifterna inhämtats ifrån.
			</p>
			<h3>Rätt att kräva rättning och radering:</h3>
			<p>
				Om du tror att Vobbiz har registrerat uppgifter om dig som är
				oriktiga eller ofullständiga, har du rätt att begära rättelse av
				de aktuella personuppgifterna. Du kan kräva att vi tar bort
				personuppgifter om dig om uppgifterna inte längre är nödvändiga
				för att uppfylla syftet med behandlingen; om du drar tillbaka
				ditt samtycke om samtycke har använts som grund för behandling;
				om du motsätter dig behandlingen och det inte finns några andra
				lagliga grunder för att fortsätta behandlingen; eller om
				uppgifterna har behandlats olagligt. Du har också rätt att
				invända mot behandlingen av dina personuppgifter om du anser att
				det är något vi inte gör rätt i förhållande till dina
				personuppgifter.
			</p>
			<h3>Rätt till dataöverföring:</h3>
			<p>
				Du har rätt att motta de personuppgifter vi har lagrat om dig på
				ett strukturerat sätt och i ett maskinläsbart format. Du har
				också rätt att kräva att vi överför uppgifter som vi har
				mottagit från dig till en annan personuppgiftsansvarig om det är
				tekniskt möjligt och behandlingen av personuppgifterna är
				baserat på samtycke eller avtal.
			</p>

			<h2>Kontaktinformation</h2>
			<p>
				Om du har frågor om hur vi behandlar personuppgifter eller vill
				infria dina rättigheter enligt denna policy ber vi dig att
				vänligen kontakta vår personuppgiftsansvarig via e-post:
			</p>
			<p>
				DPO är pålagd tystnadsplikt och är skyldig att hindra andra från
				att få tillgång till eller kunskap om din personliga information
				om inte ditt samtycke har erhållits. Sekretess gäller också
				efter avslutat arbete.
			</p>

			<h2>Personuppgiftsansvarig</h2>
			<p>
				Vobbiz i rollen som personuppgiftsansvarig, för översikt över de
				processer, affärsområden och system som behandlar
				personuppgifter, samt genomför interna kontroller och
				riskbedömningar för att säkerställa efterlevnad av
				personuppgiftsregelverket.
			</p>
			<p>Personuppgiftsansvarig kan kontaktas per post:</p>
			<address>
				Vobbiz AB
				<br />
				Gamla Almedalsvägen 4
				<br />
				412 63 Göteborg
				<br />
				Sverige
			</address>

			<h2>Var klagar jag på behandlingen?</h2>
			<p>
				Datainspektionens jobb är att kontrollera att
				personuppgiftsregelverket följs. Om du upplever något du anser
				är ett brott mot regelverket, kan du skicka in en skriftlig
				förfrågan till Datainspektionen. Postadress: Datainspektionen,
				Box 8114, 104 20 Stockholm.
			</p>
		</Content>
	</Container>
);

const Container = styled.section`
	width: 1000px;
	max-width: 90vw;
	background-color: var(--color-whiteish);
	padding: 2rem;
	border-radius: 0.5rem;
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
	margin-inline: 0.5rem;
	position: relative;
	height: 100%;
	max-height: 80vh;
	overflow-y: auto;

	& h1 {
		font-size: var(--font-size-xl);
	}

	& > button {
		position: fixed;
		top: 1rem;
		right: 1rem;
		color: var(--color-gray);
		background-color: var(--color-whiteish);

		:hover,
		:focus {
			&::after {
				border: 2px solid var(--color-teal);
			}
		}
	}
`;

const Content = styled.div`
	& h2 {
		margin-block: 2rem 1rem;
		font-size: var(--font-size-l);
	}

	& h3 {
		margin-bottom: 0.5rem;
	}

	& p {
		margin-bottom: 1rem;
	}

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background: var(--color-whiteish);
	}

	::-webkit-scrollbar-thumb {
		background: var(--color-gray);
		border-radius: 5px;
	}

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		max-height: 92%;
	}
`;

export default PrivacyPolicyModal;
