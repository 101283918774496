import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { StylesReset } from "./styles/StylesReset";
import { CssVariables } from "./styles/CssVariables";
import { GlobalStyles } from "./styles/GlobalStyles";
import { store } from "./store/store";
import App from "./App";

const container = document.getElementById("root");

const root = createRoot(container as Element);
root.render(
	<Provider store={store}>
		<StylesReset />
		<CssVariables />
		<GlobalStyles />
		<App />
	</Provider>
);
