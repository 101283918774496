/* eslint-disable camelcase */
import styled from "styled-components";
import { Contract } from "../../models/contract";
import { GradientCard } from "../GradientCard";
import { Subscription } from "../../models/subscription";
import { DottedDivider } from "../Dividers/DottedDivider";
import { FlipCard } from "../FlipCard";
import { AnchorText } from "../AnchorText";
import { ErrorWhenUserHasNoSubscription } from "../ErrorWhenUserHasNoSubscription/ErrorWhenUserHasNoSubscription";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

interface Props {
	activeSubscriptions: Subscription[] | null | undefined;
}

/**
 * @description This component is used to display information about the customer's active subscriptions as cards
 * @param activeSubscriptions an array of active subscriptions for a user
 */

export const CustomerProductCard: React.FC<Props> = props => {
	const { activeSubscriptions } = props;

	return (
		<>
			<CardWrapper>
				{activeSubscriptions &&
					activeSubscriptions.length > 0 &&
					activeSubscriptions.map((subscription: Subscription) => (
						<FlipCard
							key={subscription.id}
							mixed
							title={formatPhoneNumber(
								subscription.phone_numbers[0].phone_number
							)}
							secondTitle={subscription.contracts[0].product_name}
						>
							<GradientCard
								mixed
								title={
									subscription.contracts[0]
										? subscription.contracts[0].product_name
										: "Borttagen produkt"
								}
							>
								<Content>
									<h4>Telefonnummer:</h4>
									<ul>
										<li>
											{formatPhoneNumber(
												subscription.phone_numbers[0]
													.phone_number
											)}
										</li>
									</ul>

									<DottedDivider />

									<h4>Detaljer:</h4>
									{subscription.contracts.map(
										({
											id,
											contract_number,
											product_name,
											unit_price,
											period_start,
										}: Contract) => (
											<ul key={id}>
												<li>
													<strong>
														Kontraktnummer:
													</strong>{" "}
													{contract_number}
												</li>
												<li>
													<strong>Produkt:</strong>{" "}
													{product_name}
												</li>
												<li>
													<strong>Pris:</strong>{" "}
													{unit_price * 1.25} kr/månad
												</li>
												<li>
													<strong>
														Avtalet är tecknat:
													</strong>{" "}
													{period_start}
												</li>
											</ul>
										)
									)}

									<DottedDivider />

									<h4>Detta ingår: </h4>
									<ul className="with-dots">
										<li>Månadsbetalning</li>
										<li>Fria SMS och samtal</li>
										<li>Ingen bindningstid</li>
									</ul>
								</Content>
							</GradientCard>
						</FlipCard>
					))}
			</CardWrapper>

			{activeSubscriptions && activeSubscriptions.length > 0 && (
				<>
					<DottedDivider />
					<AnchorText
						text="Vill du byta abonnemang?"
						desiredRedirectPath="/andra-abonnemang"
					/>
				</>
			)}

			{(!activeSubscriptions || activeSubscriptions.length === 0) && (
				<ErrorWhenUserHasNoSubscription />
			)}
		</>
	);
};

const CardWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 32px;
	list-style-type: none;
	margin-bottom: 1rem;
	justify-content: center;

	.gradient-card {
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		ul {
			width: 100%;
			line-height: 1.5;
			list-style-type: none;
			padding: 8px;
		}

		.with-dots {
			list-style-type: circle;

			li {
				margin-left: 1rem;
			}
		}

		.dot-container {
			max-width: 4rem;
			position: relative;
			left: 40%;
		}
	}
`;

const Content = styled.div`
	padding: 32px;
`;
