import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Star } from "react-feather";
import { CircledIcon } from "../../../components/CircledIcon";
import { Product } from "../../../models/product";
import ToggleModalLink from "../../../components/Modal/ToggleModalLink";
import { Modal } from "../../../models/modal";
import { SelectInput } from "../../../components/FormComponents/SelectInput";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { getProductSelectedByUser } from "../../../utils/determineUserSelection";

const resolveSurf = (name: string) => name.split(" ")[2];

interface Props {
	selectedSubscription: Product | undefined;
	products: Product[];
	isError: boolean;
	setSelectedSubscription: (subscription: Product) => void;
	setButtonIsDisabled: (buttonIsDisabled: boolean) => void;
}

const Step1 = (props: Props) => {
	const {
		selectedSubscription,
		products,
		setSelectedSubscription,
		setButtonIsDisabled,
		isError,
	} = props;

	const [selectedValue, setSelectedValue] = useState("");

	const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedValue(event.target.value);

		const newSubscriptionSelectedByUser = getProductSelectedByUser(
			event,
			products
		);

		if (newSubscriptionSelectedByUser) {
			setSelectedSubscription(newSubscriptionSelectedByUser);
		}
	};

	useEffect(() => {
		if (selectedSubscription) {
			setButtonIsDisabled(false);
			setSelectedValue(selectedSubscription.name);
		}
	}, [selectedSubscription, setButtonIsDisabled]);

	return (
		<ArticleWrapper>
			<CircledIcon iconName="star">
				<Star />
			</CircledIcon>
			<h2>1. Bekräfta abonnemang</h2>
			<ToggleModalLink modal={Modal.SurfGuide}>
				Öppna Surfguiden!
			</ToggleModalLink>
			{products ? (
				<form action="">
					<SelectInput
						ariaLabel="Välj abonnemang"
						onChangeHandler={onChangeHandler}
						value={selectedValue}
						inputName="Välj abonnemang"
					>
						{products.map(product => (
							<option key={product.id} value={product.name}>
								{product.name}
							</option>
						))}
					</SelectInput>
				</form>
			) : (
				<h3>Kunde inte hämta data om tillgängliga abonnemang.</h3>
			)}

			<SubscriptionsWrapper>
				{selectedSubscription && (
					<div>
						<h3>
							{selectedSubscription.name},{" "}
							{selectedSubscription.unit_price * 1.25} kr/mån
						</h3>
						<ul>
							<li>
								{resolveSurf(selectedSubscription.name)} GB surf
							</li>
							<li>Fria SMS och samtal</li>
							<li>Ingen bindningstid</li>
							<li>Månadsbetalning</li>
						</ul>
						<p>{selectedSubscription.info}</p>
					</div>
				)}
				{!isError && !selectedSubscription && <LoadingSpinner />}
				{isError && <h3>Något gick fel, testa att uppdatera sidan.</h3>}
			</SubscriptionsWrapper>
		</ArticleWrapper>
	);
};

const ArticleWrapper = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;

	h2,
	h3 {
		margin: 1rem 0;
		text-align: center;
	}
`;

const SubscriptionsWrapper = styled.section`
	display: flex;
	justify-content: center;
	min-width: 350px;
	max-width: 350px;
	min-height: 250px;
	max-height: 250px;

	ul {
		list-style-type: none;
		margin-bottom: 1rem;
	}
`;

export default Step1;
