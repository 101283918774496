import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FETCHURL } from "../../constants/constants";
import { Surf } from "../../models/surf";
import { getTokenFromSessionStorage } from "../../services/sessionStorageServices";

export const surfSlice = createApi({
	reducerPath: "surf",
	baseQuery: fetchBaseQuery({
		baseUrl: `${FETCHURL}/api/ocs`,
		prepareHeaders: headers => {
			const token = getTokenFromSessionStorage();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: builder => ({
		getSurf: builder.query<Surf[], void>({
			query: () => "/mobileData",
		}),
	}),
});

export const { useGetSurfQuery } = surfSlice;
