import { ValidationResult } from "../models/validationResult";

export function validatePhoneNumber(phoneNumber: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};

	if (phoneNumber.length !== 10) {
		validationResult.isValid = false;
		validationResult.errorMessage =
			"Telefonnummer måste bestå av 10 siffror";
	} else if (phoneNumber.match(/[^0-9]/g)) {
		validationResult.isValid = false;
		validationResult.errorMessage =
			"Telefonnummer måste bestå av 10 siffror";
	}

	return validationResult;
}

export function validateIdentityNumber(
	identityNumber: string
): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};

	const isValid =
		/^(19|20).*(\d{2}.*(01|02|03|04|05|06|07|08|09|10|11|12).*(01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31)[-—]+.*\d{4})$/.test(
			identityNumber
		);

	const isAlsoValid =
		/^(19|20).*(\d{2}.*(01|02|03|04|05|06|07|08|09|10|11|12).*(01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31)\d{4})$/.test(
			identityNumber
		);

	if (identityNumber.length !== 13 && identityNumber.length !== 12) {
		validationResult.isValid = false;
		validationResult.errorMessage =
			"Personnummer ska vara i format YYYYMMDD-XXXX eller YYYYMMDDXXXX";
	} else if (!isValid && !isAlsoValid) {
		validationResult.errorMessage =
			"Personnummer ska vara i format YYYYMMDD-XXXX eller YYYYMMDDXXXX";
		validationResult.isValid = false;
	}

	return validationResult;
}

export function validateEmail(email: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};

	const isValid =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email
		);

	if (email.length === 0) {
		validationResult.errorMessage = "Vänligen ange mailadress.";
		validationResult.isValid = false;
	} else if (!isValid) {
		validationResult.errorMessage = "Vänligen ange korrekt mailadress.";
		validationResult.isValid = false;
	}

	return validationResult;
}

export function validateFirstName(firstName: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};

	const isValid = /^[a-zA-ZåäöüßÅÄÖÜ\s]+$/g.test(firstName);

	if (firstName.length === 0) {
		validationResult.errorMessage = "Vänligen ange förnamn.";
		validationResult.isValid = false;
	} else if (!isValid) {
		validationResult.errorMessage = "Namnet får endast bestå av bokstäver.";
		validationResult.isValid = false;
	}

	return validationResult;
}

export function validateName(name: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};

	const isValid = /^[a-zA-ZåäöüßÅÄÖÜ\s]+$/g.test(name);

	if (name.length === 0) {
		validationResult.errorMessage = "Vänligen ange ett namn.";
		validationResult.isValid = false;
	} else if (!isValid) {
		validationResult.errorMessage = "Namnet får endast bestå av bokstäver.";
		validationResult.isValid = false;
	}

	return validationResult;
}

export function validateLastName(lastName: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};

	const isValid = /^[a-zA-ZåäöüßÅÄÖÜ\s]+$/g.test(lastName);

	if (lastName.length === 0) {
		validationResult.errorMessage = "Vänligen ange efternamn.";
		validationResult.isValid = false;
	} else if (!isValid) {
		validationResult.errorMessage = "Namnet får endast bestå av bokstäver.";
		validationResult.isValid = false;
	}
	return validationResult;
}

export function validateStreet(street: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};

	const isValid = /^[0-9a-zA-ZåäöüßÅÄÖÜ\s]+$/g.test(street);

	if (street.length === 0) {
		validationResult.errorMessage = "Vänligen ange adress.";
		validationResult.isValid = false;
	} else if (street.length < 3) {
		validationResult.errorMessage = "Vänligen ange korrekt adress.";
		validationResult.isValid = false;
	} else if (!isValid) {
		validationResult.errorMessage =
			"Adressen får endast bestå av bokstäver och siffror.";
		validationResult.isValid = false;
	}

	return validationResult;
}

export function validateZipcode(zipCode: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};

	const formattedZipCode = zipCode.replace(/ /g, "");

	const isValid = /[0-9]+/.test(formattedZipCode);

	if (formattedZipCode.length !== 5) {
		validationResult.errorMessage =
			"Vänligen ange postnummer bestående av 5 siffror.";
		validationResult.isValid = false;
	} else if (!isValid) {
		validationResult.errorMessage =
			"Postnumret får endast bestå av siffror.";
		validationResult.isValid = false;
	}
	return validationResult;
}

export function validateCity(city: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};
	const isValid = /^[a-zA-ZåäöüßÅÄÖÜ\s]+$/g.test(city);

	if (city.length === 0) {
		validationResult.errorMessage = "Vänligen ange ort.";
		validationResult.isValid = false;
	} else if (!isValid) {
		validationResult.errorMessage = "Orten måste bestå av bokstäver.";
		validationResult.isValid = false;
	}

	return validationResult;
}

export function validateMessage(message: string): ValidationResult {
	const validationResult: ValidationResult = {
		isValid: true,
		errorMessage: "",
		warningMessage: "",
	};
	const isValid = /^[0-9a-z"'@&/=+-;:!?.,()A-ZåäöüßÅÄÖÜ\s]+$/g.test(message);

	if (message.length === 0) {
		validationResult.errorMessage = "Vänligen ange ett meddelande.";
		validationResult.isValid = false;
	} else if (!isValid) {
		validationResult.errorMessage = `Vissa specialtecken är inte tillåtna. Du kan endast använda bokstäver, siffror och "'@&/=+-;:!?.,()`;
		validationResult.isValid = false;
	}
	return validationResult;
}
