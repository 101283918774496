/**
 * Use this to toggle the aria-hidden on the root of the document when a dialog is displayed.
 * @param currentToggleState The state variable that keeps track whether the modal is active or not.
 * @description This code was inspired by the UI component Reach UI. https://github.com/reach/reach-ui
 */
export function toggleAriaCurtain(currentToggleState: boolean): void {
	const rootNode = document.querySelector("#root");

	if (!currentToggleState) {
		rootNode?.removeAttribute("aria-hidden");
		return;
	}

	// Handles edge cases for unexpected initial attributes in the elements inspector in the browser.
	const currentAttr = rootNode?.getAttribute("aria-hidden");
	if (
		currentAttr === "false" ||
		currentAttr === "" ||
		currentAttr !== "true"
	) {
		rootNode?.setAttribute("aria-hidden", "true");
		return;
	}

	if (currentAttr === null) {
		rootNode?.setAttribute("aria-hidden", "true");
	} else if (currentToggleState) {
		rootNode?.setAttribute("aria-hidden", "false");
	} else if (!currentToggleState) {
		rootNode?.removeAttribute("aria-hidden");
	}
}
