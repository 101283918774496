import styled from "styled-components";
import { Button } from "../../components/Button";
import { LoadingSpinner } from "../../components/LoadingSpinner";

interface Props {
	handleCancel: () => void;
	responseMessage: string;
	isLoading: boolean;
}

const SameDeviceView = (props: Props) => {
	const { handleCancel, responseMessage, isLoading } = props;

	return (
		<Container>
			<p>{responseMessage}</p>
			{isLoading && <LoadingSpinner />}
			<Button onClick={handleCancel}>Avbryt</Button>
		</Container>
	);
};

export default SameDeviceView;

const Container = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 8px;

	p {
		min-height: 1.6rem;
		width: fit-content;
		margin-inline: auto;
		font-weight: bold;
	}

	& .loading-spinner {
		margin-block: 1rem;
	}
`;
