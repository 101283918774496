import { HelpCircle, Package, Phone } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Breakpoints } from "../../styles/Breakpoints";
import { Button } from "../Button";
import MenuToggleBtn from "./MenuToggleBtn";

interface Props {
	isOpen?: boolean;
	toggleMenu: () => void;
	handleLogout: () => void;
}

const Links = (props: Props) => {
	const { isOpen, toggleMenu, handleLogout } = props;
	const location = useLocation();

	return (
		<Nav>
			<MenuToggleBtn isOpen={isOpen} toggleMenu={toggleMenu} />
			<Heading>
				<Phone size={18} />
				<h2>Abonnemang</h2>
			</Heading>

			<List>
				<li>
					<Link
						to="/oversikt"
						className={
							location.pathname === "/oversikt" ? "active" : ""
						}
					>
						Översikt
					</Link>
				</li>
				<li>
					<Link
						className={
							location.pathname === "/mina-abonnemang"
								? "active"
								: ""
						}
						to="/mina-abonnemang"
					>
						Mina abonnemang
					</Link>
				</li>
				<li>
					<Link to="/surf">Surf</Link>
				</li>
				<li>
					<Link
						to="/rostbrevlada"
						className={
							location.pathname === "/rostbrevlada"
								? "active"
								: ""
						}
					>
						Röstbrevlåda
					</Link>
				</li>
				<li>
					<Link
						to="/pin-puk"
						className={
							location.pathname === "/pin-puk" ? "active" : ""
						}
					>
						PIN/PUK-kod
					</Link>
				</li>
			</List>

			<Heading>
				<Package size={18} />
				<h2>Tjänster</h2>
			</Heading>

			<List>
				<li>
					<Link
						to="/andra-abonnemang"
						className={
							location.pathname === "/andra-abonnemang"
								? "active"
								: ""
						}
					>
						Ändra abonnemang
					</Link>
				</li>
				<li>
					<Link
						to="/mina-uppgifter"
						className={
							location.pathname === "/mina-uppgifter"
								? "active"
								: ""
						}
					>
						Mina uppgifter
					</Link>
				</li>
				<li>
					<Link
						to="/betalningar"
						className={
							location.pathname === "/betalningar" ? "active" : ""
						}
					>
						Betalningar
					</Link>
				</li>
				<li>
					<Link
						to="/nytt-sim"
						className={
							location.pathname === "/nytt-sim" ? "active" : ""
						}
					>
						Nytt SIM-kort
					</Link>
				</li>
			</List>

			<Heading>
				<HelpCircle size={18} />
				<h2>Support</h2>
			</Heading>
			<List>
				<li>
					<Link
						to="/kontakta-oss"
						className={
							location.pathname === "/kontakta-oss"
								? "active"
								: ""
						}
					>
						Kontakta oss
					</Link>
				</li>
			</List>
			<Button className="logout-btn" secondary onClick={handleLogout}>
				Logga ut
			</Button>
		</Nav>
	);
};

const Nav = styled.nav`
	background: var(--color-whiteish);
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	max-width: 400px;
	padding: 8px;

	@media screen and (min-width: ${Breakpoints.TabletLandscape}) {
		transform: none;
		width: fit-content;
		display: block;
		position: relative;
		min-width: 275px;
		height: auto;
	}

	& .toggle-btn {
		position: absolute;
		right: 1rem;
		top: 1rem;

		@media screen and (min-width: ${Breakpoints.TabletLandscape}) {
			display: none;
		}
	}

	hr {
		border-top: 1px solid var(--color-light-teal);
	}

	& .logout-btn {
		margin-top: 1rem;
		width: 100%;
	}
`;

const Heading = styled.div`
	color: var(--color-whiteish);
	background: var(--color-teal-dark);
	display: flex;
	align-items: center;
	padding: 8px;
	border-radius: 8px;
	width: 100%;

	& h2 {
		margin-left: 8px;
		font-size: var(--font-size-s);
		text-transform: uppercase;
		letter-spacing: 1px;
		text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
	}
`;

const List = styled.ol`
	list-style-type: none;
	width: 100%;
	padding: 0;
	margin: 0 0 32px;

	li {
		padding: 10px 0;
		width: 100%;
		border-top: 1px solid rgba(0, 0, 0, 0.08);

		&:first-of-type {
			border-top: none;
		}

		a {
			text-decoration: none;
			color: inherit;
			padding: 0 16px;
			transition: all 0.2s;
			border-radius: 8px;

			&:hover,
			&:focus,
			&.active {
				color: hsla(190, 35%, 38%, 1);
				font-weight: 700;
			}

			&:focus,
			&:active {
				outline: 2px solid var(--color-teal);
			}

			&.active {
				pointer-events: none;
				cursor: default;
			}
		}
	}
`;

export default Links;
