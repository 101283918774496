import { FETCHURL } from "../constants/constants";
import { LoginResponse } from "../models/loginResponse";

export const loginService = async (identityNumber: string) => {
	try {
		const response = await fetch(`${FETCHURL}/api/auth/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				identityNumber,
			}),
		});

		const data: LoginResponse = await response.json();

		return data;
	} catch (_) {
		const err: LoginResponse = {
			error: "Something went wrong while logging in. Try again later!",
		};
		return err;
	}
};

export const verifyToken = async (
	token: string
): Promise<string | undefined> => {
	const response = await fetch(`${FETCHURL}/api/auth/verify`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({
			token,
		}),
	});

	const data: LoginResponse = await response.json();

	if (data.token) {
		return data.token;
	}

	return undefined;
};
