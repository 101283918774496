import React from "react";
import styled from "styled-components";

type Props = {
	text: string;
	desiredRedirectPath: string;
};

/**
 * @interface Props
 * @description An anchor tag component to redirect user to a desired path when they click on it.
 * @param text The text to display to the user
 * @param desiredRedirectPath The URI to redirect to
 */

export const AnchorText: React.FC<Props> = ({ text, desiredRedirectPath }) => (
	<Anchor href={desiredRedirectPath}>{text}</Anchor>
);

const Anchor = styled.a`
	color: hsla(190, 35%, 36%, 1);
	font-size: var(--font-size-base);
	font-weight: 700;
	text-decoration: none;
	border-radius: 4px;

	&:focus,
	&:active {
		outline: 2px solid var(--color-teal);
		outline-offset: 4px;
	}

	:hover {
		text-decoration: underline;
	}
`;
