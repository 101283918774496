/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Modal } from "../../models/modal";
import { RootState } from "../store";

const initialState = {
	isActive: false,
	activeModal: Modal.CustomerSupport,
	scrollPosition: 0,
};

const modalSlice = createSlice({
	name: "modalIsOpen",
	initialState,
	reducers: {
		toggleModal: state => {
			if (!state.isActive) {
				state.scrollPosition = window.scrollY;
			}
			state.isActive = !state.isActive;
		},
		setActiveModal: (state, action: { payload: Modal }) => {
			state.activeModal = action.payload;
		},
	},
});

export const { toggleModal, setActiveModal } = modalSlice.actions;

export const selectModalState = (state: RootState) => state.modal.isActive;

export const selectActiveModal = (state: RootState) => state.modal.activeModal;

export const selectScrollPosition = (state: RootState) =>
	state.modal.scrollPosition;

export default modalSlice.reducer;
