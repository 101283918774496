import {
	BaseQueryFn,
	FetchArgs,
	createApi,
	fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { FETCHURL } from "../../constants/constants";
import { Product } from "../../models/product";
import { getTokenFromSessionStorage } from "../../services/sessionStorageServices";

interface CustomError {
	data: {
		message: string;
		stack: string;
	};
	status: number;
}

export const productSlice = createApi({
	reducerPath: "product",
	baseQuery: fetchBaseQuery({
		baseUrl: `${FETCHURL}/api/products`,
		prepareHeaders: headers => {
			const token = getTokenFromSessionStorage();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}) as BaseQueryFn<string | FetchArgs, unknown, CustomError>,
	endpoints: builder => ({
		fetchProducts: builder.query<Product[], void>({
			query: () => "/",
		}),
	}),
});

export const { useFetchProductsQuery } = productSlice;
