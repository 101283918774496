import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/FormComponents/TextInput";
import { Customer } from "../../models/customer";
import { Breakpoints } from "../../styles/Breakpoints";
import {
	validateCity,
	validateEmail,
	validateFirstName,
	validateLastName,
	validatePhoneNumber,
	validateStreet,
	validateZipcode,
} from "../../utils/validators";

interface Props {
	customer: Customer;
	onCancel: () => void;
	onSave: (newCustomerObject: Customer) => void;
}

const EditPersonalDetails = ({ customer, onCancel, onSave }: Props) => {
	const [firstName, setFirstName] = useState(customer.first_name);
	const [firstNameIsValid, setFirstNameIsValid] = useState(true);
	const [lastName, setLastName] = useState(customer.name);
	const [lastNameIsValid, setLastNameIsValid] = useState(true);
	const [email, setEmail] = useState(customer.email);
	const [emailIsValid, setEmailIsValid] = useState(true);
	const [phone, setPhone] = useState(
		customer.contact_phone.replace("+46", "0")
	);
	const [phoneIsValid, setPhoneIsValid] = useState(true);
	const [street, setStreet] = useState(customer.address);
	const [streetIsValid, setStreetIsValid] = useState(true);
	const [postalCode, setPostalCode] = useState(customer.postal_code);
	const [postalCodeIsValid, setPostalCodeIsValid] = useState(true);
	const [city, setCity] = useState(customer.city);
	const [cityIsValid, setCityIsValid] = useState(true);
	const [inputsAreValid, setInputsAreValid] = useState(false);

	const handleUpdateCustomer = async () => {
		const customerToUpdate = {
			...customer,
			first_name: firstName,
			name: lastName,
			email,
			contact_phone: `+46${phone.slice(1)}`,
			address: street,
			postal_code: postalCode.replace(" ", ""),
			city,
		};
		onSave(customerToUpdate);
	};

	useEffect(() => {
		document.title = "Mitt Vobbiz - Mina uppgifter";
	}, []);

	useEffect(() => {
		setInputsAreValid(
			firstNameIsValid &&
				lastNameIsValid &&
				emailIsValid &&
				phoneIsValid &&
				streetIsValid &&
				postalCodeIsValid &&
				cityIsValid
		);
	}, [
		firstName,
		lastName,
		email,
		phone,
		firstNameIsValid,
		lastNameIsValid,
		emailIsValid,
		phoneIsValid,
		streetIsValid,
		postalCodeIsValid,
		cityIsValid,
	]);

	return (
		<Container>
			<InfoText>
				Tänk på att det är viktigt att informationen du anger är
				korrekt, annars riskerar du att missa viktig information! 😱
			</InfoText>
			<InputsContainer>
				<TextInput
					type="text"
					inputName="first-name"
					label="Förnamn"
					value={firstName}
					setValue={setFirstName}
					isValid={firstNameIsValid}
					setIsValid={setFirstNameIsValid}
					validate={validateFirstName}
				/>
				<TextInput
					type="text"
					inputName="last-name"
					label="Efternamn"
					value={lastName}
					setValue={setLastName}
					isValid={lastNameIsValid}
					setIsValid={setLastNameIsValid}
					validate={validateLastName}
				/>
				<TextInput
					type="text"
					inputName="email"
					label="E-post"
					value={email}
					setValue={setEmail}
					isValid={emailIsValid}
					setIsValid={setEmailIsValid}
					validate={validateEmail}
				/>
				<TextInput
					type="text"
					inputName="phone"
					label="Kontakttelefon"
					value={phone}
					setValue={setPhone}
					isValid={phoneIsValid}
					setIsValid={setPhoneIsValid}
					validate={validatePhoneNumber}
				/>
				<AddressContainer>
					<TextInput
						type="text"
						inputName="street"
						label="Gata"
						value={street}
						setValue={setStreet}
						isValid={streetIsValid}
						setIsValid={setStreetIsValid}
						validate={validateStreet}
					/>
					<TextInput
						type="text"
						inputName="postal-code"
						label="Postnummer"
						value={postalCode}
						setValue={setPostalCode}
						isValid={postalCodeIsValid}
						setIsValid={setPostalCodeIsValid}
						validate={validateZipcode}
					/>
					<TextInput
						type="text"
						inputName="city"
						label="Ort"
						value={city}
						setValue={setCity}
						isValid={cityIsValid}
						setIsValid={setCityIsValid}
						validate={validateCity}
					/>
				</AddressContainer>
			</InputsContainer>
			<ButtonsContainer>
				<Button
					disabled={!inputsAreValid}
					onClick={handleUpdateCustomer}
				>
					Spara
				</Button>
				<Button ghost onClick={onCancel}>
					Avbryt
				</Button>
			</ButtonsContainer>
		</Container>
	);
};

const Container = styled.div``;

const InputsContainer = styled.div`
	margin-bottom: 32px;

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		display: grid;
		grid-column-gap: 32px;
		grid-template-columns: 1fr 1fr;
	}
`;

const InfoText = styled.p`
	max-width: 350px;
	text-align: center;
	margin-bottom: 48px;
	margin-inline: auto;
`;

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
`;

const AddressContainer = styled.div`
	grid-column: span 2;
	padding: 32px 32px 8px 32px;
	border: 1px dashed hsla(228, 70%, 79%, 0.3);
	border-radius: 16px;
	position: relative;
	margin-top: 32px;

	&::before {
		content: "Kontaktadress";
		position: absolute;
		line-height: 1;
		top: -8px;
		left: 28px;
		color: var(--color-gray);
		padding: 0 4px;
		background-color: #fff;
	}
`;

export default EditPersonalDetails;
