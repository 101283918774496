import React from "react";
import styled from "styled-components";
import { ScreenReaderText } from "../../ScreenReaderText";

export const DottedDivider: React.FC = () => (
	<StyledDottedDivider className="dot-container">
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<ScreenReaderText text="Sektionsuppdelare" />
	</StyledDottedDivider>
);

const StyledDottedDivider = styled.div`
	display: flex;
	flex-direction: row;
	padding: 2rem 0;

	.dot {
		width: 6px;
		height: 6px;
		border-radius: 50%;

		:first-child,
		:nth-child(2),
		:nth-child(3),
		:nth-child(4) {
			margin-right: 0.3rem;
		}

		:first-child {
			background: var(--color-purple);
		}

		:nth-child(2) {
			background: var(--color-pink);
			margin-right: 0.3rem;
		}

		:nth-child(3) {
			background: var(--color-salmon);
		}

		:nth-child(4) {
			background: var(--color-teal);
		}

		:nth-child(5) {
			background: var(--color-light-teal);
		}
	}

	@media screen and (max-width: 600px) {
		.dot {
			width: 4px;
			height: 4px;

			:first-child,
			:nth-child(2),
			:nth-child(3),
			:nth-child(4) {
				margin-right: 0.2rem;
			}
		}
	}
`;
