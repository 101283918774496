import React from "react";
import styled from "styled-components";

type Props = {
	children: React.ReactNode;
	onChangeHandler?: React.ChangeEventHandler<HTMLSelectElement>;
	selectInputHidden?: string;
	value: string;
	ariaLabel?: string;
	inputName: string;
	label?: string;
};

export const SelectInput: React.FC<Props> = (props: Props) => {
	const {
		children,
		onChangeHandler,
		selectInputHidden,
		value,
		ariaLabel,
		inputName,
		label,
	} = props;

	return (
		<Wrapper selectInputHidden={selectInputHidden}>
			<label htmlFor={inputName}>{label}</label>
			<NativeSelect
				required
				aria-label={ariaLabel}
				onChange={onChangeHandler}
				id={inputName}
				name={inputName}
				value={value}
			>
				{children}
			</NativeSelect>
		</Wrapper>
	);
};

interface SelectProps {
	selectInputHidden?: string;
}

const Wrapper = styled.div<SelectProps>`
	visibility: ${props => props.selectInputHidden};
	position: relative;
	width: max-content;
`;

const NativeSelect = styled.select`
	width: 100%;
	min-width: 300px;
	max-width: 350px;
	background: #fff;
	border: 1px solid var(--color-light-gray);
	border-radius: 0.5rem;
	font: inherit;
	margin: 1rem 0;
	padding: 0.5rem 2.5rem 0.5rem 1rem;
	outline: none;

	&:focus {
		outline: 3px solid var(--color-purple);
		outline-offset: 3px;
	}

	&hover {
		color: var(--color-blackish);
	}
`;
