export const saveTokenToSessionStorage = (token: string) => {
	sessionStorage.setItem("token", token);
};

export const getTokenFromSessionStorage = (): string | null => {
	const token = sessionStorage.getItem("token");
	return token || null;
};

export const clearTokenFromSessionStorage = () => {
	sessionStorage.removeItem("token");
};
