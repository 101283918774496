import React from "react";
import styled from "styled-components";

interface Props {
	children: React.ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	secondary?: boolean;
	ghost?: boolean;
	className?: string;
}

/**
 * @description A simple button component
 * @param children - a node of react children to render inside the component
 * @param onClick - a function to call when the button is clicked
 * @param secondary - a boolean that is added if the button should return a button with seceondary colors instead of primary
 * @param ghost - a boolean that is added if the button should be of type "ghost" (a more discreet variant with colored border and light background)
 */

export const Button: React.FC<Props> = props => {
	const { children, secondary, ghost, onClick, disabled, className } = props;

	switch (ghost) {
		case true:
			return (
				<ButtonGhost
					className={className}
					secondary={secondary}
					onClick={onClick}
					disabled={disabled}
				>
					{children}
				</ButtonGhost>
			);
		default:
			return (
				<ButtonPrimary
					className={className}
					secondary={secondary}
					onClick={onClick}
					disabled={disabled}
				>
					{children}
				</ButtonPrimary>
			);
	}
};

interface ButtonProps {
	secondary?: boolean;
}

const ButtonBase = styled.button<ButtonProps>`
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	padding: 17px 24px 16px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.5px;
	border: none;
	border-radius: 0.5rem;
	font-family: inherit;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease;
	transition-property: background, background-color, background-image,
		box-shadow, transform;
	line-height: 1;
	font-size: var(--font-size-s);

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&:active,
	&:focus {
		outline: ${props =>
			props.secondary
				? "3px solid var(--color-teal)"
				: "3px solid var(--color-purple)"};
		outline-offset: 4px;
	}

	&:hover {
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
		transform: translateY(-2px);

		&:disabled {
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
			transform: translateY(0);
		}
	}
`;

const ButtonPrimary = styled(ButtonBase)<ButtonProps>`
	text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
	background-color: ${props =>
		props.secondary ? "var(--color-teal)" : "var(--color-purple)"};
	color: var(--color-whiteish);
	background-image: ${props =>
		props.secondary
			? "linear-gradient(to right, var(--color-teal), var(--color-teal))"
			: "linear-gradient(to right, var(--color-purple), var(--color-purple))"};

	&:hover {
		background: ${props =>
			props.secondary
				? "var(--gradient-secondary)"
				: "var(--gradient-primary)"};
	}
`;

const ButtonGhost = styled(ButtonBase)<ButtonProps>`
	border: 3px solid
		${props =>
			props.secondary ? "var(--color-teal)" : "var(--color-purple)"};
	color: var(--color-gray);
	background: ${props =>
		props.secondary
			? "linear-gradient(to right, #ECF3F4, #ECF3F4)"
			: "linear-gradient(to right, #F0EDF5, #F0EDF5)"};
	padding: calc(1rem - 3px) calc(1.5rem - 3px);

	&:hover {
		text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
		color: var(--color-whiteish);
		background: ${props =>
			props.secondary
				? "var(--gradient-secondary)"
				: "var(--gradient-primary)"};
		border: none;
		padding: 1rem 1.5rem;
	}
`;
