import jwtDecode from "jwt-decode";
import {
	clearTokenFromSessionStorage,
	getTokenFromSessionStorage,
} from "../services/sessionStorageServices";

interface Token {
	customerId: number;
	exp: number;
	iat: number;
}

export const useAuth = () => {
	let token = getTokenFromSessionStorage();

	if (token) {
		const decodedToken: Token = jwtDecode(token);
		if (decodedToken.customerId && decodedToken.exp * 1000 < Date.now()) {
			token = null;
			clearTokenFromSessionStorage();
		}
	}

	return token;
};
