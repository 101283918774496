import React from "react";
import styled from "styled-components";
import { AnchorText } from "../AnchorText";

export const BecomeCustomer: React.FC = () => (
	<StyledBecomeCustomer>
		<p>Va? Är du inte kund hos oss än!? 😲</p>{" "}
		<AnchorText
			desiredRedirectPath="/bli-kund"
			text="Teckna ett abonnemang här!"
		/>
	</StyledBecomeCustomer>
);

const StyledBecomeCustomer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 32px;
`;
