import styled from "styled-components";

export const SwitchToVobbizCompanyLink: React.FC = () => (
	<LinkWrapper>
		<a href="https://vobbiz.se/">Företag</a>
		<a href="https://privat.vobbiz.se/">Privat</a>
	</LinkWrapper>
);

const LinkWrapper = styled.div`
	position: absolute;
	top: 32px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 32px;

	a {
		text-transform: uppercase;
		text-decoration: none;
		color: var(--color-whiteish);
		font-size: var(font-size-m);
		font-weight: 600;

		&:hover {
			text-decoration: underline;
		}
	}
`;
