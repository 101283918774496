import { FETCHURL } from "../constants/constants";
import { ClientErrorResponse } from "../models/clientErrorResponse";
import { ContractToBeCreated } from "../models/contract";
import { CustomerToBeCreated } from "../models/customer";

export const createNewCustomer = async (
	customer: CustomerToBeCreated,
	contract: ContractToBeCreated,
	wantsNewNumber: boolean,
	discountCode: string
) => {
	const response = await fetch(`${FETCHURL}/api/customer`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			customer,
			contract,
			wantsNewNumber,
			discountCode,
		}),
	});

	const data = await response.json();

	if (!response.ok) {
		throw new ClientErrorResponse(
			data.message || "Något gick fel, testa att försöka igen"
		);
	}

	return data;
};
