import { Calendar } from "react-feather";
import styled from "styled-components";
import { Breakpoints } from "../../styles/Breakpoints";
import { AnimatedSecondaryHeading } from "../AnimatedSecondaryHeading/AnimatedSecondaryHeading";

export const News = () => (
	<Container>
		<AnimatedSecondaryHeading>Nyheter</AnimatedSecondaryHeading>
		<NewsDate>
			<Calendar size={18} />
			<span>2022-09-05</span>
		</NewsDate>
		<p>
			Äntligen är Mitt Vobbiz live! 🥳 På den här sidan hittar du nyheter
			från oss på Vobbiz samt en översikt av ditt eller dina abonnemang.
		</p>
		<p>
			Mer detaljerad information om ditt abonnemang och våra tjänster
			hittar du i menyn. Tveka inte att höra av dig till oss om du har
			några frågor eller hittar något som ser tokigt ut! Det gör du på
			"Kontakta oss" i menyn, eller genom att trycka på frågetecknet nere
			i högra hörnet på din skärm.
		</p>
		<p>Ha en toppendag! ⭐️</p>
	</Container>
);

const Container = styled.article`
	flex: 1;
	border: 1px dashed hsla(190, 42%, 70%, 0.4);
	padding: 32px;
	border-radius: 16px;
	max-width: 600px;

	@media (min-width: ${Breakpoints.Laptop}) {
		max-width: 100%;
	}

	p:not(:first-of-type) {
		margin-bottom: 16px;
	}

	h2 {
		margin-bottom: 8px;
	}
`;

const NewsDate = styled.p`
	color: var(--color-whiteish);
	background: var(--color-teal-dark);
	display: flex;
	align-items: center;
	padding: 8px;
	border-radius: 8px;
	width: 100%;
	font-size: var(--font-size-s);
	margin-bottom: 32px;

	& span {
		margin-left: 8px;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
	}
`;
