import React from "react";
import styled from "styled-components";
import { ScreenReaderText } from "../ScreenReaderText";

type Props = {
	iconName: string;
	children: React.ReactNode;
	secondary?: boolean;
};

/**
 * @description A gradient circle that should contain an icon as child
 * @param children - should contain an icon element, like an svg or an icon from a package
 * @param iconName - should contain a description of the icon used to display appropriate screen readers
 * @param secondary - a boolean that is added if the circle should return with seceondary colors instead of primary
 */

export const CircledIcon: React.FC<Props> = props => {
	const { children, secondary, iconName } = props;

	return (
		<GradientCircle className="gradient-circle" secondary={secondary}>
			{children}
			<ScreenReaderText text={`${iconName} icon`} />
		</GradientCircle>
	);
};

interface CircleProps {
	secondary?: boolean;
}

const GradientCircle = styled.div<CircleProps>`
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	background: ${props =>
		props.secondary ? "var(--gradient-mixed)" : "var(--gradient-primary)"};
	color: var(--color-whiteish);
`;
