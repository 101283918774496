import { useState, useEffect, MouseEventHandler } from "react";
import styled from "styled-components";
import { Star } from "react-feather";
import { Subscription } from "../../models/subscription";
import { PageWrapper } from "../../components/PageWrapper";
import { Button } from "../../components/Button";
import { Wizard } from "../../components/Wizard";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { Product } from "../../models/product";
import { ContractToBeUpdated } from "../../models/contract";
import {
	useGetSubscriptionsQuery,
	useUpdateContractMutation,
} from "../../store/slices/subscriptionSlice";
import { useGetCustomerQuery } from "../../store/slices/customerSlice";
import { AlertMessage } from "../../components/AlertMessage";
import { CircledIcon } from "../../components/CircledIcon";
import { sendEmail } from "../../services/mailServices";

const totalSteps = 3;

export function ChangeProductsPage() {
	// Data from Redux
	const { data: customer } = useGetCustomerQuery();
	const {
		data: activeSubscriptions,
		isLoading,
		isError,
	} = useGetSubscriptionsQuery();
	const [updateContract] = useUpdateContractMutation();
	const [changeWasSuccessful, setChangeWasSuccessful] = useState(false);

	// State variables for the whole flow of becoming a customer
	const [selectedProduct, setSelectedProduct] = useState<
		Product | undefined
	>();
	const [currentStep, setCurrentStep] = useState(1);
	const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
	const [wantsNewSubscription, setWantsNewSubscription] = useState(false);
	const [subscriptionToChange, setSubscriptionToChange] = useState<
		Subscription | undefined
	>(undefined);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertMessageIsVisible, setAlertMessageIsVisible] =
		useState<boolean>(false);

	useEffect(() => {
		document.title = "Mitt Vobbiz - Byta abonnemang";
	}, []);

	const changeSubscriptionHandler: MouseEventHandler<
		HTMLButtonElement
	> = async () => {
		setChangeWasSuccessful(false);
		if (!selectedProduct) {
			setAlertMessage("Du måste välja ett abonnemang!");
			setAlertMessageIsVisible(true);
			return;
		}

		if (wantsNewSubscription) {
			try {
				if (!customer) {
					throw new Error("Kunde inte hitta din mejladress");
				}

				const messageFromCustomer = `Kund önskar nytt abonnemang: ${selectedProduct.name}`;

				const emailSent = await sendEmail(
					customer.email,
					messageFromCustomer
				);

				if (emailSent) {
					setChangeWasSuccessful(true);
					setAlertMessage(
						"Du har skapat ett nytt abonnemang! 🎉 Vi kontaktar dig med mer information om ditt nya telefonnummer inom 8 timmar."
					);
					setAlertMessageIsVisible(true);
					setButtonIsDisabled(true);
				} else {
					throw new Error();
				}
			} catch (error) {
				setAlertMessage("Något gick fel, försök igen!");
				setAlertMessageIsVisible(true);
			}
		}

		if (!wantsNewSubscription && subscriptionToChange) {
			try {
				if (customer) {
					const contractToBeUpdated: ContractToBeUpdated = {
						product_name: selectedProduct.name,
						product_id: selectedProduct.id,
						next_price: selectedProduct.unit_price,
						unit_price: selectedProduct.unit_price,
						customer_id: customer.id,
						subscription_id: subscriptionToChange.id,
					};

					const contractId = subscriptionToChange.contracts[0].id;
					const updatedContract = await updateContract({
						contract: contractToBeUpdated,
						contractId,
					});

					if ("data" in updatedContract) {
						setChangeWasSuccessful(true);
						setAlertMessage(
							"Abonnemanget är ändrat! 🎉 Vad vill du göra nu tro?"
						);
						setAlertMessageIsVisible(true);
						setButtonIsDisabled(true);
					}
				} else {
					throw new Error();
				}
			} catch (error) {
				setAlertMessage(
					"Något gick fel när vi försökte byta ditt abonnemang. Du kan försöka igen eller kontakta Vobbiz support för hjälp."
				);
				setAlertMessageIsVisible(true);
			}
		}
	};

	return (
		<PageWrapper secondary>
			<MainContainer>
				<CircledIcon secondary iconName="star">
					<Star />
				</CircledIcon>
				<h1>Ändra abonnemang</h1>
				<WizardContainer>
					<Wizard currentStep={currentStep} totalSteps={totalSteps} />
				</WizardContainer>
				{currentStep === 1 && (
					<Step1
						isLoading={isLoading}
						isError={isError}
						setButtonIsDisabled={setButtonIsDisabled}
						activeSubscriptions={activeSubscriptions}
						wantsNewSubscription={wantsNewSubscription}
						setWantsNewSubscription={setWantsNewSubscription}
						subscriptionToChange={subscriptionToChange}
						setSubscriptionToChange={setSubscriptionToChange}
						customer={customer}
					/>
				)}
				{currentStep === 2 && (
					<Step2
						setButtonIsDisabled={setButtonIsDisabled}
						selectedProduct={selectedProduct}
						setSelectedProduct={setSelectedProduct}
					/>
				)}
				{currentStep === 3 && (
					<Step3
						wantsNewSubscription={wantsNewSubscription}
						subscriptionToChange={subscriptionToChange}
						selectedProduct={selectedProduct}
					/>
				)}
			</MainContainer>

			<ButtonsContainer>
				<Button
					secondary
					ghost
					onClick={() => {
						setCurrentStep(currentStep - 1);
						setAlertMessageIsVisible(false);
						window.scrollTo(0, 0);
					}}
					disabled={currentStep === 1}
				>
					Tillbaka
				</Button>
				{currentStep === 3 ? (
					<Button
						secondary
						disabled={buttonIsDisabled}
						onClick={changeSubscriptionHandler}
					>
						{wantsNewSubscription
							? "Beställ nytt abonnemang"
							: "Ändra abonnemang"}
					</Button>
				) : (
					<Button
						secondary
						disabled={
							currentStep === totalSteps || buttonIsDisabled
						}
						onClick={() => {
							setCurrentStep(currentStep + 1);
							window.scrollTo(0, 0);
						}}
					>
						Nästa
					</Button>
				)}
			</ButtonsContainer>
			<AlertMessage
				isSuccess={changeWasSuccessful}
				isVisible={alertMessageIsVisible}
			>
				{alertMessage}
			</AlertMessage>
		</PageWrapper>
	);
}

const WizardContainer = styled.section`
	margin-top: 2rem;
	width: 150px;
	margin-bottom: 32px;
`;

const MainContainer = styled.section`
	width: fit-content;
	margin-block: 2rem;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		text-align: center;
		margin-bottom: 32px;
	}

	.error {
		color: var(--color-error);
	}
`;

const ButtonsContainer = styled.section`
	width: 100%;
	max-width: 250px;
	margin-inline: auto;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	button {
		margin-right: 16px;
	}
`;
