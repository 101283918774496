import { Subscription } from "../models/subscription";

export const getImsiNumbersFromSubscriptions = (
	activeSubscriptions: Subscription[]
) => {
	const imsiNumbersToReturn: string[] = [];

	if (activeSubscriptions) {
		activeSubscriptions.forEach(contract => {
			contract.phone_numbers.map(phoneNumber => {
				imsiNumbersToReturn.push(phoneNumber.imsi);
				return imsiNumbersToReturn;
			});
		});
	}

	return imsiNumbersToReturn;
};
