import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "react-feather";
import styled from "styled-components";
import { loginService, verifyToken } from "../../services/authService";
import {
	getTokenFromSessionStorage,
	saveTokenToSessionStorage,
} from "../../services/sessionStorageServices";
import { CircledIcon } from "../../components/CircledIcon";
import { TextInput } from "../../components/FormComponents/TextInput";
import { Button } from "../../components/Button";
import { MinaSidorLogo } from "../../components/MinaSidorLogo";
import { PageWrapper } from "../../components/PageWrapper";
import { useResetStoreState } from "../../hooks/useResetStoreState";

export function LoginPageDev() {
	const navigate = useNavigate();
	const resetStoreState = useResetStoreState();

	const [identityNumber, setIdentityNumber] = useState("");
	const [identityNumberIsValid, setIdentityNumberIsValid] = useState(false);

	const loginHandler = async () => {
		try {
			const loginResponse = await loginService(identityNumber);

			if (loginResponse.error) {
				console.log(loginResponse.error);
				setIdentityNumberIsValid(false);
			} else if (loginResponse.token) {
				setIdentityNumberIsValid(true);
				saveTokenToSessionStorage(loginResponse.token);
				resetStoreState();
				navigate("/oversikt");
			} else {
				console.log("Something went wrong");
			}
		} catch (error) {
			console.log(error);
		}
	};

	const checkIfUserIsLoggedIn = async () => {
		const token = getTokenFromSessionStorage();
		if (token) {
			const verifiedToken = await verifyToken(token);
			if (verifiedToken) {
				resetStoreState();
				navigate("/oversikt");
			}
		}
	};

	useEffect(() => {
		checkIfUserIsLoggedIn();
	});

	return (
		<PageWrapper hideMenu hideFooter>
			<LoginContainer>
				<MinaSidorLogo />
				<CircledIcon iconName="user" secondary>
					<User />
				</CircledIcon>
				<TextInput
					type="text"
					inputName="identityNumber"
					label="Personnummer"
					placeholder="YYYYMMDDXXXX"
					value={identityNumber}
					setValue={setIdentityNumber}
					isValid={identityNumberIsValid}
					setIsValid={setIdentityNumberIsValid}
				/>
				<Button secondary onClick={loginHandler}>
					Logga in
				</Button>
			</LoginContainer>
		</PageWrapper>
	);
}

const LoginContainer = styled.section`
	width: 100%;
	max-width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	& button {
		width: 100%;
	}
`;
