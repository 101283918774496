import React, { useState } from "react";
import { User } from "react-feather";
import styled from "styled-components";
import { CircledIcon } from "../../components/CircledIcon";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { PageWrapper } from "../../components/PageWrapper";
import { Customer } from "../../models/customer";
import {
	useGetCustomerQuery,
	useUpdateCustomerMutation,
} from "../../store/slices/customerSlice";
import DisplayPersonalDetails from "./DisplayPersonalDetails";
import EditPersonalDetails from "./EditPersonalDetails";
import { AlertMessage } from "../../components/AlertMessage";

enum View {
	Display = "display",
	Edit = "edit",
}

const CustomerPersonalDetailsPage = () => {
	const [view, setView] = React.useState(View.Display);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertMessageIsVisible, setAlertMessageIsVisible] =
		useState<boolean>(false);
	const [updateCustomer] = useUpdateCustomerMutation();
	const {
		data: customer,
		isLoading,
		isSuccess,
		isError,
	} = useGetCustomerQuery();
	const [editWasSuccessful, setEditWasSuccessful] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const renderView = () => {
		switch (view) {
			case View.Display:
				return (
					customer && (
						<DisplayPersonalDetails
							onEdit={onEdit}
							customer={customer}
						/>
					)
				);
			case View.Edit:
				return (
					customer && (
						<EditPersonalDetails
							onSave={onSave}
							onCancel={onCancel}
							customer={customer}
						/>
					)
				);
			default:
				return null;
		}
	};

	const onEdit = () => {
		setView(View.Edit);
		setAlertMessage("");
	};

	const onCancel = () => {
		setView(View.Display);
		setAlertMessage("");
	};

	const onSave = async (newCustomerObject: Customer) => {
		try {
			await updateCustomer(newCustomerObject)
				.unwrap()
				.then(() => {
					setView(View.Display);
					setAlertMessageIsVisible(true);
					setAlertMessage("Dina uppgifter är uppdaterade.");
					setEditWasSuccessful(true);
					window.scrollTo(0, 0);
				})
				.catch(error => {
					setEditWasSuccessful(false);
					setAlertMessageIsVisible(true);
					setAlertMessage(error.data.message);
				});
		} catch (err) {
			setEditWasSuccessful(false);
			setAlertMessageIsVisible(true);
			setAlertMessage(
				"Något gick fel när vi försökte uppdatera dina uppgifter, försök igen."
			);
		}
	};

	return (
		<PageWrapper secondary>
			<Container>
				<CircledIcon secondary iconName="user">
					<User />
				</CircledIcon>
				<Heading>Mina uppgifter</Heading>
				{isLoading && <LoadingSpinner />}
				{isError && (
					<AlertMessage isVisible={isError}>
						Något gick fel när vi försökte hämta dina uppgifter. Du
						kan försöka igen eller kontakta vår support för hjälp.
					</AlertMessage>
				)}
				{isSuccess && renderView()}
				<AlertMessage
					isVisible={alertMessageIsVisible}
					isSuccess={editWasSuccessful}
				>
					{alertMessage}
				</AlertMessage>
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0;

	.error {
		color: var(--color-error);
	}
`;

const Heading = styled.h1`
	margin-bottom: 32px;
`;

export default CustomerPersonalDetailsPage;
