import { useEffect, useState } from "react";
import { Mail } from "react-feather";
import styled from "styled-components";
import { EmailObj } from "../../models/emailObj";
import { Breakpoints } from "../../styles/Breakpoints";
import { CircledIcon } from "../CircledIcon";
import support from "../../assets/images/support.png";
import { TextInput } from "../FormComponents/TextInput";
import {
	validateEmail,
	validateMessage,
	validateName,
} from "../../utils/validators";
import { TextArea } from "../FormComponents/TextArea";
import { Button } from "../Button";
import { LoadingSpinner } from "../LoadingSpinner";

interface Props {
	sendMessage: (emailObj: EmailObj) => void;
	isSending: boolean;
	actionWasSuccessful: boolean;
}

/**
 * @description A contact form that can be used to send email to Vobbiz
 * @param sendMessage - function to be called when form is submitted
 * @param isSending - boolean that indicates that the form is currently sending, used to display a loading spinner
 * @param actionWasSuccessful - boolean that indicates that the form was successfully sent
 */

const ContactForm = (props: Props) => {
	const { sendMessage, isSending, actionWasSuccessful } = props;
	const [message, setMessage] = useState("");
	const [messageIsValid, setMessageIsValid] = useState(false);
	const [name, setName] = useState("");
	const [nameIsValid, setNameIsValid] = useState(false);
	const [email, setEmail] = useState("");
	const [emailIsValid, setEmailIsValid] = useState(false);
	const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

	const onSubmit = async () => {
		const emailObj: EmailObj = {
			name,
			email,
			message,
		};

		sendMessage(emailObj);

		if (actionWasSuccessful) resetForms();
	};

	const resetForms = () => {
		setName("");
		setEmail("");
		setMessage("");
		setButtonIsDisabled(true);
		setNameIsValid(false);
		setEmailIsValid(false);
		setMessageIsValid(false);
	};

	useEffect(() => {
		setButtonIsDisabled(true);

		if (nameIsValid && emailIsValid && messageIsValid) {
			setButtonIsDisabled(false);
		}
	}, [
		buttonIsDisabled,
		message,
		name,
		email,
		nameIsValid,
		emailIsValid,
		messageIsValid,
	]);

	return (
		<Container>
			<CircledIcon iconName="mail">
				<Mail />
			</CircledIcon>
			<h2>Kontakta oss</h2>
			<p>
				Vill du skicka ett mail direkt till oss kan du använda dig av
				detta formulär så återkommer vi så snart vi kan. 😊
			</p>
			<p>
				Ringer du hellre?
				<span>
					Då når du oss på telefonnummer 031-745&nbsp;67&nbsp;50!
				</span>
			</p>
			<ContentContainer>
				<img
					src={support}
					alt="Pratbubbla med texten 'Ring' och 'Maila' inuti"
				/>
				<FormContainer onSubmit={onSubmit}>
					<TextInput
						type="text"
						inputName="name"
						label="Namn"
						value={name}
						setValue={setName}
						isValid={nameIsValid}
						setIsValid={setNameIsValid}
						validate={validateName}
					/>
					<TextInput
						type="email"
						inputName="email"
						label="E-post"
						placeholder="namn@mail.com"
						value={email}
						setValue={setEmail}
						isValid={emailIsValid}
						setIsValid={setEmailIsValid}
						validate={validateEmail}
					/>
					<TextArea
						inputName="message"
						label="Meddelande"
						placeholder="Skriv ditt meddelande här"
						value={message}
						setValue={setMessage}
						isValid={messageIsValid}
						setIsValid={setMessageIsValid}
						validate={validateMessage}
					/>

					<Button onClick={onSubmit} disabled={buttonIsDisabled}>
						Skicka
					</Button>
				</FormContainer>
			</ContentContainer>
			{isSending && <LoadingSpinner />}
		</Container>
	);
};

const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 96px;

	& h2 {
		max-width: 500px;
		line-height: 1.2;
		margin-bottom: 32px;
		color: hsla(263, 42%, 65%, 1);
		font-size: var(--font-size-xxl);
		text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.08);
	}

	& > p {
		max-width: 500px;
		margin-bottom: 16px;
		text-align: center;
	}
`;

const ContentContainer = styled.div`
	width: 100%;
	margin-top: 48px;
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	@media (min-width: ${Breakpoints.TabletLandscape}) {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 48px;
	}

	& img {
		width: 100%;
		max-width: 500px;
		border-radius: 1.5rem;
		height: auto;
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
		margin-inline: auto;
		margin-bottom: 48px;
	}
`;

const FormContainer = styled.div`
	width: 100%;
	max-width: 700px;
	margin-inline: auto;
`;

export default ContactForm;
