import { useEffect, useState } from "react";
import styled from "styled-components";
import { Phone, Unlock } from "react-feather";
import { PageWrapper } from "../../components/PageWrapper";
import { CircledIcon } from "../../components/CircledIcon";
import { useGetSubscriptionsQuery } from "../../store/slices/subscriptionSlice";
import { AlertMessage } from "../../components/AlertMessage";
import { PinPukCard } from "../../components/PinPukCard/PinPukCard";
import { DottedDivider } from "../../components/Dividers/DottedDivider";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { getImsiNumbersFromSubscriptions } from "../../utils/getImsiNumbersFromSubscriptions";
import { getSimCardDataFromImsiNumbers } from "../../services/simCardServices";
import { SimWithPUK } from "../../models/simWithPuk";
import { ErrorWhenUserHasNoSubscription } from "../../components/ErrorWhenUserHasNoSubscription/ErrorWhenUserHasNoSubscription";
import { pairSimCardDataWithPhoneNumbers } from "../../utils/pairSimCardDataWithPhoneNumbers";

export const PinPukPage: React.FC = () => {
	const {
		data: activeSubscriptions,
		isLoading,
		isError,
	} = useGetSubscriptionsQuery();
	const [isFetchingPUK, setIsFetchingPUK] = useState(true);
	const [imsiNumbers, setImsiNumbers] = useState<string[]>([]);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertMessageIsVisible, setAlertMessageIsVisible] =
		useState<boolean>(false);
	const [simCardDataWithPhoneNumbers, setSimCardDataWithPhoneNumbers] =
		useState<SimWithPUK[]>([]);

	useEffect(() => {
		document.title = "Mitt Vobbiz - PIN/PUK kod";
	}, []);

	useEffect(() => {
		if (activeSubscriptions && activeSubscriptions.length > 0) {
			setImsiNumbers(
				getImsiNumbersFromSubscriptions(activeSubscriptions)
			);
		}
	}, [activeSubscriptions]);

	const getSimCardData = async () => {
		setIsFetchingPUK(true);

		try {
			setAlertMessageIsVisible(false);
			const simCardData = await getSimCardDataFromImsiNumbers(
				imsiNumbers
			);

			if (!simCardData || simCardData.length === 0) {
				throw new Error();
			}

			if (activeSubscriptions) {
				const pairedData = pairSimCardDataWithPhoneNumbers(
					simCardData,
					activeSubscriptions
				);

				if (!pairedData || pairedData.length === 0) {
					throw new Error();
				}

				setSimCardDataWithPhoneNumbers(pairedData);
				setIsFetchingPUK(false);
			}
		} catch (_) {
			setIsFetchingPUK(false);
			setAlertMessage(
				"Något gick fel när vi försökte hämta PUK-koder, testa att ladda om sidan."
			);
			setAlertMessageIsVisible(true);
		}
	};

	useEffect(() => {
		if (imsiNumbers.length > 0) {
			getSimCardData();
		} else {
			setIsFetchingPUK(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imsiNumbers]);

	return (
		<PageWrapper secondary>
			<Container>
				<CircledIcon iconName="unlock" secondary>
					<Unlock />
				</CircledIcon>
				<h1>PIN/PUK-kod</h1>

				<p>Alla våra SIM-kort levereras med PIN-kod "0000".</p>
				<p>
					Om du har ändrat PIN-koden och glömt bort den, kan du
					använda PUK-koden för att uppdatera din PIN-kod.
				</p>
				<p>
					När du skrivit fel PIN-kod tre gånger, ombeds du ange din
					PUK-kod. Därefter kan du välja en ny PIN-kod.
				</p>

				<section>
					{isError && (
						<h3 className="error">
							Något gick fel när vi försökte hämta simkortsdata,
							testa att ladda om sidan.
						</h3>
					)}
					{(isFetchingPUK || isLoading) && <LoadingSpinner />}
					{!isLoading &&
						!isError &&
						(!activeSubscriptions ||
							activeSubscriptions.length === 0) &&
						!alertMessage && <ErrorWhenUserHasNoSubscription />}
					{activeSubscriptions && activeSubscriptions.length > 0 && (
						<>
							{simCardDataWithPhoneNumbers &&
								simCardDataWithPhoneNumbers.map(card => (
									<CardWrapper key={card.puk}>
										{simCardDataWithPhoneNumbers.length >
											1 && (
											<PhoneNumber>
												<PhoneIcon>
													<Phone />
												</PhoneIcon>
												{card.phoneNumber}
											</PhoneNumber>
										)}
										<PinPukCard
											description="PUK"
											code={card.puk}
										/>
										<ICCNumber>
											<strong>SIM-nummer (ICC)</strong>:{" "}
											{card.icc}
										</ICCNumber>
										<DottedDivider />
									</CardWrapper>
								))}
						</>
					)}
				</section>
				<AlertMessage isVisible={alertMessageIsVisible}>
					{alertMessage}
				</AlertMessage>
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	margin: 0 auto;
	width: 100%;

	& > h1 {
		margin-bottom: 32px;
	}

	section {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	& .error {
		color: var(--color-error);
	}

	& > p {
		width: 100%;
		margin-bottom: 16px;
	}
`;

const CardWrapper = styled.article`
	margin-top: 32px;
	width: fit-content;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	& .dot-container {
		padding: 16px;
		width: fit-content;
		margin-inline: auto;
		margin-top: 32px;
	}

	&:last-of-type {
		& .dot-container {
			display: none;
		}
	}
`;

const ICCNumber = styled.p`
	color: var(--color-gray);
	font-size: var(--font-size-base);
	margin-top: 8px;
	text-transform: uppercase;
	text-align: center;
`;

const PhoneIcon = styled.span`
	background: var(--gradient-primary-reversed);
	width: 45px;
	height: 45px;
	border-radius: 50%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
`;

const PhoneNumber = styled.h3`
	width: 100%;
	display: flex;
	align-items: center;
	font-size: var(--font-size-xl);
	color: var(--color-purple);
	text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.06);
	font-weight: 900;
	margin-bottom: 16px;
	transform: rotate(-1deg);
`;
