import { configureStore } from "@reduxjs/toolkit";
import { productSlice } from "./slices/productSlice";
import { customerSlice } from "./slices/customerSlice";
import modalSlice from "./slices/modalSlice";
import { subscriptionSlice } from "./slices/subscriptionSlice";
import { surfSlice } from "./slices/surfSlice";

export const store = configureStore({
	reducer: {
		[customerSlice.reducerPath]: customerSlice.reducer,
		[subscriptionSlice.reducerPath]: subscriptionSlice.reducer,
		[productSlice.reducerPath]: productSlice.reducer,
		[surfSlice.reducerPath]: surfSlice.reducer,
		modal: modalSlice,
	},
	// Creates automatic cache collection, automatic refetching etc.
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			productSlice.middleware,
			customerSlice.middleware,
			subscriptionSlice.middleware,
			surfSlice.middleware
		),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
