import { useEffect } from "react";
import { CreditCard } from "react-feather";
import styled from "styled-components";
import { CircledIcon } from "../../components/CircledIcon";
import { PageWrapper } from "../../components/PageWrapper";
import { ReactComponent as PaymentIllustration } from "../../assets/images/payment.svg";

export const PaymentPage: React.FC = () => {
	useEffect(() => {
		document.title = "Mitt Vobbiz - Betalningar";
	}, []);
	return (
		<PageWrapper secondary>
			<Container>
				<CircledIcon iconName="Message circle" secondary>
					<CreditCard />
				</CircledIcon>
				<h1>Betalningar</h1>

				<p>
					Den här sidan är under uppbyggnad! Här kommer du så
					småningom kunna se dina fakturor och din fakturastatus. Fram
					tills dess skickas e-faktura till den mejladress du har
					registrerat.
				</p>
				<PaymentIllustration />
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	margin-inline: auto;
	gap: 32px;

	& .gradient-circle {
		margin: 0;
	}
`;
