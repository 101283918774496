import React from "react";
import styled from "styled-components";

interface Props {
	currentValue: number;
	totalValue: number;
	secondary?: boolean;
}

/**
 * @interface Props
 * @description A radial progress bar component that will indicate proportions between current and total value.
 * @param currentValue the current value
 * @param totalValue The max value
 * @param secondary A boolean that is added if the radial progress bar should return a progress bar with secondary colors instead of primary.
 */

export const RadialProgressBar: React.FC<Props> = props => {
	const { currentValue, totalValue, secondary } = props;

	const animationPercentages = (currentValue / totalValue) * 100;

	return (
		<ProgressBar
			className="progress-bar"
			role="progressbar"
			aria-valuemax={totalValue}
			aria-valuemin={0}
			aria-valuenow={currentValue}
			animationPercentages={animationPercentages}
			secondary={secondary}
			aria-label="Mängd surf kvar denna månaden"
		>
			<Container>
				<CurrentValue>{currentValue} GB</CurrentValue>
				<span>kvar av</span>
				<TotalValue>{totalValue} GB</TotalValue>
			</Container>
		</ProgressBar>
	);
};

interface ProgressBarProps {
	animationPercentages: number;
	secondary?: boolean;
}

const ProgressBar = styled.div<ProgressBarProps>`
	--fillColor: ${props =>
		props.secondary ? "var(--color-teal)" : "var(--color-purple)"};
	--backgroundColor: ${props =>
		props.secondary
			? "rgba(101, 179, 191, 0.2)"
			: "rgba(197, 173, 237, 0.2)"};
	--percentage: ${props => props.animationPercentages};

	animation: growProgressBar 3s 1 forwards;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 300px;
	min-width: 200px;
	width: 100%;
	height: 100%;
	aspect-ratio: 1;
	border-radius: 50%;
	background: radial-gradient(
			closest-side,
			white 70%,
			transparent 0 99.9%,
			white 0
		),
		conic-gradient(
			var(--fillColor) calc(var(--percentage) * 1%),
			var(--backgroundColor) 0
		);
	color: var(--color-gray);
`;

const Container = styled.p`
	text-align: center;
	padding-top: 32px;
	margin: 0;
	text-transform: uppercase;
	font-weight: 400;
	color: var(--color-medium-gray);
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const CurrentValue = styled.span`
	font-size: var(--font-size-l);
	font-weight: 800;
	line-height: 1;
	color: var(--color-gray);
	text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

	& + span {
		font-size: var(--font-size-s);
		font-weight: 600;
	}
`;

const TotalValue = styled.span`
	font-size: var(--font-size-m);
	font-weight: 600;
`;
