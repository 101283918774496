import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AlertTriangle, CheckSquare, XCircle } from "react-feather";
import { ValidationResult } from "../../../models/validationResult";

type Props = {
	type: string;
	inputName: string;
	label?: string;
	placeholder?: string;
	value: string;
	setValue?: (value: string) => void;
	setMultipleValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	style?: React.CSSProperties;
	isValid: boolean;
	setIsValid: (isValid: boolean) => void;
	validate?: (value: string) => ValidationResult;
	inputDisabled?: boolean;
	onClick?: React.MouseEventHandler<HTMLInputElement>;
};

/**
 * @description A text input component with validation and error handling
 * @param type The type of input to render (text, password, email etc.)
 * @param inputName The name of the input, will also be used as id
 * @param label The label to display above the input
 * @param placeholder The placeholder text to display when the input is empty
 * @param value The value of the input (presumably from a state variable)
 * @param setValue A function to set the value of the input
 * @param style An optional style object to apply to the input
 * @param isValid A boolean indicating whether the input is valid, will be set from inside the component
 * @param setIsValid A function to set the validity of the input
 * @param validate An optional function to validate the input, returns a ValidationResult object
 * @param inputDisabled An optional function to set the input field to disabled
 * @param OnClick An optional function to act on a click event
 */

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
	(props: Props, ref) => {
		const [isEmpty, setIsEmpty] = useState(true);
		const [isVisited, setIsVisited] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");
		const [warningMessage, setWarningMessage] = useState("");

		const {
			type,
			inputName,
			label,
			placeholder,
			value,
			setValue,
			style,
			isValid,
			setIsValid,
			validate,
			setMultipleValue,
			inputDisabled,
			onClick,
		} = props;

		const onChangeHandler = (
			event: React.ChangeEvent<HTMLInputElement>
		) => {
			if (setValue) {
				setValue(event.target.value);
			} else if (setMultipleValue) {
				setMultipleValue(event);
			}
			if (validate) {
				const validationResult = validate(event.target.value);
				setIsValid(validationResult.isValid);
				setErrorMessage(validationResult.errorMessage);
				setWarningMessage(validationResult.warningMessage);
			}
		};

		const onBlurHandler = () => {
			setIsVisited(true);
		};

		useEffect(() => {
			if (value.length > 0) {
				setIsEmpty(false);
			} else {
				setIsEmpty(true);
			}
		}, [value]);

		const inputClassName = `
	${isEmpty ? "empty" : ""}
	${isVisited && errorMessage ? "error" : ""}
	${isVisited && warningMessage ? "warning" : ""}`;

		return (
			<InputContainer className="input-container" style={style}>
				<Label htmlFor={inputName}>{label}</Label>
				<InputField
					type={type}
					name={inputName}
					id={inputName}
					value={value}
					onChange={onChangeHandler}
					onBlur={onBlurHandler}
					className={inputClassName}
					placeholder={placeholder}
					{...{ [inputDisabled ? "disabled" : "active"]: true }}
					ref={ref}
					onClick={onClick}
				/>
				{!isEmpty && isValid && isVisited && (
					<CheckSquare
						size={16}
						strokeWidth={1}
						className="validIcon"
					/>
				)}
				<AlertMessageContainer>
					{errorMessage && isVisited && (
						<AlertMessage className="error">
							<XCircle size={14} /> {errorMessage}
						</AlertMessage>
					)}
					{warningMessage && isVisited && (
						<AlertMessage className="warning">
							<AlertTriangle size={14} /> {warningMessage}
						</AlertMessage>
					)}
				</AlertMessageContainer>
			</InputContainer>
		);
	}
);

const InputContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;

	.validIcon {
		position: absolute;
		right: 1rem;
		top: 35px;
		color: var(--color-gray);
	}
`;

const InputField = styled.input`
	border-radius: 0.25rem;
	padding: 0.5rem 2.5rem 0.5rem 1rem;
	font: inherit;
	line-height: 0;
	border: 1px solid var(--color-light-gray);
	color: var(--color-gray);
	background: transparent;
	transition: all 0.2s;
	outline: none;
	overflow: hidden;

	&:hover,
	&:focus {
		border: 1px solid var(--color-light-gray);
		color: var(--color-blackish);
		background-color: #f9f9f9;
	}

	&:focus {
		outline: 3px solid var(--color-purple);
		outline-offset: 3px;
	}

	&.error {
		border-color: var(--color-error);
	}

	&.warning {
		border-color: var(--color-warning);
	}

	::placeholder {
		color: var(--color-medium-gray);
	}
`;

const Label = styled.label`
	font-size: 0.9rem;
	color: var(--color-gray);
`;

const AlertMessageContainer = styled.div`
	height: 1rem;
`;

const AlertMessage = styled.span`
	font-size: 0.8rem;
	display: flex;
	align-items: center;
	margin-top: 0.25rem;
	margin-left: 0.25rem;
	line-height: 1;

	&.error {
		color: var(--color-error);
	}

	&.warning {
		color: var(--color-warning);
	}

	& svg {
		margin-right: 0.25rem;
		transform: translateY(-1px);
	}
`;
