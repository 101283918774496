import styled from "styled-components";

type Props = {
	isChecked: boolean;
	value: string;
	ariaLabel: string;
	setIsChecked: (isChecked: boolean) => void;
	firstValue: string;
	secondValue: string;
};

/**
 * @description A styled checkbox that looks like a switch and lets users toggle between two values
 * @param isChecked a boolean that indicates if the checkbox is checked or not
 * @param value the value that is set when the checkbox is checked
 * @param ariaLabel a string that is used to add an aria-label to the checkbox
 * @param setIsChecked a function that sets the checkbox to checked or not
 * @param firstValue the value to the left of the checkbox (unchecked)
 * @param secoundValue the value to the right of the checkbox (checked)
 */

export const ToggleSwitch = (props: Props) => {
	const {
		value,
		isChecked,
		ariaLabel,
		firstValue,
		secondValue,
		setIsChecked,
	} = props;

	const onChangeHandler = () => {
		setIsChecked(!isChecked);
	};

	return (
		<Container>
			{firstValue}
			<CheckboxWrapper className="toggle-switch">
				<input
					aria-label={ariaLabel}
					type="checkbox"
					checked={isChecked}
					value={value}
					onChange={onChangeHandler}
				/>
				<span className="slider" />
			</CheckboxWrapper>
			{secondValue}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 16px;
`;

const CheckboxWrapper = styled.label`
	position: relative;
	display: inline-block;
	width: 60px;
	height: 24px;

	input {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 42px;

		&:focus {
			outline: 3px solid var(--color-purple);
			outline-offset: 20px;
		}
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--color-whiteish);
		border: 2px solid var(--color-purple);
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 34px;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: -2px;
		bottom: -2px;
		background: var(--gradient-mixed);
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}

	input:checked + .slider {
		background: var(--color-light-gray);
	}

	input:focus + .slider {
		box-shadow: 0 0 1px rgba(197, 173, 237, 0.8);
	}

	input:checked + .slider:before {
		-ms-transform: translateX(34px);
		transform: translateX(34px);
	}
`;
