import styled from "styled-components";

interface Props {
	description: string;
	code: string;
}

/**
 * @interface Props
 * @description A styled SIM-card containing data about the subscription like PIN and PUK numbers.
 * @param description The title of the value to display.
 * @param code The value to display.

 */

export const PinPukCard = (props: Props) => {
	const { description, code } = props;
	return (
		<Container>
			<div className="card" />
			<div className="corner-line" />
			<div className="corner-filler" />
			<div className="circuit">
				<div className="column left">
					<div />
					<div>
						<div />
						<div />
					</div>
					<div />
				</div>
				<div className="column" />
				<div className="column right">
					<div />
					<div>
						<div />
						<div />
					</div>
					<div />
				</div>
			</div>
			<div className="content">
				<p>
					<span>PIN</span> 0000
				</p>
				<p>
					<span>{description}</span> {code}
				</p>
			</div>
		</Container>
	);
};

const Container = styled.div`
	--color-gray: hsla(261, 7%, 75%, 1);
	--color-light: hsla(261, 62%, 80%, 0.25);
	position: relative;
	width: 300px;
	height: 200px;
	overflow: hidden;

	.card {
		width: 300px;
		height: 200px;
		border: 8px solid var(--color-gray);
		border-radius: 16px;
		background-color: #fff;
		position: absolute;
	}

	.corner-line {
		width: 100px;
		height: 8px;
		transform: rotate(45deg);
		background-color: var(--color-gray);
		position: absolute;
		right: -17px;
		top: 28px;
	}

	.corner-filler {
		width: 100px;
		height: 100px;
		background-color: #fff;
		transform: rotate(45deg);
		position: absolute;
		right: -57px;
		top: -54px;
	}

	.circuit {
		box-sizing: content-box;
		width: 180px;
		height: 120px;
		border: 6px solid var(--color-light);
		position: absolute;
		left: 25px;
		bottom: 25px;
		border-radius: 12px;
		display: grid;
		grid-template-columns: 55px 70px 55px;

		.column {
			height: 100%;

			& > div:nth-of-type(1) {
				width: 50px;
				height: 25px;
			}

			& > div:nth-of-type(2) {
				width: 50px;
				height: 70px;

				& > div {
					width: 70px;
					height: 35px;
					background-color: #fff;

					&:nth-of-type(1) {
						border: 6px solid var(--color-light);
						border-bottom: 3px solid var(--color-light);
					}

					&:nth-of-type(2) {
						border: 6px solid var(--color-light);
						border-top: 3px solid var(--color-light);
					}
				}
			}

			& > div:nth-of-type(3) {
				width: 50px;
			}
		}

		.column.left {
			border-right: 6px solid var(--color-light);

			& > div:nth-of-type(2) {
				transform: translateX(-6px);

				& > div:nth-of-type(1) {
					border-radius: 0 12px 0 0;
				}

				& > div:nth-of-type(2) {
					border-radius: 0 0 12px 0;
				}
			}
		}

		.column.right {
			border-left: 6px solid var(--color-light);

			& > div:nth-of-type(2) {
				transform: translateX(-15px);

				& > div:nth-of-type(1) {
					border-radius: 12px 0 0 0;
				}

				& > div:nth-of-type(2) {
					border-radius: 0 0 0 12px;
				}
			}
		}
	}

	.content {
		width: 100%;
		height: 100%;
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: var(--font-size-l);

		& p {
			margin-left: 46px;
		}

		& span {
			font-weight: 900;
		}
	}
`;
