import { useEffect, useState } from "react";
import styled from "styled-components";
import { RadioButton } from "../../../components/FormComponents/RadioButton";
import { Subscription } from "../../../models/subscription";
import { SelectInput } from "../../../components/FormComponents/SelectInput";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { getSubscriptionSelectedByUser } from "../../../utils/determineUserSelection";
import { VisibilityClass } from "../../../models/visibilityClass";
import { Customer } from "../../../models/customer";
import { AnchorText } from "../../../components/AnchorText";
import { AnimatedSecondaryHeading } from "../../../components/AnimatedSecondaryHeading/AnimatedSecondaryHeading";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { AlertMessage } from "../../../components/AlertMessage";

interface Props {
	setButtonIsDisabled: (buttonIsDisabled: boolean) => void;
	activeSubscriptions: Subscription[] | null | undefined;
	wantsNewSubscription: boolean;
	isLoading: boolean;
	isError: boolean;
	setWantsNewSubscription: (value: boolean) => void;
	subscriptionToChange: Subscription | undefined;
	setSubscriptionToChange: (subscription: Subscription | undefined) => void;
	customer: Customer | undefined;
}

export const Step1: React.FC<Props> = (props: Props) => {
	const {
		setButtonIsDisabled,
		activeSubscriptions,
		wantsNewSubscription,
		setWantsNewSubscription,
		subscriptionToChange,
		setSubscriptionToChange,
		isLoading,
		isError,
		customer,
	} = props;

	const [selectInputHidden, setSelectInputHidden] = useState<VisibilityClass>(
		VisibilityClass.Hidden
	);
	const [selectedValue, setSelectedValue] = useState("");
	const [showAlertMessage, setShowAlertMessage] = useState(false);

	useEffect(() => {
		if (wantsNewSubscription || subscriptionToChange) {
			setShowAlertMessage(true);
		}
	}, [wantsNewSubscription, subscriptionToChange]);

	const radioButtonHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (
			e.target.id === "changeCurrentContract" &&
			activeSubscriptions &&
			activeSubscriptions.length === 1
		) {
			setSubscriptionToChange(activeSubscriptions[0]);
			setWantsNewSubscription(false);
			setSelectInputHidden(VisibilityClass.Visible);
			setShowAlertMessage(true);
		} else if (e.target.id === "changeCurrentContract") {
			setWantsNewSubscription(false);
			setSelectInputHidden(VisibilityClass.Visible);
			setShowAlertMessage(false);
		} else if (e.target.id === "createNewContract") {
			setWantsNewSubscription(true);
			setSelectInputHidden(VisibilityClass.Hidden);
			setSubscriptionToChange(undefined);
			setShowAlertMessage(true);
		}
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedValue(event.target.value);

		if (activeSubscriptions && activeSubscriptions.length > 0) {
			const newSubscriptionSelectedByUser = getSubscriptionSelectedByUser(
				event,
				activeSubscriptions
			);

			if (newSubscriptionSelectedByUser) {
				setSubscriptionToChange(newSubscriptionSelectedByUser);
				setShowAlertMessage(true);
			}
		}
	};

	useEffect(() => {
		setButtonIsDisabled(true);
		if (wantsNewSubscription || subscriptionToChange) {
			setButtonIsDisabled(false);
		}
	}, [setButtonIsDisabled, wantsNewSubscription, subscriptionToChange]);

	return (
		<ArticleWrapper>
			{customer && !customer.email && (
				<NoEmailHeader>
					<h4>
						För att byta abonnemang på Mitt Vobbiz behöver du
						registrera en mejladress. Det kan du göra{" "}
						<AnchorText
							text="här."
							desiredRedirectPath="/mina-uppgifter"
						/>
						Vill du inte registrera en mejladress,{" "}
						<AnchorText
							text="kontakta Vobbiz support."
							desiredRedirectPath="/kontakta-oss"
						/>
					</h4>
				</NoEmailHeader>
			)}
			{customer && customer.email && (
				<form>
					<AnimatedSecondaryHeading>
						1. Byta eller teckna nytt?
					</AnimatedSecondaryHeading>

					<p className="info">
						Teckna nytt abonnemang eller byta abonnemang? Det gör du
						enkelt här! Börja med att välja om du vill byta ut ett
						befintligt abonnemang eller skapa ett helt nytt.
					</p>

					{isError && (
						<AlertMessage isVisible={isError}>
							Något gick fel när vi försökte hämta abonnemangen,
							testa att ladda om sidan.
						</AlertMessage>
					)}
					{isLoading && <LoadingSpinner />}

					<ButtonContainer>
						<RadioButton
							name="phone-number"
							value="createNewContract"
							id="createNewContract"
							onChange={radioButtonHandler}
						>
							Jag vill teckna ett nytt abonnemang
						</RadioButton>
					</ButtonContainer>

					{activeSubscriptions && activeSubscriptions.length > 0 && (
						<ButtonContainer>
							<RadioButton
								name="phone-number"
								value="changeCurrentContract"
								id="changeCurrentContract"
								onChange={radioButtonHandler}
							>
								Jag vill ändra nuvarande abonnemang
							</RadioButton>
						</ButtonContainer>
					)}
					{activeSubscriptions && activeSubscriptions.length > 1 && (
						<SelectInput
							onChangeHandler={onChangeHandler}
							selectInputHidden={selectInputHidden}
							value={selectedValue}
							inputName="subscription"
						>
							<option value="">
								Vilket abonnemang vill du ändra?
							</option>
							<optgroup label="Aktiva abonnemang">
								{activeSubscriptions.map(subscription => {
									const valueToDisplay = `${
										subscription.contracts[0].product_name
									} ${formatPhoneNumber(
										subscription.phone_numbers[0]
											.phone_number
									)}`;

									return (
										<option
											key={subscription.id}
											value={valueToDisplay}
										>
											{
												subscription.contracts[0]
													.product_name
											}{" "}
											{formatPhoneNumber(
												subscription.phone_numbers[0]
													.phone_number
											)}
										</option>
									);
								})}
							</optgroup>
						</SelectInput>
					)}
					<AlertMessage isVisible={showAlertMessage} isSuccess>
						{subscriptionToChange &&
							`Jag vill ändra följande abonnemang: ${
								subscriptionToChange.contracts[0].product_name
							} ${formatPhoneNumber(
								subscriptionToChange.phone_numbers[0]
									.phone_number
							)}`}
						{wantsNewSubscription &&
							"Jag vill teckna ett nytt abonnemang"}
					</AlertMessage>
				</form>
			)}
		</ArticleWrapper>
	);
};

const ArticleWrapper = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	max-width: 600px;

	.error {
		color: var(--color-error);
	}

	#subscription {
		position: relative;
		top: 0;
		right: -56px;
	}
`;

const NoEmailHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	min-width: 300px;
	max-width: 700px;
	margin: 0 auto;
	h4 {
		margin: 32px 0;
	}
`;

const ButtonContainer = styled.section`
	display: flex;
	align-items: center;
	align-self: flex-start;
	margin: 16px;
	.radio-button {
		margin-right: 1rem;
	}
	#keepCurrentPhoneNumber {
		position: relative;
		top: -16px;
	}
	.validIcon {
		top: 25%;
	}
	.input-container {
		max-width: 75vw;
	}
`;
