import { Product } from "../models/product";
import { Subscription } from "../models/subscription";
import { formatPhoneNumber } from "./formatPhoneNumber";

export const getSubscriptionSelectedByUser = (
	event: { target: HTMLInputElement | HTMLSelectElement },
	subscriptions: Subscription[]
) => {
	const subscriptionSelectedByUser = subscriptions.find(
		subscription =>
			formatPhoneNumber(subscription.phone_numbers[0].phone_number) ===
			event.target.value.split(" ").pop()
	);

	if (!subscriptionSelectedByUser) {
		return null;
	}
	return subscriptionSelectedByUser;
};

export const getProductSelectedByUser = (
	event: { target: HTMLInputElement | HTMLSelectElement },
	products: Product[]
) => {
	const productSelectedByUser = products.find(
		product => product.name === event.target.value
	);
	if (!productSelectedByUser) {
		return null;
	}
	return productSelectedByUser;
};
