import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../styles/Breakpoints";
import { CloseButton } from "../Modal/CloseButton";

const TermsOfServiceModal: React.FC = () => (
	<Container
		data-testid="terms-of-service-modal"
		aria-modal="true"
		aria-label="terms_of_service_dialog"
		role="dialog"
		onClick={e => e.stopPropagation()}
	>
		<CloseButton />
		<h1>Allmänna Villkor</h1>
		<Content>
			<h2>1. Allmänt</h2>
			<ol>
				<li>
					<p>
						Vobbiz AB:s Allmänna villkor stipulerar den eller de av
						Vobbiz enligt Avtalet tillhandahållna tjänsten eller
						tjänsterna till juridisk person. De allmänna villkoren
						utgör en integrerad del av Avtalet.
					</p>
				</li>
				<li>
					<p>
						Detta Avtal gäller även tillämpliga delar då
						leverantören tillhandahåller mobila och/eller fasta
						telefonilösningar då leverantören är en teleoperatör.
					</p>
				</li>
			</ol>
			<h2>2. Definitioner</h2>
			<ol>
				<li>
					<p>
						I Avtalet ska nedan angivna termer ha följande
						betydelse:
					</p>
					<p>
						<strong>Driftsättningsdagen</strong> avser den tidpunkt
						där Leverantören ansluter Tjänsten till ett allmänt
						elektroniskt kommunikationsnät (PSTN).
					</p>
					<p>
						<strong>UCaaS-tjänst</strong> (Tjänsten) avses
						Leverantörens tillhandahållande av programvara som
						Tjänst.
					</p>
					<p>
						<strong>Tilläggstjänst</strong> avser tjänst som ej
						framgår av Uppdragsbeskrivningen.
					</p>
					<p>
						<strong>Tjänsten</strong> avser det uppdrag som
						Leverantören ska utföra enligt detta Avtal.
					</p>
					<p>
						<strong>Tredjepartsapplikation</strong> avser en
						programvara vars upphovsrätt tillkommer annan än
						Leverantören som i Avtalet anges vara
						Tredjepartsapplikation.
					</p>
				</li>
			</ol>
			<h2>3. Tjänsten</h2>
			<ol>
				<li>
					<p>
						Leverantören tillhandahåller en UCaaS-tjänst till Kund.
						Tjänsten är en internetbaserad kommunikationsplattform
						för telefoni, videomöten, chatt, e-post och kalender som
						Kunden hyr licenser till och som kan konfigureras enligt
						Kundens önskemål inom de ramar Tjänsten tillåter.{" "}
					</p>
				</li>
			</ol>

			<h2>4. Driftsättning</h2>
			<ol>
				<li>
					<p>
						Driftsättningsdagen är den dag då Tjänsten tas i bruk
						(”Driftsättningsdagen”).
					</p>
				</li>
				<li>
					<p>
						Leverantören ska leverera de inloggningsuppgifter som
						behövs hos Kunden för driftsättningen av Tjänsten. I god
						tid före Driftsättningsdagen, dock senast två (2) dagar
						dessförinnan, ska Kunden erhålla dessa
						inloggningsuppgifter.
					</p>
				</li>
			</ol>
			<h2>5. Kundens ansvar och åtaganden</h2>
			<ol>
				<li>
					<p>
						Kunden ansvarar för att inloggningsuppgifter, lösenord,
						identiteter, koder eller liknande hanteras på ett säkert
						sätt och i enlighet med gällande dataskyddsregler så att
						obehöriga ej kan komma åt dessa.
					</p>
				</li>
				<li>
					<p>
						Kunden ska tillhandahålla Leverantören all information
						(”Kunddata”) som är nödvändig för att kunna driftsätta
						växellösningen, vilken användare som har respektive
						telefonnummer, vilket telefonnummer som ska vara
						huvudnummer samt hur samtal ska distribueras.
					</p>
				</li>
				<li>
					<p>
						Parterna åtar sig att gemensamt arbeta för att leverans
						och driftsättning ej tar längre tid än nödvändigt. När
						Kunden till Leverantören eller Återförsäljaren inkommit
						med komplett Kunddata skall Parterna gemensamt bestämma
						ett preliminärt datum för Driftsättning.
					</p>
				</li>
				<li>
					<p>
						Om Kunden ej inkommer med komplett Kunddata till
						Leverantören inom skälig tid trots påminnelser från
						Leverantören kommer Leverantören att slutföra leverans
						efter bästa förmåga, om detta sker åtar sig Kunden att
						på egen hand lägga in användare och programmera växeln
						enligt önskemål. Leverantören har då även rätt att
						aktivera Avtalet för fakturering.
					</p>
				</li>
				<li>
					<p>
						Kunden och Leverantören åtar sig att skyndsamt vidtaga
						alla åtgärder som är nödvändiga för att den planerade
						Driftsättningsdagen inte ska försenas.
					</p>
				</li>
				<li>
					<p>
						Kunden ansvarar för att befintlig infrastruktur är av
						sådan kvalitet att funktionens kvalitet kan säkerställas
						och inga avbrott uppkommer. Krävs förändringar i LAN,
						WAN, Switch, Router, Brandvägg, Internet-accesser etc.
						bekostas detta av Kunden.
					</p>
					<p>
						Leverantören tillhandahåller via sin hemsida en
						förteckning över krav på nätverk, enheter och
						operativsystem etc som krävs för att UCaaS-tjänsten ska
						fungera tillfredsställande.
					</p>
					<p>
						Leverantören ansvarar endast för vad som är möjligt med
						de licenser, utrustning och teknik som är köpt från
						Leverantören och som installeras vid överenskommet datum
						för Driftsättning. Kontrollskyldighet och återkoppling
						åligger Kund.
					</p>
					<p>
						Kunden ansvarar för att tillhandahålla kompetens samt
						utföra konfigurationsändringar i Kundens nät/brandväggar
						som följer Leverantörens rekommendationer för full
						funktion. Kunden ansvarar för utplaceringen av telefoner
						och installation av Appar om inget annat avtalats.
					</p>
				</li>
			</ol>
			<h2>6. Betalning</h2>
			<ol>
				<li>
					<h3>Rörliga kontra fasta avgifter</h3>
					<p>
						Fasta avgifter som är avtalade avser abonnemangs- och
						licensavgifter. Rörliga avgifter avser exempelvis
						trafikavgifter som ligger utanför licensavgiftens
						innehåll. Utlandssamtal, extra köp av data eller
						betalsamtal är sådana exempel.
					</p>
				</li>
				<li>
					<h3>Betalnings/debiteringsintervall</h3>
					<p>
						Abonnemangsavgifter och licensavgifter debiteras
						månadsvis och i förskott med utgångspunkt från avtalets
						driftdag alternativt när tjänsten aktiveras. Rörliga
						avgifter debiteras månadsvis och med en månads
						efterskott.
					</p>
				</li>
				<li>
					<h3>Engångsavgifter</h3>
					<p>Debiteras en månad efter driftdag</p>
				</li>
				<li>
					<p>
						Faktureringsavgift tillkommer med 49 kr exkl. moms per
						faktura. Vid E-faktura eller PDF-faktura via e-post
						utgår ej någon avgift.
					</p>
				</li>
				<li>
					<p>
						Vid försenad betalning har Leverantören rätt till
						dröjsmålsränta samt påminnelseavgifter enligt
						räntelagen.
					</p>
				</li>
				<li>
					<p>
						Vid utebliven betalning har Leverantören rätt att stänga
						av Tjänsten till dess att full betalning kommit
						Leverantören tillhanda.
					</p>
				</li>
				<li>
					<p>
						Nyttjas Tjänst på otillbörligt sätt äger Vobbiz rätt att
						fakturera Kund en tilläggsavgift utöver gällande
						prislista.
					</p>
				</li>
				<li>
					<p>Alla avgifter anges exklusive mervärdesskatt.</p>
				</li>
			</ol>
			<h2>7. Tilläggsbeställning</h2>
			<ol>
				<li>
					<p>
						Tilläggsbeställningar utöver vad som ingår i Bilaga 1
						kan beställas av Kunden löpande.
					</p>
				</li>
				<li>
					<p>
						Debitering av avgifter startar då eventuell licens eller
						produkt aktiverats i Tjänsten.
					</p>
				</li>
			</ol>
			<h2>8. Avtalstid</h2>
			<ol>
				<li>
					<p>
						Avtalstiden anges i Avtalet och avser en period om 0
						månader, 12 månader, 24 månader eller 36 månader med en
						löptid från och med angiven driftdag.
					</p>
				</li>
				<li>
					<p>
						Avtalstiden för Licenser och Tilläggstjänster som Kunden
						beställer under pågående avtalstid är noll (0) månader
						och kan sägas upp med en (1) månaders uppsägningstid
						enligt ovan.
					</p>
				</li>
			</ol>
			<h2>9. Roaming</h2>
			<ol>
				<li>
					<p>
						Roaming inom EU utgår från motsvarande inhemska priser.
						Enligt EU:s reglering No 531/2012 skall volymen
						roamingtrafik motsvara skäligt bruk. Användarens
						datamängd i inhemskt datapaket begränsar volymen
						dataroaming.
					</p>
				</li>
				<li>
					<p>
						Vid missbruk av roaming förbehåller sig Vobbiz AB rätten
						att fakturera ett pålägg för förbrukad roaming i
						enlighet med EU reglering No 531/2012.
					</p>
				</li>
			</ol>
			<h2>10. Support</h2>
			<ol>
				<li>
					<p>
						Leverantörens support nås på telefon samt e-post
						support@vobbiz.se. Felanmälningar tas emot helgfria
						vardagar kl. 08.00-17.00. Leverantören åtar sig att
						påbörja hanteringen av felanmälan inom fyra (4)
						arbetstimmar, så länge felanmälan inkommer till
						Leverantören inom ovan angiven tidsram.
					</p>
				</li>
			</ol>
			<h2>11. Driftsäkerhet</h2>
			<ol>
				<li>
					<p>
						Leverantören ombesörjer att lösningen är driftsatt och
						tillgänglig 99,8% av tiden på kalenderkvartalsbasis.
						Detta gäller systemomfattande fel och beräknas på
						systemnivå och inkluderar ej mobila användare då
						tillgängligheten där till stor del styrs av faktorer som
						ej är påverkbara av Leverantören, exempelvis mobilnätets
						radiotäckning eller belastning i mobilnätet. Fel som
						Leverantören klassar som icke kritiska för användning av
						telefonitjänster i UCaaS-tjänsten exkluderas vid
						beräkning av tillgänglighet. Enskilda avbrott i
						tjänsterna för t.ex. chatt, e-post, kalender och
						konferenssamtal räknas ej som systemomfattande fel.
					</p>
				</li>
			</ol>
			<h2>Underhåll och leverantörens ansvar</h2>
			<ol>
				<li>
					<p>
						Leverantören har rätt att utföra planerat underhåll
						enligt avtalspunkt 6.8. Leverantörens ansvar vid
						driftavbrott och trafikhindrande fel gäller inte till
						den del av avbrottet / felet som kan härledas till
						Kunden, fastighetsnät, operatör eller annat som står
						utanför Leverantörens kontroll.
					</p>
				</li>
				<li>
					<p>
						I händelse av att Leverantören ej lever upp till
						tillgänglighetsnivå enligt punkt 7.5 kan Kunden ansöka
						om ersättning via Leverantörens support. Ansökan om
						ersättning måste göras senast 10 arbetsdagar efter
						kvartalets slut. Ersättning för godkänd ansökan utges
						med 10% av månadsavgiften för UCaaS-tjänsten (övriga
						avgifter räknas ej) per 0,2% i missad tillgänglighet med
						en maximal ersättning för ett kalenderkvartal på 50% av
						Kundens månadskostnad för UCaaS-tjänsten. En
						förutsättning för att ansökan ska godkännas är att
						kunden gjort en felanmälan under den tid problemet
						varade. Ersättning hanteras genom avdrag på nästkommande
						faktura.
					</p>
				</li>
				<li>
					<p>
						Leverantören har rätt att utföra planerat underhåll i
						Tjänsten och dess tillhörande tjänster från olika
						underleverantörer och telefonoperatörer. Leverantören
						skall informera Kunden i förväg om eventuella
						underhållsarbeten förväntas påverka Kunden i mer än fem
						minuter samt i möjligaste mån tillse att planerat
						underhåll utförs under Leverantörens servicefönster
						angivet under avtalspunkt 6.9. Planerat underhåll skall
						ej ses som avbrott i tjänsten. Leverantören skall
						meddela Kunden senast fem (5) dagar innan ett planerat
						underhåll. Meddelande skickas ut till kontoägare och
						administratörer.
					</p>
				</li>
				<li>
					<p>
						Servicefönster för planerat underhåll är helgfria
						vardagar kl 21 till 06 samt helgdagar. Planerat
						underhåll skall i möjligaste mån hållas till dessa tider
						och allra helst nattetid.
					</p>
				</li>
			</ol>
			<h2>13. Uppsägning</h2>
			<ol>
				<li>
					<p>
						Avtalet skall sägas upp senast 1 månader före sista
						avtalsdag enligt ursprunglig avtalsperiod. Avtalet löper
						tillsvidare efter ursprunglig avtalsperiod och med 1
						månaders uppsägningstid. Avtal som löper tillsvidare
						sägs upp till slutet av innevarande kalendermånad. Sägs
						Avtalet upp under innevarande Avtalsperiod äger Vobbiz
						AB rätten att slutdebitera Kund för kvarvarande
						Avtalsperiod i enlighet med gällande Avtal.
					</p>
				</li>
				<li>
					<p>Avtalet skall sägas upp skriftligen</p>
				</li>
			</ol>
			<h2>14. Tillämplig lag och tvistelösning</h2>
			<ol>
				<li>
					<p>
						Parternas rättigheter respektive skyldigheter vid
						tolkning och tillämpning av Avtalet ska beslutas i
						enlighet med svensk lag.
					</p>
				</li>
				<li>
					<p>
						Tvister som uppstår i anledning av Avtalet ska slutligt
						avgöras vid svensk domstol varav tingsrätten i Göteborg
						skall vara första instans.
					</p>
				</li>
			</ol>
		</Content>
	</Container>
);

const Container = styled.section`
	width: 1000px;
	max-width: 90vw;
	height: 100%;
	max-height: 80vh;
	background-color: var(--color-whiteish);
	padding: 2rem;
	border-radius: 0.5rem;
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
	margin-inline: 0.5rem;
	position: relative;
	overflow-y: scroll;

	& h1 {
		font-size: var(--font-size-xl);
	}

	& > button {
		position: fixed;
		top: 1rem;
		right: 1rem;
		color: var(--color-gray);
		background-color: var(--color-whiteish);

		:hover,
		:focus {
			&::after {
				border: 2px solid var(--color-teal);
			}
		}
	}
`;

const Content = styled.div`
	& h2 {
		margin-block: 2rem 1rem;
		font-size: var(--font-size-l);
	}

	& h3 {
		margin-bottom: 0.5rem;
	}

	& p {
		margin-bottom: 1rem;
	}

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background: var(--color-whiteish);
	}

	::-webkit-scrollbar-thumb {
		background: var(--color-gray);
		border-radius: 5px;
	}

	@media (min-width: ${Breakpoints.TabletPortrait}) {
		max-height: 92%;
	}
`;

export default TermsOfServiceModal;
