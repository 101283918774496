import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper } from "../../components/PageWrapper";
import { BankIdErrorResponse } from "../../models/bankIdModels";
import {
	getTokenFromSessionStorage,
	saveTokenToSessionStorage,
} from "../../services/sessionStorageServices";
import {
	authenticate,
	cancel,
	handlePendingLoginOnSameDevice,
	handlePendingLoginWithQr,
} from "../../utils/fetchBankId";
import waves from "../../assets/images/waves-double-purple.svg";
import MakeChoiceView from "./MakeChoiceView";
import QrCodeView from "./QrCodeView";
import SameDeviceView from "./SameDeviceView";
import { verifyToken } from "../../services/authService";
import { useResetStoreState } from "../../hooks/useResetStoreState";
import { BecomeCustomer } from "../../components/BecomeCustomer";

let controller = new AbortController();

enum View {
	MakeChoice = "makeChoice",
	QrCode = "qrCode",
	SameDevice = "sameDevice",
}

export const LoginPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [currentView, setCurrentView] = useState<View>(View.MakeChoice);
	const [qrImage, setQrImage] = useState<string>("");
	const [responseMessage, setResponseMessage] = useState("");
	const [orderRef, setOrderRef] = useState("");
	const [userHasCancelled, setUserHasCancelled] = useState(false);
	const navigate = useNavigate();
	const resetStoreState = useResetStoreState();

	useEffect(() => {
		checkIfUserIsLoggedIn();
	});

	useEffect(() => {
		document.title = "Mitt Vobbiz - Logga in";
	}, []);

	const checkIfUserIsLoggedIn = async () => {
		const token = getTokenFromSessionStorage();
		if (token) {
			const verifiedToken = await verifyToken(token);
			if (verifiedToken) {
				handleSuccessfulLogin(verifiedToken);
			}
		}
	};

	// Aborts any ongoing fetch requests if user has cancelled the login process
	useEffect(() => {
		if (userHasCancelled) {
			controller.abort();
			setCurrentView(View.MakeChoice);
		}
	}, [userHasCancelled]);

	// Resets the data for a new login attempt
	const resetDataForNewRequest = () => {
		setIsLoading(false);
		setResponseMessage("");
		setUserHasCancelled(false);
		setOrderRef("");
		setQrImage("");
		controller = new AbortController();
	};

	// Save token in session storage and redirect user to home page if login was successful
	const handleSuccessfulLogin = (token: string) => {
		console.log("Login successful!!! 🥳");
		saveTokenToSessionStorage(token);
		resetStoreState();
		navigate("/oversikt");
	};

	// Display error message to user and abort any further requests if required to do so
	const handleError = (err: BankIdErrorResponse) => {
		setResponseMessage(err.message);
		if (err.abortFurtherRequests) setUserHasCancelled(true);
		setIsLoading(false);
	};

	const handleLoginWithQr = async () => {
		setCurrentView(View.QrCode);
		try {
			resetDataForNewRequest();
			setResponseMessage("Starta BankID-appen");
			const order = await authenticate();
			setOrderRef(order.orderRef);
			const response = await handlePendingLoginWithQr({
				qrStartToken: order.qrStartToken,
				orderRef: order.orderRef,
				signal: controller.signal,
				setResponseMessage,
				setQrImage,
			});
			handleSuccessfulLogin(response.token);
		} catch (err) {
			if (err instanceof BankIdErrorResponse) handleError(err);
		}
	};

	const handleLoginOnSameDevice = async () => {
		try {
			setCurrentView(View.SameDevice);
			resetDataForNewRequest();
			const order = await authenticate();
			setOrderRef(order.orderRef);
			const { autoStartToken } = order;

			const autoStartUrl = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
			window.location.href = autoStartUrl;

			setIsLoading(true);
			const response = await handlePendingLoginOnSameDevice({
				orderRef: order.orderRef,
				signal: controller.signal,
				setResponseMessage,
				autostart: true,
			});
			handleSuccessfulLogin(response.token);
		} catch (err) {
			if (err instanceof BankIdErrorResponse) handleError(err);
		}
	};

	// Cancels the ongoing login process
	const handleCancel = async () => {
		setCurrentView(View.MakeChoice);
		try {
			await cancel(orderRef);
			setUserHasCancelled(true);
			setResponseMessage("Åtgärden avbruten.");
		} catch (err) {
			if (err instanceof BankIdErrorResponse) handleError(err);
		}
	};

	const renderView = () => {
		switch (currentView) {
			case View.MakeChoice:
				return (
					<MakeChoiceView
						handleLoginOnSameDevice={handleLoginOnSameDevice}
						handleLoginWithQr={handleLoginWithQr}
						responseMessage={responseMessage}
					/>
				);
			case View.QrCode:
				return (
					<QrCodeView
						responseMessage={responseMessage}
						qrImage={qrImage}
						handleCancel={handleCancel}
					/>
				);
			case View.SameDevice:
				return (
					<SameDeviceView
						handleCancel={handleCancel}
						responseMessage={responseMessage}
						isLoading={isLoading}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<PageWrapper secondary hideMenu hideFooter>
			<Container>
				<LoginWrapper background={waves}>
					<Heading>Logga in 👏</Heading>
					{renderView()}
				</LoginWrapper>
				<BecomeCustomer />
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin-top: -32px;

	& button {
		margin-block: 8px;
	}
`;

interface LoginWrapperProps {
	background: string;
}

const LoginWrapper = styled.div<LoginWrapperProps>`
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
	background-color: white;
	border-radius: 1.5rem;
	padding: 2rem;
	width: 100%;
	max-width: 400px;
	margin-inline: auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	position: relative;
	overflow: hidden;
	background-image: url(${props => props.background});
	background-repeat: no-repeat;
`;

const Heading = styled.h1`
	margin-top: 1rem;
	margin-bottom: 3rem;
	font-size: var(--font-size-xl);
	color: var(--color-whiteish);
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	transform: rotate(-2deg);
	position: relative;

	&::before {
		content: "Mitt Vobbiz";
		font-size: var(--font-size-base);
		position: absolute;
		top: -12px;
		left: 2px;
		text-transform: uppercase;
		font-weight: normal;
		letter-spacing: 0.5px;
	}
`;
