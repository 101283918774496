import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { Modal } from "../../models/modal";
import {
	selectModalState,
	setActiveModal,
	toggleModal,
} from "../../store/slices/modalSlice";

interface Props {
	children: React.ReactNode;
	modal: Modal;
}

const ToggleModalLink: React.FC<Props> = ({ children, modal }: Props) => {
	const dispatch = useAppDispatch();
	const modalIsActive = useAppSelector(selectModalState);

	const clickHandler = () => {
		dispatch(setActiveModal(modal));
		dispatch(toggleModal());
	};

	return (
		<Button
			tabIndex={0}
			aria-pressed={modalIsActive}
			onClick={clickHandler}
		>
			{children}
		</Button>
	);
};

const Button = styled.button`
	padding: 4px 6px 2px 6px;
	margin: 0;
	border: none;
	background: transparent;
	outline: none;
	cursor: pointer;
	font: inherit;
	font-weight: bold;
	color: var(--color-gray);
	transition: all 0.3s;
	width: fit-content;
	margin-inline: auto;
	border-radius: 8px;
	line-height: 1;

	&:hover {
		color: var(--color-teal);
	}

	&:focus,
	&:active {
		outline: 3px solid var(--color-teal);
		outline-offset: 2px;
	}
`;

export default ToggleModalLink;
