import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { useAppSelector } from "../../hooks/hooks";
import { toggleAriaCurtain } from "../../utils/toggleAriaCurtain";
import { Modal } from "./Modal";
import { Modal as IModal } from "../../models/modal";
import { CustomerSupportModal } from "../CustomerSupportModal";
import {
	selectActiveModal,
	selectModalState,
	selectScrollPosition,
} from "../../store/slices/modalSlice";
import PrivacyPolicyModal from "../PrivacyPolicyModal/PrivacyPolicyModal";
import TermsOfServiceModal from "../TermsOfServiceModal/TermsOfServiceModal";
import { SurfAmountCustomerGuide } from "../SurfAmountCustomerGuide";

export const ModalPortal: React.FC = () => {
	const modalIsActive = useAppSelector(selectModalState);
	const activeModal = useAppSelector(selectActiveModal);
	const modalRoot = document.querySelector("#modal-root") as HTMLElement;
	const scrollPosition = useAppSelector(selectScrollPosition);

	useEffect(() => {
		toggleAriaCurtain(modalIsActive);
	}, [modalIsActive]);

	useEffect(() => {
		// Turn off scroll in the document if a modal is active
		if (modalIsActive) document.body.className = "hasDialog";
		if (!modalIsActive) {
			document.body.className = "";
		}
		window.scrollTo(0, scrollPosition);
	}, [modalIsActive, scrollPosition]);

	const renderActiveModal = () => {
		switch (activeModal) {
			case IModal.CustomerSupport:
				return <CustomerSupportModal />;
			case IModal.PrivacyPolicy:
				return <PrivacyPolicyModal />;
			case IModal.TermsOfService:
				return <TermsOfServiceModal />;
			case IModal.SurfGuide:
				return <SurfAmountCustomerGuide />;
			default:
				return null;
		}
	};

	if (modalIsActive) {
		return createPortal(
			<FocusLock>
				<Modal>{renderActiveModal()}</Modal>
			</FocusLock>,
			modalRoot
		);
	}

	return null;
};
