import styled from "styled-components";
import Confetti from "react-confetti";
import { Link, useNavigate } from "react-router-dom";
import { PageWrapper } from "../../components/PageWrapper";
import useWindowSize from "../../hooks/useWindowSize";
import illustration from "../../assets/images/celebration.svg";
import { Button } from "../../components/Button";
import { Breakpoints } from "../../styles/Breakpoints";

export const NewCustomerPage: React.FC = () => {
	const { width, height } = useWindowSize();
	const navigate = useNavigate();

	const colors = ["#C5ADED", "#F8ABEB", "#FFC8DD", "#65B3BF", "#99C7D7"];

	const redirectToMittVobbiz = () => {
		navigate("/logga-in");
	};

	return (
		<PageWrapper hideMenu hideFooter>
			<Container>
				<Heading>Grattis! 🥳 🎉</Heading>
				<SubHeading>Du har nu blivit kund hos Vobbiz!</SubHeading>
				<Illustration src={illustration} />
				<ConfettiContainer>
					<Confetti
						width={width}
						height={height}
						colors={colors}
						numberOfPieces={100}
					/>
				</ConfettiContainer>
				<p>
					Du kan nu logga in på Mitt Vobbiz för mer information om
					status på ditt abonnemang.
				</p>
				<Button onClick={redirectToMittVobbiz}>
					Klicka här för att gå till Mitt Vobbiz
				</Button>
				<Link to="/">Tillbaka till startsidan</Link>
			</Container>
		</PageWrapper>
	);
};

const Container = styled.div`
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	text-align: center;

	@media (min-width: ${Breakpoints.TabletLandscape}) {
	}

	& button,
	a,
	p,
	h1 {
		z-index: 2;
	}

	& a {
		color: var(--color-purple);
		text-decoration: none;
		font-weight: 700;
		transition: all 0.2s;
		border-bottom: 2px solid transparent;

		&:hover {
			border-bottom: 2px solid var(--color-purple);
		}
	}
`;

const ConfettiContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`;

const Heading = styled.h1`
	font-size: var(--font-size-xxl);
	font-weight: 800;
	margin-bottom: -16px;
	color: var(--color-pink);
	text-shadow: 0 3px 3px hsla(311, 62%, 26%, 0.25);
	transform: rotate(-1deg);
`;

const SubHeading = styled.h2`
	font-size: var(--font-size-l);
	color: var(--color-gray);
`;

const Illustration = styled.img`
	width: 100%;
	max-width: 500px;
	height: auto;
`;
