import { useDispatch } from "react-redux";
import { customerSlice } from "../store/slices/customerSlice";
import { productSlice } from "../store/slices/productSlice";
import { subscriptionSlice } from "../store/slices/subscriptionSlice";
import { surfSlice } from "../store/slices/surfSlice";

export const useResetStoreState = () => {
	const dispatch = useDispatch();

	const resetStoreState = () => {
		dispatch(customerSlice.util.resetApiState());
		dispatch(productSlice.util.resetApiState());
		dispatch(subscriptionSlice.util.resetApiState());
		dispatch(surfSlice.util.resetApiState());
	};

	return resetStoreState;
};
