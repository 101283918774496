import { useEffect } from "react";
import styled from "styled-components";
import ToggleModalLink from "../../../components/Modal/ToggleModalLink";
import { Modal } from "../../../models/modal";
import { DisplayProductsCard } from "../../../components/DisplayProductsCard";
import { Product } from "../../../models/product";
import { AnimatedSecondaryHeading } from "../../../components/AnimatedSecondaryHeading/AnimatedSecondaryHeading";

interface Props {
	setButtonIsDisabled: (buttonIsDisabled: boolean) => void;
	selectedProduct: Product | undefined;
	setSelectedProduct: (product: Product) => void;
}

export const Step2: React.FC<Props> = (props: Props) => {
	const { setButtonIsDisabled, selectedProduct, setSelectedProduct } = props;

	useEffect(() => {
		setButtonIsDisabled(true);
		if (selectedProduct) {
			setButtonIsDisabled(false);
		}
	}, [selectedProduct, setButtonIsDisabled]);

	return (
		<ArticleWrapper>
			<AnimatedSecondaryHeading>
				2. Välj abonnemang
			</AnimatedSecondaryHeading>
			<p>
				Nu är det dags att välja vilket abonnemang du vill byta till. Du
				kan få extra hjälp av våran surfgudie för att skapa en överblick
				över vilka produkter vi erbjuder.
			</p>
			<ToggleModalLink modal={Modal.SurfGuide}>
				Öppna Surfguiden!
			</ToggleModalLink>
			<DisplayProductsCard
				setSelectedProduct={setSelectedProduct}
				selectedProduct={selectedProduct}
			/>
		</ArticleWrapper>
	);
};

const ArticleWrapper = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	max-width: 600px;

	p,
	ul {
		margin: 16px;
	}
`;
