import { useEffect } from "react";
import styled from "styled-components";
import { Phone } from "react-feather";
import { CircledIcon } from "../../components/CircledIcon";
import { CustomerProductCard } from "../../components/CustomerProductCard";
import { PageWrapper } from "../../components/PageWrapper";
import { useGetSubscriptionsQuery } from "../../store/slices/subscriptionSlice";
import { LoadingSpinner } from "../../components/LoadingSpinner";

export function ProductsPage() {
	const {
		data: activeSubscriptions,
		isLoading,
		isSuccess,
		isError,
	} = useGetSubscriptionsQuery();

	useEffect(() => {
		document.title = "Mitt Vobbiz - Mina abonnemang";
	}, []);

	return (
		<PageWrapper secondary>
			<ProductsPageWrapper>
				<CircledIcon secondary iconName="Phone">
					<Phone />
				</CircledIcon>
				<h1>Nuvarande abonnemang</h1>
				<p>
					Här kan du se information om nuvarande abonnemang. När du
					klickar på ett kort visas mer detaljerad information.
				</p>
				{isSuccess && (
					<CustomerProductCard
						activeSubscriptions={activeSubscriptions}
					/>
				)}
				{isLoading && <LoadingSpinner />}
				{isError && (
					<h3 className="error">
						Något gick fel när vi försökte hämta abonnemangen, testa
						att ladda om sidan.
					</h3>
				)}
			</ProductsPageWrapper>
		</PageWrapper>
	);
}

const ProductsPageWrapper = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	text-align: center;

	p {
		margin: 24px 8px 32px 8px;
		max-width: 460px;
	}

	.error {
		color: var(--color-error);
	}
`;
