import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./routes/HomePage";
import { ProductsPage } from "./routes/mina-abonnemang/ProductsPage";
import { NewCustomerPage } from "./routes/ny-kund/NewCustomerPage";
import OrderPage from "./routes/bli-kund/OrderPage";
import { ModalPortal } from "./components/Modal/ModalPortal";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import ToggleCustomerSupportBtn from "./components/CustomerSupportModal/ToggleCustomerSupportBtn";
import { ChangeProductsPage } from "./routes/byta-abonnemang/ChangeProductsPage";
import CustomerPersonalDetailsPage from "./routes/mina-uppgifter/CustomerPersonalDetailsPage";
import { ContactUsPage } from "./routes/kontakta-oss/ContactUsPage";
import { VoiceMailPage } from "./routes/rostbrevlada/VoiceMailPage";
import { NewSimCardPage } from "./routes/nytt-sim/NewSimCardPage";
import { OverviewPage } from "./routes/oversikt/OverviewPage";
import { PaymentPage } from "./routes/betalningar/PaymentPage";
import { PinPukPage } from "./routes/pin-puk/PinPukPage";
import { SurfPage } from "./routes/surf/SurfPage";
import { FourOFourPage } from "./routes/FourOFourPage";
import { LoginPage } from "./routes/logga-in/LoginPage";
import { LoginPageDev } from "./routes/logga-in-dev/LoginPageDev";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route
					path="/oversikt"
					element={
						<ProtectedRoute>
							<OverviewPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mina-uppgifter"
					element={
						<ProtectedRoute>
							<CustomerPersonalDetailsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/kontakta-oss"
					element={
						<ProtectedRoute>
							<ContactUsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mina-abonnemang"
					element={
						<ProtectedRoute>
							<ProductsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/andra-abonnemang"
					element={
						<ProtectedRoute>
							<ChangeProductsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/rostbrevlada"
					element={
						<ProtectedRoute>
							<VoiceMailPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/nytt-sim"
					element={
						<ProtectedRoute>
							<NewSimCardPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/betalningar"
					element={
						<ProtectedRoute>
							<PaymentPage />
						</ProtectedRoute>
					}
				/>
				<Route path="/logga-in" element={<LoginPage />} />
				<Route path="/logga-in-dev" element={<LoginPageDev />} />
				<Route path="/ny-kund" element={<NewCustomerPage />} />
				<Route
					path="/pin-puk"
					element={
						<ProtectedRoute>
							<PinPukPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/surf"
					element={
						<ProtectedRoute>
							<SurfPage />
						</ProtectedRoute>
					}
				/>
				<Route path="/bli-kund" element={<OrderPage />} />
				{/* <Route path="/components" element={<ComponentsPage />} /> */}
				{/* Create a 404 route */}
				<Route path="*" element={<FourOFourPage />} />
			</Routes>
			<ModalPortal />
			<ToggleCustomerSupportBtn />
		</BrowserRouter>
	);
}

export default App;
