import { useState, MouseEventHandler, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Product } from "../../models/product";
import { useFetchProductsQuery } from "../../store/slices/productSlice";
import { Wizard } from "../../components/Wizard";
import { PageWrapper } from "../../components/PageWrapper";
import Step1 from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { Step4 } from "./steps/Step4";
import { Step5 } from "./steps/Step5";
import { Button } from "../../components/Button";
import { CustomerToBeCreated } from "../../models/customer";
import { createNewCustomer } from "../../services/customerServices";
import { ContractToBeCreated } from "../../models/contract";
import { AlertMessage } from "../../components/AlertMessage";
import { ClientErrorResponse } from "../../models/clientErrorResponse";
import { LoadingSpinner } from "../../components/LoadingSpinner";

const totalSteps = 5;

function OrderPage() {
	const navigate = useNavigate();
	const {
		data: products = [],
		isError,
		isLoading,
		error,
	} = useFetchProductsQuery();
	const [currentStep, setCurrentStep] = useState(1);
	const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
	const [keepPhoneNumber, setKeepPhoneNumber] = useState("");
	const [changePhoneNumber, setChangePhoneNumber] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertMessageIsVisible, setAlertMessageIsVisible] =
		useState<boolean>(false);
	const [params] = useSearchParams();
	const [selectedSubscription, setSelectedSubscription] = useState<
		Product | undefined
	>();
	const [customer, setCustomer] = useState<CustomerToBeCreated>({
		identity_number: "",
		email: "",
		first_name: "",
		name: "",
		address: "",
		postal_code: "",
		city: "",
		contact_phone: "",
	});
	const [discountCode, setDiscountCode] = useState("");

	useEffect(() => {
		document.title = "Vobbiz Privat - Bli kund";
	}, []);

	useEffect(() => {
		const productParams = params.get("product");
		if (productParams !== null) {
			const chosenSubscription = products.find(
				product => product.id === Number(productParams)
			);

			if (chosenSubscription) {
				setSelectedSubscription(chosenSubscription);
			}
		} else {
			setSelectedSubscription(products[0] ?? null);
		}
	}, [products, params]);

	useEffect(() => {
		if (error && "data" in error) {
			setAlertMessage(
				error.data.message
					? error.data.message
					: "Något gick fel när vi försökte hämta produkterna."
			);
			setAlertMessageIsVisible(true);
		}
	}, [error]);

	const onClickHandler: MouseEventHandler<HTMLButtonElement> = async () => {
		if (!customer || !selectedSubscription) {
			return;
		}

		// Format data to send to server
		customer.postal_code = customer.postal_code.replace(/ /g, "");
		customer.identity_number = customer.identity_number
			.replace(/[^\d]/g, "")
			.replace(/(.{8})/g, "$1-");
		customer.contact_phone = keepPhoneNumber.replace("0", "+46");

		const contract: ContractToBeCreated = {
			product_name: selectedSubscription.name,
		};

		try {
			const createdCustomer = await createNewCustomer(
				customer,
				contract,
				changePhoneNumber,
				discountCode
			);

			if (createdCustomer) {
				navigate("/ny-kund");
			} else {
				throw new Error();
			}
		} catch (err) {
			setAlertMessage(
				err instanceof ClientErrorResponse
					? err.message
					: "Något gick fel, försök igen."
			);
			setAlertMessageIsVisible(true);
		}
	};

	if (isLoading)
		return (
			<PageWrapper hideMenu>
				<Container>
					<LoadingSpinner />
				</Container>
			</PageWrapper>
		);

	if (isError)
		return (
			<PageWrapper hideMenu>
				<Container>
					<AlertMessage isVisible={alertMessageIsVisible}>
						{alertMessage}
					</AlertMessage>
				</Container>
			</PageWrapper>
		);

	return (
		<PageWrapper hideMenu>
			<WizardContainer>
				<Wizard currentStep={currentStep} totalSteps={totalSteps} />
			</WizardContainer>
			<MainContainer>
				{currentStep === 1 && (
					<Step1
						selectedSubscription={selectedSubscription}
						setSelectedSubscription={setSelectedSubscription}
						products={products}
						isError={isError}
						setButtonIsDisabled={setButtonIsDisabled}
					/>
				)}
				{currentStep === 2 && (
					<Step2
						setButtonIsDisabled={setButtonIsDisabled}
						keepPhoneNumber={keepPhoneNumber}
						setKeepPhoneNumber={setKeepPhoneNumber}
						changePhoneNumber={changePhoneNumber}
						setChangePhoneNumber={setChangePhoneNumber}
					/>
				)}
				{currentStep === 3 && (
					<Step3
						setButtonIsDisabled={setButtonIsDisabled}
						customer={customer}
						setCustomer={setCustomer}
					/>
				)}
				{currentStep === 4 && (
					<Step4 setButtonIsDisabled={setButtonIsDisabled} />
				)}
				{currentStep === 5 && (
					<Step5
						keepPhoneNumber={keepPhoneNumber}
						customer={customer}
						selectedSubscription={selectedSubscription}
						setButtonIsDisabled={setButtonIsDisabled}
						discountCode={discountCode}
						setDiscountCode={setDiscountCode}
					/>
				)}
			</MainContainer>
			<ButtonsContainer>
				<Button
					ghost
					onClick={() => {
						setCurrentStep(currentStep - 1);
						setAlertMessageIsVisible(false);
					}}
					disabled={currentStep === 1}
				>
					Tillbaka
				</Button>
				{currentStep === 5 ? (
					<Button
						disabled={buttonIsDisabled}
						onClick={onClickHandler}
					>
						Bli kund
					</Button>
				) : (
					<Button
						disabled={
							currentStep === totalSteps || buttonIsDisabled
						}
						onClick={() => {
							setCurrentStep(currentStep + 1);
							window.scrollTo(0, 0);
						}}
					>
						Nästa
					</Button>
				)}
			</ButtonsContainer>
			<AlertMessage isVisible={alertMessageIsVisible}>
				{alertMessage}
			</AlertMessage>
		</PageWrapper>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	min-height: 100%;
`;

const WizardContainer = styled.section`
	margin-top: 2rem;
`;

const MainContainer = styled.section`
	width: fit-content;
	min-height: 100%;
	margin-block: 2rem;
	margin-inline: auto;
`;

const ButtonsContainer = styled.section`
	width: 100%;
	max-width: 250px;
	margin-inline: auto;
	display: flex;
	justify-content: space-between;
`;

export default OrderPage;
