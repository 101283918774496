import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

interface Props {
	children: React.ReactNode;
}

/**
 * @description A secondary heading with a slide-in animation
 * @param children The content of the heading
 */

export const AnimatedSecondaryHeading = (props: Props) => {
	const { children } = props;
	return (
		<Heading
			initial={{ x: 200, opacity: 0 }}
			animate={{ x: 0, opacity: 1 }}
			transition={{ duration: 0.75 }}
		>
			{children}
		</Heading>
	);
};

const Heading = styled(motion.h2)`
	color: var(--color-gray);
	text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.06);
	font-size: var(--font-size-l);
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 1px;
`;
