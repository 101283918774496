// noinspection UnnecessaryLocalVariableJS

import {
	BankIdAuthResponse,
	BankIdCancelResponse,
	BankIdErrorResponse,
	BankIdPendingCollectResponse,
	BankIdQrResponse,
	BankIdSuccessResponse,
	CollectOptions,
	GenerateQrCodeOptions,
	HandlePendingLoginOnSameDeviceOptions,
	HandlePendingLoginWithQrOptions,
} from "../models/bankIdModels";
import { BASEURL } from "../constants/constants";
import { generateQrImage } from "./generateQrImage";

export async function authenticate(): Promise<BankIdAuthResponse> {
	const response = await fetch(`${BASEURL}/api/bankid/authenticate`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	});

	const data = await response.json();

	if (!response.ok) {
		throw new BankIdErrorResponse(
			data.message,
			data.abortFurtherRequests || false
		);
	}

	return data as BankIdAuthResponse;
}

export async function cancel(orderRef: string): Promise<BankIdCancelResponse> {
	const response = await fetch(`${BASEURL}/api/bankid/cancel`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			orderRef,
		}),
	});

	const data = await response.json();

	if (!response.ok) {
		throw new BankIdErrorResponse(
			data.message,
			data.abortFurtherRequests || false
		);
	}

	return {
		success: true,
	};
}

export async function collect(
	options: CollectOptions
): Promise<BankIdSuccessResponse | BankIdPendingCollectResponse> {
	const { orderRef, signal, usingQr, autostart } = options;
	const response = await fetch(`${BASEURL}/api/bankid/collect`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			orderRef,
			usingQr,
			autostart,
		}),
		signal,
	});

	const data = await response.json();

	if (!response.ok) {
		throw new BankIdErrorResponse(data.message, data.abortFurtherRequests);
	}

	if ("token" in data) {
		const successResponse: BankIdSuccessResponse = data;
		return successResponse;
	}
	const pendingCollectResponse: BankIdPendingCollectResponse = data;
	return pendingCollectResponse;
}

export async function generateQrCode(
	options: GenerateQrCodeOptions
): Promise<BankIdQrResponse> {
	const { orderRef, qrStartToken, secondsSinceAuth, signal } = options;
	const response = await fetch(`${BASEURL}/api/bankid/generateqr`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			orderRef,
			qrStartToken,
			secondsSinceAuth,
		}),
		signal,
	});

	const data = await response.json();

	if (!response.ok) {
		throw new BankIdErrorResponse(data.message, data.abortFurtherRequests);
	}

	return data;
}

export const handlePendingLoginOnSameDevice = async (
	options: HandlePendingLoginOnSameDeviceOptions
): Promise<BankIdSuccessResponse> =>
	new Promise((resolve, reject) => {
		const { orderRef, signal, setResponseMessage } = options;
		// eslint-disable-next-line consistent-return
		const timer = setInterval(async () => {
			try {
				const response = await collect({
					orderRef,
					signal,
					usingQr: false,
					autostart: true,
				});

				if ("userMessage" in response) {
					if (response.userMessage)
						setResponseMessage(response.userMessage);
				} else if ("token" in response) {
					clearInterval(timer);
					return resolve(response);
				}
			} catch (err) {
				clearInterval(timer);
				return reject(err);
			}
		}, 2000);
	});

export const handlePendingLoginWithQr = async (
	options: HandlePendingLoginWithQrOptions
): Promise<BankIdSuccessResponse> =>
	new Promise((resolve, reject) => {
		const {
			orderRef,
			signal,
			setResponseMessage,
			qrStartToken,
			setQrImage,
		} = options;
		let secondsSinceAuth = 0;
		// eslint-disable-next-line consistent-return
		const timer = setInterval(async () => {
			try {
				secondsSinceAuth += 1;

				if (secondsSinceAuth % 2 === 0) {
					const collectResponse = await collect({
						orderRef,
						signal,
						usingQr: true,
					});

					if ("userMessage" in collectResponse) {
						if (collectResponse.userMessage)
							setResponseMessage(collectResponse.userMessage);
					} else if ("token" in collectResponse) {
						clearInterval(timer);
						return resolve(collectResponse);
					}
				}

				const qrCodeResponse = await generateQrCode({
					secondsSinceAuth,
					qrStartToken,
					orderRef,
					signal,
				});

				const image = generateQrImage(qrCodeResponse.qrCode);
				setQrImage(image);
			} catch (err) {
				clearInterval(timer);
				return reject(err);
			}
		}, 1000);
	});
