import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CloseButton } from "../Modal/CloseButton";
import { useFetchProductsQuery } from "../../store/slices/productSlice";
import { Breakpoints } from "../../styles/Breakpoints";
import blob from "../../assets/images/blob2.svg";
import { Product } from "../../models/product";
import { useAppDispatch } from "../../hooks/hooks";
import { toggleModal } from "../../store/slices/modalSlice";
import { LoadingSpinner } from "../LoadingSpinner";

const getSurfAmount = (subscription: string): number => {
	const surfAmount = subscription.split(" ")[2].slice(0, -2);
	return +surfAmount;
};

export const SurfAmountCustomerGuide: React.FC = () => {
	const {
		data: subscriptions,
		isError,
		isLoading,
		isSuccess,
	} = useFetchProductsQuery();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const navigateToProductPage = (id: number) => {
		if (
			window.location.pathname === "/bli-kund" ||
			window.location.pathname === "/"
		) {
			navigate(`/bli-kund?product=${id}`);
			dispatch(toggleModal());
		} else if (window.location.pathname === "/andra-abonnemang") {
			navigate(`/andra-abonnemang?product=${id}`);
			dispatch(toggleModal());
		}
	};

	const handleClick = (id: number) => {
		navigateToProductPage(id);
	};

	const handleKeypress = (
		e: React.KeyboardEvent<HTMLLIElement>,
		id: number
	) => {
		if (e.charCode === 13 || e.key === "Enter") {
			navigateToProductPage(id);
		}
	};

	const renderItem = (subscription: Product) => (
		<Item
			tabIndex={0}
			key={subscription.id}
			onClick={() => handleClick(subscription.id)}
			onKeyPress={e => handleKeypress(e, subscription.id)}
		>
			<div>
				<h4>{subscription.name}</h4>
				<p>{subscription.info}</p>
			</div>
			<Price>{subscription.unit_price * 1.25} kr/mån</Price>
		</Item>
	);

	return (
		<Container
			data-testid="surf-guide-modal"
			aria-modal="true"
			aria-label="surf_guide_dialog"
			role="dialog"
			onClick={e => e.stopPropagation()}
		>
			<CloseButton />
			<h1>Surfguiden! 🏄‍♀️</h1>
			{isLoading && <LoadingSpinner />}
			{isError && (
				<p>
					Något gick fel när vi försökte hämta produkterna. Det verkar
					som om vi har tekniska problem. Du kan alltid kontakta vår
					support för hjälp med att teckna ett abonnemang.
				</p>
			)}
			{isSuccess && (
				<Subscriptions>
					<h2>För småsurfaren</h2>
					<List>
						{subscriptions
							.filter(sub => getSurfAmount(sub.name) < 9)
							.map(sub => renderItem(sub))}
					</List>
					<h2>För scrollaren</h2>
					<List>
						{subscriptions
							.filter(
								sub =>
									getSurfAmount(sub.name) < 22 &&
									getSurfAmount(sub.name) >= 9
							)
							.map(sub => renderItem(sub))}
					</List>
					<h2>För pendlaren</h2>
					<List>
						{subscriptions
							.filter(
								sub =>
									getSurfAmount(sub.name) < 45 &&
									getSurfAmount(sub.name) >= 22
							)
							.map(sub => renderItem(sub))}
					</List>
					<h2>För storsurfaren</h2>
					<List>
						{subscriptions
							.filter(sub => getSurfAmount(sub.name) >= 45)
							.map(sub => renderItem(sub))}
					</List>
				</Subscriptions>
			)}
		</Container>
	);
};

const Container = styled.article`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 800px;
	max-height: 90vh;
	border: 4px dotted hsla(311, 85%, 82%, 0.5);
	border-radius: 1.5rem;
	padding: 0.75rem;
	background: hsla(263, 62%, 98%, 1);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
	position: relative;

	& > button {
		z-index: 3;
		position: absolute;
		top: 0.25rem;
		right: 0.25rem;
		color: var(--color-salmon);
		background: hsla(263, 62%, 98%, 1);

		@media screen and (min-width: ${Breakpoints.TabletPortrait}) {
			top: 1rem;
			right: 1rem;
		}

		:hover,
		:focus {
			&::after {
				border: 2px solid hsla(308, 81%, 95%, 1);
			}
		}
	}

	h1 {
		text-align: center;
		margin-bottom: 2rem;
		margin-top: 2rem;
		color: var(--color-pink);
		font-size: var(--font-size-xl);
		text-transform: uppercase;
		font-weight: 900;
		transform: rotate(-3deg);
		text-shadow: 4px 3px 0 rgba(0, 0, 0, 0.13);

		@media screen and (min-width: ${Breakpoints.TabletPortrait}) {
			font-size: var(--font-size-xxl);
			margin-top: 1rem;
			margin-bottom: 3rem;
		}

		@media screen and (min-width: ${Breakpoints.Laptop}) {
			margin-top: 0;
		}
	}

	h2 {
		width: 100%;
		padding: 12px 16px 6px 16px;
		font-size: var(--font-size-m);
		text-transform: uppercase;
		line-height: 1;
		border-radius: 8px;
		background: var(--gradient-primary-reversed);
		color: var(--color-whiteish);
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
		margin-bottom: 1rem;
	}

	h3 {
		margin-bottom: 0.5rem;
	}

	@media screen and (min-width: ${Breakpoints.TabletPortrait}) {
		padding: 2rem;
	}

	@media screen and (min-width: ${Breakpoints.TabletLandscape}) {
		padding: 3rem;
		& h3 {
			margin-bottom: 1rem;
		}
	}
`;

const Subscriptions = styled.section`
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	padding-right: 8px;

	::-webkit-scrollbar-track {
		background: hsla(263, 62%, 98%, 1);
	}

	::-webkit-scrollbar {
		width: 14px;
		background: transparent;
		border-radius: 6px;
	}

	::-webkit-scrollbar-thumb {
		background: hsla(263, 62%, 95%, 1);
		border-radius: 6px;
		border: 3px solid hsla(263, 62%, 98%, 1);
	}
`;

const List = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
`;

const Item = styled.li`
	border-radius: 16px;
	padding: 2rem 1rem 3rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	margin-bottom: 2rem;
	cursor: pointer;

	&:focus,
	&:active {
		outline: 3px solid var(--color-purple);
		outline-offset: -3px;
	}

	&::after {
		content: "";
		position: absolute;
		width: 25%;
		height: 2px;
		border: 2px dashed hsla(263, 62%, 95%, 1);
		bottom: -1rem;
		left: 50%;
		transform: translateX(-50%);
	}

	& div {
		max-width: 70%;
	}

	&:last-of-type {
		margin-bottom: 0;

		&::after {
			display: none;
		}
	}
`;

const Price = styled.div`
	aspect-ratio: 1;
	background: url(${blob});
	background-size: cover;
	background-repeat: no-repeat;
	color: var(--color-whiteish);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	position: absolute;
	right: 16px;
	top: 50%;
	font-weight: bold;
	transform: translateY(-50%);
	font-size: var(--font-size-s);

	@media screen and (min-width: ${Breakpoints.TabletPortrait}) {
		font-size: var(--font-size-base);
		padding: 12px;
	}
`;
