import React from "react";
import styled from "styled-components";
import { CloseButton } from "../Modal/CloseButton";
import blobMulti from "../../assets/images/blobMulti.svg";

interface Props {
	title: string;
	children: React.ReactNode;
	mixed?: boolean;
	closeButton?: boolean;
}

/**
 * @interface Props
 * @description A gradient card component
 * @param children - a node of react children to render inside the component
 * @param title - the title of the card which will display in the gradient header of the card
 * @param mixed A boolean that is added if the gradient card should return a card with secondary colors instead of primary.
 * @param closeButton A boolean that adds the CloseButton component to the header of the gradient card if present
 *
 */
export const GradientCard: React.FC<Props> = props => {
	const { title, children, mixed, closeButton } = props;

	return (
		<CardElement className="gradient-card">
			<CardHeader mixed={mixed}>
				<h2>{title}</h2>
				{closeButton && <CloseButton />}
			</CardHeader>
			<CardContent blobMulti={blobMulti}>{children}</CardContent>
		</CardElement>
	);
};

interface CardProps {
	mixed?: boolean;
}

interface ContentProps {
	mixed?: boolean;
	blobMulti: string;
}

const CardElement = styled.article`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 40rem;
	min-height: 10rem;
	border: none;
	border-radius: 16px;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	transition: all 0.2s;
	background: var(--color-whiteish);
	color: var(--color-blackish);
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
	letter-spacing: 0.5px;
	line-height: 1;
	font-weight: 500;
	padding: 0;

	& > * {
		border-radius: 16px;
	}
`;

const CardHeader = styled.header<CardProps>`
	width: 100%;
	padding: 24px 32px;
	border-radius: 16px 16px 0 0;
	background: ${props =>
		props.mixed ? "var(--gradient-mixed)" : "var(--gradient-primary)"};
	text-align: left;
	color: var(--color-whiteish);
	display: grid;
	grid-template-columns: 10fr minmax(37px, 1fr);
	align-items: center;

	& h2 {
		font-size: 1.3rem;
	}
`;

const CardContent = styled.section<ContentProps>`
	width: 100%;
	height: 100%;
	text-align: left;
	text-shadow: none;
	background: var(--color-whiteish) url(${props => props.blobMulti}) repeat
		center;
`;
